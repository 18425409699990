import SignUpContent from "@containers/SignUpContainer/SignUpContent";
import React, { useState } from "react";

function SignUpScreen() {

	return (
		<div style={{ overflow: "hidden", minHeight: "100%" }}>
			<SignUpContent />
		</div>
	);
}

export default SignUpScreen;
