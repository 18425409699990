import { useEffect, useRef, useState } from "react";
import "./ReplyContactModal.scss";
import { Modal } from "react-bootstrap";
import emailjs from "emailjs-com";
import { SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import ReactQuill, { Quill } from "react-quill";
import useEmailResponse from "@hooks/collaborators/sendEmails/UseEmailToContact";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/compat/app";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import { Bottletrash } from "assets/Imports";
const ReplyContactModal = (props) => {
	const quillRef = useRef<ReactQuill>(null);
	const inputRefs = [useRef<HTMLInputElement | null>(null), useRef<HTMLInputElement | null>(null)];
	const { contactInfo, onHide, contactLog, collaborator, setContactNotificationSeen, setRefresh, refresh } = props;
	const [loader, setLoader] = useState<boolean>();
	const [firstInteraction, setFirstInteraction] = useState(true);

	const handleSelectionChange = () => {
		if (firstInteraction && quillRef.current) {
			const quillInstance = quillRef.current.getEditor();
			quillInstance.format("size", "large");
			setFirstInteraction(false);
		}
	};

	const handleResetInput = (index: number) => {
		if (inputRefs[index]?.current) {
			inputRefs[index].current!.value = "";
		}
	};

	const formRef: any = useRef();
	const [editorValue, setEditorValue] = useState(""); // en lugar de ""

	const { sendEmailResponse } = useEmailResponse();
	const [fileName, setFileName] = useState<any>("");
	const [file, setFile] = useState<any>([]);

	const handleEditorChange = (value) => {
		setEditorValue(value);
	};
	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		if (selectedFile && selectedFile.size <= 2 * 1024 * 1024) {
			setFileName(selectedFile.name);
			setFile([...file, selectedFile]);
			return;
		} else {
			alert("Porfavor selecciona un archivo de 2MB o menos");
			event.target.value = "";
			return;
		}
	};

	let Font = Quill.import("formats/font");
	let Block = Quill.import("blots/block");
	Block.tagName = "p";
	Block.className = "pre";
	Quill.register(Block, true);

	const toolbarOptions = [
		//	[{ size: ["small", false, "large", "huge"] }], // custom dropdown
		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		['bold', 'italic', 'underline', 'strike'],        // toggled buttons
		//[{ list: "ordered" }, { list: "bullet" }],
		[{ script: "sub" }, { script: "super" }], // superscript/subscript
		["clean"], // remove formatting button
		[{ font: Font.whitelist }],
	];

	const replaceSpacesWithNbsp = (html) => {
		return html.replace(/(^|>)\s+|\s+(?=<|$)/g, (match) => match.replace(/\s/g, "&nbsp;"));
	};

	const applyFontSize = (html) => {
		let parser = new DOMParser();
		let doc = parser.parseFromString(html, "text/html");

		let all = doc.getElementsByTagName("*");

		for (let i = 0; i < all.length; i++) {
			let el = all[i];

			if (el instanceof HTMLElement && el.nodeName === "P") {
				let font = doc.createElement("font");
				font.setAttribute("size", "5");

				while (el.firstChild) font.appendChild(el.firstChild);

				// Create new 'P' element and append 'font' element to it
				let newP = doc.createElement("p");
				newP.appendChild(font);

				if (el.parentNode !== null) {
					el.parentNode.replaceChild(newP, el);
				}
			}
		}

		// Create the main parent font tag
		let mainFont = doc.createElement("div");
		mainFont.setAttribute("style", "padding: 12px 12px 12px 40px; border-left: 4px solid rgb(208, 208, 208);");

		// Append the body's content to the main font tag
		while (doc.body.firstChild) {
			mainFont.appendChild(doc.body.firstChild);
		}

		// Append the main font tag to the body
		doc.body.appendChild(mainFont);

		return doc.body.innerHTML;
	};

	const handleSubmit = async (e) => {
		try {
			setLoader(true);
			e.preventDefault();

			const form = e.target;
			// Obtener los archivos seleccionados

			const downloadURLPromises = file.map((file) => {
				const storageRef = firebase.storage().ref().child(`files/${uuidv4()}`);
				const uploadTask = storageRef.put(file);
				return uploadTask.then((snapshot) => snapshot.ref.getDownloadURL());
			});

			// Esperar a que se completen todas las promesas
			const downloadURLs = await Promise.all(downloadURLPromises);
			// Crear el arreglo de objetos de archivos
			const fileObjects = file.map((file, index) => ({
				link: downloadURLs[index],
				name: file.name,
			}));

			const emailRegex = /^\s*[\w.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}\s*$/;

			let tempEmails = form.email.value.split(",").map((word) => word.trim());
			let validEmails = tempEmails.filter((word) => emailRegex.test(word));

			const quillInstance = quillRef.current?.getEditor();
			const plainTextValue = quillInstance?.getText();
			const htmlValue = quillInstance?.root.innerHTML; // Obtenemos el HTML
			const modifiedHtmlValue = replaceSpacesWithNbsp(applyFontSize(htmlValue));
			form.replyMessage.value = modifiedHtmlValue;
			if (tempEmails.length !== validEmails.length) {
				alert("Porfavor revisa que los emails esten bien escritos");
				throw new Error("Something wrong with the emails");
			}

			if (!plainTextValue || plainTextValue === "<p><br></p>") {
				alert("Por favor, redacte el correo.");
				setLoader(false);
				return;
			}

			await emailjs.sendForm("service_kznboar", "template_9bsvq5g", formRef.current, "user_PPI0O8nofs9cbuJ3JRWlT");

			const tempCollaborator = { id: collaborator.id, name: collaborator.name };
			await contactLog(
				tempCollaborator,
				form.replyMessage.value,
				validEmails,
				contactInfo.phone,
				contactInfo.name,
				contactInfo.id,
				true
			);
			await sendEmailResponse(
				contactInfo.id,
				collaborator.email,
				tempEmails,
				form.replyMessage.value,
				fileObjects,
				contactInfo.description,
				form.subject.value,
				collaborator.id,
				collaborator.name
			);
			alert("Mensaje enviado con éxito");
			await setContactNotificationSeen(contactInfo.id);
			setRefresh(!refresh);
			onHide();
			//setFirstInteraction(true);
		} catch (error: any) {
			if (error.status === 426) {
				alert("El tamaño de los archivos no debe sobrepasar 2Mb");
			} else {
				alert("Ocurrió un error inesperado, favor de intentar de nuevo.");
			}
			console.error(error);
		}
		setLoader(false);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Tab") {
			e.preventDefault();
			const quillInstance = quillRef.current?.getEditor();
			if (quillInstance) {
				const selection = quillInstance.getSelection();
				if (selection) {
					quillInstance.format("indent", "+1");
				}
			}
		}
	};

	return (
		<Modal
			show={props.showModal}
			onHide={() => {
				props.onHide();
				//setFirstInteraction(true);
			}}
			centered={true}
			dialogClassName="rcm-dialog"
			size="lg"
			aria-labelledby="example-modal-sizes-title-lg"
		>
			<Modal.Header closeButton className="rcm-header">
				<Modal.Title className="rcm-title">Contestar comentario</Modal.Title>
			</Modal.Header>
			<Modal.Body className="rcm-body" style={{ display: "flex", flexDirection: "column" }}>
				<form className="form-grid" onSubmit={handleSubmit} ref={formRef}>
					<p>
						Nombre: <span>{contactInfo?.name}</span>
					</p>
					<input type="text" defaultValue={contactInfo.name} name="name" style={{ display: "none" }} />

					<div className="input-group e1">
						<label htmlFor="email">Para:</label>
						<span style={{ fontWeight: "2px", fontFamily: "fantasy", color: "GrayText" }}>
							separar por "," si quieres añadir otro correo
						</span>
						<input type="text" defaultValue={contactInfo.email} name="email" required />
					</div>
					<div className="input-group e1">
						<label htmlFor="subject">Asunto:</label>
						<input type="text" defaultValue={contactInfo.description} name="subject" required />
					</div>
					<div className="input-group e2">
						<label htmlFor="contactMessage">Mensaje de contacto:</label>
						<textarea readOnly defaultValue={contactInfo.description} name="contactMessage" required />
					</div>
					<div style={{ maxHeight: "100%", overflowY: "auto" }}>
						<label htmlFor="replyMessage">Respuesta:</label>
						<ReactQuill
							ref={quillRef}
							modules={{
								toolbar: toolbarOptions,
							}}
							theme="snow"
							onChange={handleEditorChange}
							onKeyDown={handleKeyDown}
						/>

						<input type="hidden" name="replyMessage" value={editorValue} required />
						{/*<textarea name="replyMessage" required/>*/}
					</div>

					<div className="input-file-group">
						<label htmlFor="file1">Archivo 1:</label>
						<div className="input-file">
							<input type="file" name="files" onChange={handleFileChange} ref={inputRefs[0]} />
							<img src={Bottletrash} alt="trash" onClick={() => handleResetInput(0)} style={{cursor:"pointer"}}/>
						</div>
					</div>
					<div className="input-file-group">
						<label htmlFor="file2">Archivo 2:</label>
						<div className="input-file">
							<input type="file" name="files" onChange={handleFileChange} ref={inputRefs[1]} />
							<img src={Bottletrash} alt="trash" onClick={() => handleResetInput(1)} style={{cursor:"pointer"}} />
						</div>
					</div>
					<div className="buttons-container">
						<button type="submit" className="send-btn" disabled={loader}>
							{!loader ? "Enviar" : <SpinnerCustom height={10} />}
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default ReplyContactModal;
