import { useEffect, useState } from "react";
import { collection, query, where, getDocs, getDoc, doc, setDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { DocumentData } from "firebase/firestore/lite";

const GetCollaborator = async () => {
	try {
		const q = query(collection(db, "users"), where("userType", "==", 4));
		const querySnapshot = await getDocs(q);
		const userData: any = [];
		querySnapshot.forEach((doc) => {
			const user = {
				...doc.data(),
				id: doc.id,
			};

			userData.push(user);
		});

		const collaboratorData: any = [];

		await Promise.all(
			userData.map(async (user) => {
				const collaborator = { ...user };
				const adminRoutes: any = [];
				const roles: any = [];
				if (collaborator.permissions && collaborator.permissions.length > 0) {
					const permissionIDs = collaborator.permissions;
					await Promise.all(
						permissionIDs.map(async (permissionID) => {
							const adminRouteDoc = doc(db, "admin_routes", permissionID);
							const adminRouteSnapshot = await getDoc(adminRouteDoc);
							const adminRouteData: DocumentData = adminRouteSnapshot.data() as DocumentData;
							if (adminRouteData) {
								const adminRouteWithId = {
									...adminRouteData,
									id: permissionID,
								};
								adminRoutes.push(adminRouteWithId);
							}
						})
					);
				}
				if (collaborator.roles !== undefined && collaborator.roles.length > 0) {
					const roleID = typeof collaborator.roles === "object" ? collaborator.roles[0] : collaborator.roles;
					const roleDoc = doc(db, "Roles_Collaborators", roleID);
					const roleSnapshot = await getDoc(roleDoc);
					const roleData: DocumentData = roleSnapshot.data() as DocumentData;

					if (roleData) {
						const roleWithId = {
							...roleData,
							id: roleID,
						};
						roles.push(roleWithId);
					}
				}

				collaborator.adminRoutes = adminRoutes;
				collaborator.roles = roles;

				collaboratorData.push(collaborator);
			})
		);
		return collaboratorData;
	} catch (error) {
		console.error(error);
	}
};

const AddCollaborator = async (userSelected, setUserSelected) => {
	if (userSelected !== "") {
		try {
			const userRef = doc(db, "users", userSelected);
			const docSnapshot = await getDoc(userRef);
			if (docSnapshot.exists()) {
				if (docSnapshot.data().hasOwnProperty("userType")) {
					// El campo "userType" ya existe, actualizar su valor a 4
					await setDoc(userRef, { userType: 4 }, { merge: true });
					console.log("Campo userType actualizado en Firebase");
				} else {
					// El campo "userType" no existe, crearlo y asignar el valor 4
					await setDoc(userRef, { userType: 4 }, { merge: true });
					console.log("Campo userType creado en Firebase");
				}
				setUserSelected("");
			} else {
				console.error("El documento no existe en Firebase");
			}
		} catch (error) {
			console.error("Error al interactuar con Firebase: ", error);
		}
	}
	return AddCollaborator;
};

export const UseCollaborator = { GetCollaborator, AddCollaborator };
