import React, { useEffect, useState, useContext } from "react";
import "../../CollaboratorContainer.css";
import "../../CollaboratorContainer.scss";
import {
	collection,
	collectionGroup,
	doc,
	getDoc,
	getDocs,
	query,
	where,
} from "firebase/firestore";
import { db } from "initFirebase";
import { Link, useHistory } from "react-router-dom";
import { compareGenerations, getSubtitle } from "functions/Generations/FunctionsGenerations";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { EyeIcon } from "assets/Imports";
import { SearchIcon, CheckmarIcon, CheckmarkCircle, EditIcon, CloseCircle } from "assets/Imports";
import { Table } from "react-bootstrap";
import { EditCollaboratorModal } from "../modals/EditCollaboratorModal";
import { UseRole } from "@hooks/collaborators/UseRole";
import { UseCollaborator } from "@hooks/collaborators/UseCollaborator";
import { AddCollaboratorModal } from "../../components/modals/AddCollaboratorModal";
import toast, { Toaster } from "react-hot-toast";
import { MenusDataContext } from "../../context/menu-data.provider";
import { CustomSpinner, SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import { useAuth } from "@hooks/useAuth";
import { MainUserModal } from "../modals/MainUserModal";
function TableCollaborator(props) {
	const {
		state_Add_Collaborator,
		NameContainer,
		SubTitle,
		NameTh1,
		NameTh2,
		NameTh3,
		NameButton,
		user,
	} = props;
	const [showModalEdit, setModalEdit] = useState<boolean>(false);
	const [showModalAdd, setshowModalAdd] = useState(false);
	const { GetCollaborator, AddCollaborator } = UseCollaborator;
	const [Collaborators, setCollaborators] = useState<any>([]);
	const { handleSelectedUserPreview } = useAuth();
	const [isloading, setIsloading] = useState<boolean>(true);
	const [selectedUser, setSelectedUser] = useState<any>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [mainUser, setMainUser] = useState<any>();
	const [selectedMainUser, setSelectedMain] = useState<any>([]);
	const [showMainUser, setshowMainUser] = useState<boolean>(false);
	const fetchMainUser = async () => {
		try {
			const userRef = doc(db, "users/Gj6aGiQYQ5Ptj6fvrEjWhRM52pA2");
			const userDoc = await getDoc(userRef);
			// Verifica si el documento existe
			if (userDoc.exists()) {
				// Obtiene los datos del documento
				let data = userDoc.data();
				// Agrega el id del usuario a los datos
				data.id = userDoc.id;
				// Asigna los datos (incluyendo el id) al estado
				setMainUser(data);
			}
		} catch (error) {
			console.error("Error fetching main user info: " + error);
		}
	};

	const fetchCollaborators = async () => {
		try {
			setIsloading(true);
			const collaborators = await GetCollaborator();
			setCollaborators(
				collaborators
					.map((data) => ({
						NameCollaborator: data?.name,
						id: data?.id,
						Email: data?.email,
						Descri: data?.description,
						DinamicText: data?.dynamicText,
						seccionsCollaborator: data?.adminRoutes?.map((route) => route.Titulo),
						seccionsCollaboratorId: data?.adminRoutes?.map((route) => route.id),
						seccionsCollaboratorRoutes: data?.adminRoutes?.map((route) => route.Ruta),
						rolesCollaborator: data?.roles.map((role) => role.RoleName),
					}))
					.sort((a, b) => {
						const nameA = a.NameCollaborator.toUpperCase();
						const nameB = b.NameCollaborator.toUpperCase();

						if (nameA < nameB) {
							return -1;
						}

						if (nameA > nameB) {
							return 1;
						}

						return 0;
					})
			);
			setIsloading(false);
		} catch (error) {
			console.error("Error al obtener los colaboradores:", error);
		}
	};

	const handleCollaborator = async (userSelected, setUserSelected) => {
		try {
			await AddCollaborator(userSelected, setUserSelected);
			toast.success("Se agregó un nuevo colaborador");

			fetchCollaborators();
		} catch (error) {
			toast.error("No se pudo agregar un nuevo colaborador");
		}
	};

	useEffect(() => {
		fetchCollaborators();
		fetchMainUser();
	}, []);

	return (
		<>
			<EditCollaboratorModal
				show={showModalEdit}
				Hide={() => setModalEdit(false)}
				fetchCollaborators={fetchCollaborators}
				selectedUser={selectedUser}
			/>
			<MainUserModal
				show={showMainUser}
				Hide={() => setshowMainUser(false)}
				selectedMainUser={selectedMainUser}
			/>
			<AddCollaboratorModal
				showmodal={showModalAdd}
				Hide={() => setshowModalAdd(false)}
				AddCollaraborator={handleCollaborator}
			/>
			<Toaster />
			<div className="collaborator-title-container">
				<h1>{NameContainer}</h1>
			</div>

			<div className="collaborator-subtitle-container">
				<p className="label-dark" style={{ margin: 0 }}>
					{SubTitle}.
				</p>
				<div
					style={{
						border: "2px solid #7b7b7e",
						width: 300,
						borderRadius: 10,
						padding: "10px 20px",
						display: "flex",
					}}
				>
					<img src={SearchIcon} alt="search-icon" />
					<input
						style={{ border: "none", paddingLeft: 15, width: "100%" }}
						placeholder="Buscar"
						type="text"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
			</div>

			<div className="edit-gen-card collaborator">
				{!isloading && (
					<div className="button-container">
						<button
							className="tertiary return-btn-style"
							onClick={() => {
								setshowModalAdd(true);
							}}
						>
							{NameButton}
						</button>
					</div>
				)}

				{isloading === true ? (
					<CustomSpinner size={100} height={"100%"} borderSize={4} />
				) : (
					<Table hover responsive>
						<thead>
							<tr>
								<th>{NameTh1}</th>
								<th>{NameTh2}</th>
								<th>{NameTh3}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style={{ fontWeight: "bold" }}>{mainUser.name}</td>
								<td style={{ fontWeight: "bold" }}>{mainUser.email}</td>
								<td style={{ fontWeight: "bold" }}>Todas</td>
								<td>
									<img
										src={EyeIcon}
										alt="edit-icon"
										style={{ cursor: "pointer" }}
										onClick={() => {
											setSelectedMain(mainUser);
											setshowMainUser(true);
										}}
									/>
								</td>
							</tr>
							{Collaborators.filter(
								(collaborator) =>
									collaborator.NameCollaborator.toLowerCase().includes(searchTerm.toLowerCase()) ||
									collaborator.Email.toLowerCase().includes(searchTerm.toLowerCase()) ||
									collaborator.rolesCollaborator.some((section) =>
										section.toLowerCase().includes(searchTerm.toLowerCase())
									) ||
									collaborator.seccionsCollaborator.some((section) =>
										section.toLowerCase().includes(searchTerm.toLowerCase())
									)
							).map((data, index) => (
								<tr key={data.id}>
									<td className="name-cell" style={{ fontWeight: "bold" }}>
										{data?.NameCollaborator}
										<span className="label_Rol" style={{ fontWeight: "bold" }}>
											{data?.rolesCollaborator.join(", ") || "Sin roles asignados"}
										</span>
									</td>
									<td style={{ fontWeight: "bold" }}>{data?.Email}</td>
									<td style={{ color: "gray", fontWeight: "inherit" }}>
										{data?.seccionsCollaborator && data?.seccionsCollaborator.length > 0
											? data?.seccionsCollaborator.join(", ")
											: "Sin secciones asignadas"}
									</td>
									<td>
										<img
											src={EditIcon}
											alt="edit-icon"
											style={{ cursor: "pointer" }}
											onClick={() => {
												setSelectedUser(data);
												handleSelectedUserPreview(data);
												setModalEdit(true);
											}}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				)}
			</div>
		</>
	);
}

export default TableCollaborator;
