import { Suspense, useEffect, useState } from "react";
import "./ConsultStyles.scss";
import "react-quill/dist/quill.snow.css";
import TextEditor from "@components/TextEditor/TextEditor";
import { addDoc, collection, doc, getDoc, getDocs, limit, orderBy, query, snapshotEqual, where } from "firebase/firestore";
import { db } from "initFirebase";
import FormModal from "@components/ModalFormUser/FormModal";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import parse from "html-react-parser";
import SearchCard from "./components/SearchCard";
import emailjs from "emailjs-com";
import ConsultAnswers from "./components/ConsultAnswers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import ModuleSelectorBar from "./components/ModuleSelectorBar";
import { randomSearchTexts } from "constants/SearchWords";
import { randomIntFromInterval } from "functions/Functions";
import {
	AdminIcon,
	AppsSharp2Icon,
	AppsSharp2WhiteIcon,
	AppsSharpIcon,
	AppsSharpWhiteIcon,
	BackArrowIcon,
	BrazilFlagPng,
	BrazilFlagSVG,
	Calendar,
	ChinaFlagPng,
	ChinaFlagSVG,
	CloseCircleIcon,
	DownArrow,
	EyeBlock,
	ForwardArrow,
	FranceFlagPng,
	FranceFlagSVG,
	GermanyFlagPng,
	GermanyFlagSVG,
	ItalyFlagPng,
	ItalyFlagSVG,
	ColabComment,
	JapanFlagPng,
	JapanFlagSVG,
	Logo2,
	NextArrow,
	RussiaFlagPng,
	RussiaFlagSVG,
	SearchIcon,
	UnitedStatesFlagPng,
	UnitedStatesFlagSVG,
} from "assets/Imports";

const searchPlaceHolder = randomSearchTexts[randomIntFromInterval(0, 8)];

function ConsultContainer({ user }) {
	const history = useHistory();
	const [view, setView] = useState<number>(2);
	const [consultPost, setConsultPost] = useState<string>("");
	const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [sesionesPagadas, setSesionesPagadas] = useState<number>(0);
	const [actualSeminary, setActualSeminary] = useState<number>(0);
	const [pastMaterial, setPastMateriales] = useState<Array<seminary>>([]);
	const [formModalShow, setFormModalShow] = useState<boolean>(false);
	const [cardIndex, setCardIndex] = useState<number>(0);
	const [send, setSend] = useState<boolean>(false);
	const [consultLangs, setConsultLangs] = useState<langsBoolean>({
		french: false,
		german: false,
		italian: false,
		russian: false,
		chinese: false,
		portuguese: false,
		english: false,
		japanese: false,
	});
	const [noPay, setNoPay] = useState<boolean>(false);
	const [hashtags, setHashtags] = useState<Array<string>>([]);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [myConsults, setMyConsults] = useState<boolean>(false);
	const [french, setFrench] = useState<boolean>(false);
	const [german, setGerman] = useState<boolean>(false);
	const [japanese, setJapanese] = useState<boolean>(false);
	const [italian, setItalian] = useState<boolean>(false);
	const [russian, setRussian] = useState<boolean>(false);
	const [chinese, setChinese] = useState<boolean>(false);
	const [portuguese, setPortuguese] = useState<boolean>(false);
	const [english, setEnglish] = useState<boolean>(false);
	const [popularHashtags, setPopularHashtags] = useState<Array<string>>([]);
	const [popularHashtags2, setPopularHashtags2] = useState<Array<string>>([]);
	const [showMore, setShowMore] = useState<boolean>(false);
	const [hashtagFilter, setHashtagFilter] = useState<Array<string>>([]);
	const [isSearching, setIsSearching] = useState<boolean>(false);
	const [filterText, setFilterText] = useState<string>("");
	const [loadingConsult, setLoadingConsult] = useState<boolean>(false);
	const [showNotificationBar, setShowNotificationBar] = useState<boolean>(false);
	const seminarios = [
		60, 59, 58, 57, 56, 55, 54, 53, 52, 51, 50, 49, 48, 47, 46, 45, 44, 43, 42, 41, 40, 39, 38, 37, 36, 35, 34, 33, 32, 31, 30,
		29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1,
	];
	const [moduleSelector, setModuleSelector] = useState<number>(0);

	let { path, url } = useRouteMatch();

	interface seminary {
		num: number;
		id: string;
		consultsSize: number;
		lastConsult: Array<any>;
		consultAnswers: Array<any>;
		personalConsults: Array<any>;
		remain: number;
	}

	interface langsBoolean {
		french: boolean;
		german: boolean;
		italian: boolean;
		russian: boolean;
		chinese: boolean;
		portuguese: boolean;
		english: boolean;
		japanese: boolean;
	}

	const getGenerations = async () => {
		let array: any = [];
		if (user.generationId !== undefined && user.generationId !== "") {
			const docRef = doc(db, "generations", user.generationId);
			const docSnap = await getDoc(docRef);
			getPayments(docSnap.data());
		} else {
			setNoPay(true);
			setLoading(false);
		}
	};

	const getMateriales = async (data, numPaid) => {
		let pastMaterial: any = [];

		seminarios.forEach(async (num) => {
			let consultsArray = new Array();
			let consultAnswers = new Array();
			let personalArray = new Array();

			const q2 = query(
				collection(db, "consults"),
				where("seminaryNum", "==", num),
				where("verified", "==", true),
				orderBy("date", "desc")
			);

			const querySnapshot2 = await getDocs(q2);

			querySnapshot2.forEach((consult) => {
				consultsArray.push({ ...consult.data(), id: consult.id });
				if (consult.data().userId === user.id) {
					personalArray.push({ ...consult.data(), id: consult.id });
				}
			});

			const q3 = query(
				collection(db, "consults"),
				where("seminaryNum", "==", num),
				where("verified", "==", false),
				orderBy("date", "desc")
			);

			const querySnapshot3 = await getDocs(q3);

			const data2: any = {
				num: num,
				consultsSize: querySnapshot2.size,
				lastConsult: consultsArray,
				consultAnswers: consultAnswers,
				personalConsults: personalArray,
				remain: querySnapshot3.size,
			};

			pastMaterial.push(data2);

			if (num === 60) {
				setPastMateriales(pastMaterial.sort(compare));
				getSeminariesPasados(data, pastMaterial);
			}
		});
	};

	const getSeminariesPasados = async (data, array) => {
		let nextMaterial: any = [];
		let pastMaterialArray: any = [];
		const q = query(collection(db, "generations", data.id, "material"));

		const querySnapshot = await getDocs(q);

		let i = 0;
		const date = new Date();

		querySnapshot.forEach((doc) => {
			let materialDate = new Date();
			i++;
			// doc.data() is never undefined for query doc snapshots
			const data2: any = {
				...doc.data(),
				id: doc.id,
			};

			let materialDateSplit = data2.date.split("/");
			materialDate.setDate(materialDateSplit[0]);
			materialDate.setMonth(materialDateSplit[1]);
			materialDate.setFullYear(materialDateSplit[2]);
			materialDate.setHours(0, 0, 0);

			if (date < materialDate) {
				nextMaterial.push(data2);
			} else {
				pastMaterialArray.push(data2);
			}
			if (i === querySnapshot.size) {
				pastMaterialArray.sort(compare2);
				nextMaterial.sort(compare2);
				let num =
					pastMaterialArray[pastMaterialArray.length - 1] === undefined ? 0 : pastMaterialArray[pastMaterialArray.length - 1].num;
				const index = array.findIndex((object) => {
					return object.num === num;
				});
				if (user.userType === 2 || user.userType === 4) {
					setActualSeminary(60);
					setLoading(false);
				} else {
					if (index >= 0) {
						setActualSeminary(array[index].num);
						setCardIndex(index);
						setLoading(false);
					} else {
						setActualSeminary(0);
						setCardIndex(array.length - 1);
						setLoading(false);
					}
				}
			}
		});
		// setCheck(true);
		// setRefresh(!refresh)
	};

	const getPayments = async (data) => {
		const qry = query(collection(db, "paymentHistory"), where("userId", "==", user.id), where("verified", "==", true));
		const qrySnapshot = await getDocs(qry);

		let i = 0;
		let total = 0;
		let sesionesPagadas = 0;
		let sesionesActuales = 60;

		qrySnapshot.forEach(async (doc) => {
			i++;
			total = total + parseInt(doc.data().amount);
			if (i === qrySnapshot.size) {
				//sesiones pagadas, sesion actual,
				if (
					((total === 12000 || total === 15000) && qrySnapshot.size === 1) ||
					user.promotion === true ||
					user.userType === 2 ||
					user.userType === 4
				) {
					total = 18000;
				}
				sesionesPagadas = Math.floor(total / 300);

				setSesionesPagadas(sesionesPagadas);

				if (user.userType === 2 || user.userType === 4) {
					getMateriales(data, 60);
				} else {
					getMateriales(data, sesionesPagadas);
				}
			}
		});

		if ((qrySnapshot.size === 0 && user.promotion === true) || user.userType === 2 || user.userType === 4) {
			setSesionesPagadas(60);
			getMateriales(data, 60);
		} else if (qrySnapshot.size === 0 && user.promotion === false) {
			setNoPay(true);
		}
	};

	function compare2(a, b) {
		if (a.num < b.num) {
			return -1;
		}
		if (a.num > b.num) {
			return 1;
		}
		return 0;
	}

	function compare(a, b) {
		if (a.num < b.num) {
			return -1;
		}
		if (a.num > b.num) {
			return 1;
		}
		return 0;
	}

	function romanize(num) {
		if (isNaN(num)) return NaN;
		var digits: any = String(+num).split(""),
			key = [
				"",
				"C",
				"CC",
				"CCC",
				"CD",
				"D",
				"DC",
				"DCC",
				"DCCC",
				"CM",
				"",
				"X",
				"XX",
				"XXX",
				"XL",
				"L",
				"LX",
				"LXX",
				"LXXX",
				"XC",
				"",
				"I",
				"II",
				"III",
				"IV",
				"V",
				"VI",
				"VII",
				"VIII",
				"IX",
			],
			roman = "",
			i = 3;
		while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
		return Array(+digits.join("") + 1).join("M") + roman;
	}

	const isFormAnswered = () => {
		if (user.formAnswered === false && user.generationId !== "") {
			setFormModalShow(true);
		} else {
			setFormModalShow(false);
		}
	};

	useEffect(() => {
		if (!loading && send === true) {
			setLoadingConsult(true);
			sendConsult();
			setSend(false);
		}
	}, [send]);

	const getHashtags = async () => {
		const docRef = doc(db, "consultsHashtags", "hashtags");
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setPopularHashtags(docSnap.data().hashtags);
			setPopularHashtags2(docSnap.data().hashtags2);
		} else {
			// doc.data() will be undefined in this case
			console.log("No such document!");
		}
	};

	useEffect(() => {
		isFormAnswered();
		getHashtags();
		getGenerations();
	}, []);

	useEffect(() => {
		if (refresh) {
			getGenerations();
		}
	}, [refresh]);

	const sumCardIndex = () => {
		if (cardIndex < pastMaterial.length - 1) {
			setCardIndex(cardIndex + 1);
		}
	};

	const subCardIndex = () => {
		if (cardIndex > 0) {
			setCardIndex(cardIndex - 1);
		}
	};

	const sendEmail = () => {
		const dataemail = {
			subject: "Nueva consulta",
			message: "Tienes una nueva consulta en Academia de Lenguas Internacionales",
		};

		emailjs.send("service_kznboar", "template_33c8s4e", dataemail, "user_PPI0O8nofs9cbuJ3JRWlT").then(
			function (res) {},
			function (error) {
				console.error(error);
			}
		);
	};

	const sendConsult = async () => {
		await addDoc(collection(db, "consults"), {
			name: user.name,
			postText: consultPost,
			hashtags: hashtags,
			french: consultLangs.french,
			german: consultLangs.german,
			japanese: consultLangs.japanese,
			italian: consultLangs.italian,
			chinese: consultLangs.chinese,
			russian: consultLangs.russian,
			english: consultLangs.english,
			portuguese: consultLangs.portuguese,
			date: new Date(),
			userId: user.id,
			seminaryNum: pastMaterial[cardIndex].num,
			verified: user.userType === 2 || user.userType === 4 ? true : false,
			numberOfReplies: 0,
			userType: user.userType === 2 || user.userType === 4 ? user.userType : 1,
			profileColor: user.color,
		}).then(async (res) => {
			if (user.userType !== 2 && user.userType !== 4) {
				console.log("enviar");
				await addDoc(collection(db, "notifications"), {
					type: "consult-new",
					seminaryNum: pastMaterial[cardIndex].num,
					name: user.name,
					text: consultPost,
					date: new Date(),
					userId: "admin",
					consultId: res.id,
					consultAnswerId: null,
					userType: 1,
					seen: false,
					profileColor: user.color,
					genId: null,
					sectionId: "G7LYiZinvaxFmvs5ZiPR",
				});
				sendEmail();
			}
			setConsultPost("");
			setHashtags([]);
			setConsultLangs({
				french: false,
				german: false,
				italian: false,
				russian: false,
				chinese: false,
				portuguese: false,
				english: false,
				japanese: false,
			});
			setShowNotificationBar(true);
			setLoadingConsult(false);
		});
	};

	const addHashtagFilter = (hashtag) => {
		let array = new Array();

		hashtagFilter.forEach((word) => {
			array.push(word);
		});
		if (array.indexOf(hashtag) !== -1) {
			const index = array.indexOf(hashtag);
			array.splice(index, 1);
			setHashtagFilter(array);
		} else {
			array.push(hashtag);
			setHashtagFilter(array);
		}
	};

	function getInitials(name: string) {
		const initials = name.split(" ");
		let fullInitials = "";

		initials.forEach((word) => {
			if (word !== "") {
				fullInitials += word[0];
			}
		});

		return fullInitials[0] + "" + (fullInitials.length > 1 ? fullInitials[1] : "");
	}

	useEffect(() => {
		if (
			english ||
			french ||
			portuguese ||
			japanese ||
			russian ||
			chinese ||
			italian ||
			german ||
			hashtagFilter.length > 0 ||
			filterText.length > 0 ||
			myConsults
		) {
			setView(1);
			setIsSearching(true);
		} else {
			setIsSearching(false);
		}
	}, [english, french, portuguese, italian, japanese, russian, chinese, german, hashtagFilter, filterText, myConsults]);

	useEffect(() => {
		if (moduleSelector > 0) {
			setView(1);
		}
	}, [moduleSelector]);

	useEffect(() => {
		if (view === 2) {
			setEnglish(false);
			setFrench(false);
			setPortuguese(false);
			setJapanese(false);
			setRussian(false);
			setItalian(false);
			setGerman(false);
			setChinese(false);
			setHashtagFilter([]);
			setFilterText("");
			setIsSearching(false);
			setOpenFilterDropdown(false);
			setMyConsults(false);
			setModuleSelector(0);
		}
	}, [view]);

	function getModuleNumber(sesionNumber) {
		if (sesionNumber > 0 && sesionNumber <= 10) {
			return "I";
		} else if (sesionNumber > 10 && sesionNumber <= 25) {
			return "II";
		} else if (sesionNumber > 25 && sesionNumber <= 40) {
			return "III";
		} else if (sesionNumber > 40 && sesionNumber <= 50) {
			return "IV";
		} else if (sesionNumber > 50 && sesionNumber <= 60) {
			return "V";
		}
	}

	if (noPay)
		return (
			<div style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
				<h3>Aún no te encuentras inscrito.</h3>
			</div>
		);

	if (loading)
		return (
			<div style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);

	return (
		<>
			<FormModal show={formModalShow} onHide={() => setFormModalShow(false)} user={user} />
			<div className={"consult-send-notification-container" + (showNotificationBar ? " open-consult-notification" : "")}>
				<div>
					<h3>
						¡Tu duda fue enviada con éxito! <br />
						Te enviaremos una notificación en cuanto sea contestada.
					</h3>
					<h3 style={{ cursor: "pointer" }} onClick={() => setShowNotificationBar(false)}>
						X
					</h3>
				</div>
			</div>
			<div className="consult-title-container">
				<h1>Consultas</h1>
				<div className="consult-change-content-btn-container">
					<div className={"left " + (view === 1 ? "active" : "")} onClick={() => setView(1)}>
						{view === 2 ? (
							<img src={AppsSharpIcon} alt="cuadricula icon" />
						) : (
							<img src={AppsSharpWhiteIcon} alt="cuadricula icon" />
						)}
					</div>
					<div className={"right " + (view === 2 ? "active" : "")} onClick={() => setView(2)}>
						{view === 2 ? <img src={AppsSharp2WhiteIcon} alt="menu icon" /> : <img src={AppsSharp2Icon} alt="menu icon" />}
					</div>
				</div>
			</div>
			<div className="description-container">
				<h3>Siéntete libre de utilizar este espacio para plantear cualquier duda que tengas.</h3>
				<h3>Para una mejor organización, te sugerimos escribirla en el seminario y utilizar las etiquetas adecuadas.</h3>
			</div>
			<div className="module-navigation-container">
				<div className="left">
					<Link to={"/micuenta/consultas/0"}>
						<div className="consult-zero-container">
							<h2>Técnicas de aprovechamiento</h2>
						</div>
					</Link>
				</div>
				<ModuleSelectorBar moduleSelector={moduleSelector} setModuleSelector={setModuleSelector} />
				<div className="right"></div>
			</div>
			{openFilterDropdown && (
				<div
					style={{ height: "100vh", width: "100%", position: "fixed", top: 77, left: 300, zIndex: 1019 }}
					onClick={() => setOpenFilterDropdown(false)}
				></div>
			)}
			<form className="search-section-container consult-search-section-container">
				<div className="search-container">
					<div
						className={"lang-selector-container " + (openFilterDropdown ? "langs-selectior-container-open" : "")}
						onClick={() => setOpenFilterDropdown(!openFilterDropdown)}
					>
						<p>Todo</p>
						<div className="icons-container">
							<img className="icon1" src={DownArrow} alt="down-arrow-icon" />
							<img className="icon2" src={CloseCircleIcon} alt="down-arrow-icon" />
						</div>
					</div>
					<div className="search-input-container">
						<img src={SearchIcon} alt="search-icon" />
						<input
							type="text"
							placeholder={searchPlaceHolder}
							value={filterText}
							onChange={(e) => setFilterText(e.target.value)}
						/>
					</div>
					<div className={"filters-container " + (openFilterDropdown ? "filters-container-open" : "")}>
						<div className="lang-filter-container">
							<h3>Por idioma(s)</h3>
							<div className="checkboxes-container">
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={french}
										onChange={(e) => setFrench(e.target.checked)}
										id="french"
										name="french"
									/>
									<label htmlFor="french">Francés</label>
									<img
										style={{ width: "20px", height: "20px", marginLeft: "auto" }}
										src={FranceFlagPng}
										alt="bandera de francia"
									/>
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={german}
										onChange={(e) => setGerman(e.target.checked)}
										id="german"
										name="german"
									/>
									<label htmlFor="german">Alemán</label>
									<img
										style={{ width: "20px", height: "20px", marginLeft: "auto" }}
										src={GermanyFlagPng}
										alt="bandera de alemania"
									/>
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={japanese}
										onChange={(e) => setJapanese(e.target.checked)}
										id="japanese"
										name="japanese"
									/>
									<label htmlFor="japanese">Japonés</label>
									<img style={{ width: "20px", height: "20px", marginLeft: "auto" }} src={JapanFlagPng} alt="bandera de japon" />
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={italian}
										onChange={(e) => setItalian(e.target.checked)}
										id="italian"
										name="italian"
									/>
									<label htmlFor="italian">Italiano</label>
									<img style={{ width: "20px", height: "20px", marginLeft: "auto" }} src={ItalyFlagPng} alt="bandera de italia" />
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={russian}
										onChange={(e) => setRussian(e.target.checked)}
										id="russian"
										name="russian"
									/>
									<label htmlFor="russian">Ruso</label>
									<img style={{ width: "20px", height: "20px", marginLeft: "auto" }} src={RussiaFlagPng} alt="bandera de rusia" />
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={chinese}
										onChange={(e) => setChinese(e.target.checked)}
										id="chinese"
										name="chinese"
									/>
									<label htmlFor="chinese">Chino</label>
									<img style={{ width: "20px", height: "20px", marginLeft: "auto" }} src={ChinaFlagPng} alt="bandera de china" />
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={portuguese}
										onChange={(e) => setPortuguese(e.target.checked)}
										id="portuguese"
										name="portuguese"
									/>
									<label htmlFor="portuguese">Portugués</label>
									<img
										style={{ width: "20px", height: "20px", marginLeft: "auto" }}
										src={BrazilFlagPng}
										alt="bandera de portugal"
									/>
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={english}
										onChange={(e) => setEnglish(e.target.checked)}
										id="english"
										name="english"
									/>
									<label htmlFor="english">Inglés</label>
									<img
										style={{ width: "20px", height: "20px", marginLeft: "auto" }}
										src={UnitedStatesFlagPng}
										alt="bandera de estados unidos"
									/>
								</div>
							</div>
						</div>
						<div className="hashtag-filter-container">
							<h3>Por etiquetas populares</h3>
							<div className="hashtags-container">
								{popularHashtags.length > 0 && (
									<>
										{!showMore ? (
											<>
												{popularHashtags.map((hashtag, index) => {
													return (
														<div
															className={"hashtag" + (hashtagFilter.indexOf(hashtag) !== -1 ? " active" : "")}
															onClick={() => addHashtagFilter(hashtag)}
															key={"popularHashtag-" + index}
														>
															#{hashtag}
														</div>
													);
												})}
											</>
										) : (
											<>
												{popularHashtags2.map((hashtag, index) => {
													return (
														<div
															className={"hashtag" + (hashtagFilter.indexOf(hashtag) !== -1 ? " active" : "")}
															onClick={() => addHashtagFilter(hashtag)}
															key={"popularHashtag-" + index}
														>
															#{hashtag}
														</div>
													);
												})}
											</>
										)}
									</>
								)}
								{!showMore && (
									<span
										style={{ textDecoration: "underline", color: "#ca5050", cursor: "pointer", fontWeight: "bolder" }}
										onClick={() => setShowMore(true)}
									>
										Mostrar más
									</span>
								)}
							</div>
						</div>
						<div className="alone-filter mb-0">
							<input type="checkbox" id="personalCheck" checked={myConsults} onChange={(e) => setMyConsults(e.target.checked)} />
							<label htmlFor="personalCheck">Buscar sólo en mis consultas</label>
						</div>
					</div>
				</div>
			</form>
			<div className="search-just-my-questions-container">
				<input
					name="myQuestions"
					id="myQuestions"
					checked={myConsults}
					onChange={(e) => setMyConsults(e.target.checked)}
					type="checkbox"
				/>
				<label htmlFor="myQuestions">Buscar sólo en mis consultas</label>
			</div>
			<>
				{view === 2 && pastMaterial[cardIndex] !== undefined ? (
					<>
						<div className="consult-seminary-selector-container">
							<img
								src={BackArrowIcon}
								alt="flecha hacia la izquierda"
								className={"" + (pastMaterial[cardIndex + 1] === undefined ? "filter" : "")}
								onClick={sumCardIndex}
							/>
							{pastMaterial[cardIndex + 1] !== undefined ? (
								<div className="consult-seminary-card" onClick={sumCardIndex}>
									{pastMaterial[cardIndex + 1].num > actualSeminary && (
										<div className="next-class-container">
											<img src={Calendar} alt="icono de calendario" />
											<p className="next-class" style={{ userSelect: "none" }}>
												Próxima sesión
											</p>
										</div>
									)}
									<h3 style={{ userSelect: "none" }}>Seminario {romanize(pastMaterial[cardIndex + 1].num)}</h3>
									{pastMaterial[cardIndex + 1].num <= actualSeminary ? (
										<p style={{ userSelect: "none" }}>{pastMaterial[cardIndex + 1].consultsSize} consultas</p>
									) : (
										<FontAwesomeIcon icon={faLock as any} />
									)}
									{pastMaterial[cardIndex + 1].num > sesionesPagadas && (
										<div className="block-seminary">
											<img src={EyeBlock} alt="ojo bloqueado" />
											<p style={{ userSelect: "none" }}>
												Aún no te has inscrito al módulo {getModuleNumber(pastMaterial[cardIndex + 1].num)}
											</p>
										</div>
									)}
								</div>
							) : (
								<div className="consult-seminary-card opacity-0" style={{ cursor: "default" }}></div>
							)}
							<div className="consult-seminary-card active">
								{pastMaterial[cardIndex].num > actualSeminary && (
									<div className="next-class-container">
										<img
											src={Calendar}
											style={{
												filter: "invert(0%) sepia(100%) saturate(100%) hue-rotate(290deg) brightness(100%) contrast(150%)",
											}}
											alt="icono de calendario"
										/>
										<p className="next-class" style={{ color: "#ffffff80", userSelect: "none" }}>
											Próxima sesión
										</p>
									</div>
								)}
								<h3 style={{ userSelect: "none" }}>Seminario {romanize(pastMaterial[cardIndex].num)}</h3>
								{pastMaterial[cardIndex].num <= actualSeminary ? (
									<p>{pastMaterial[cardIndex].consultsSize} consultas</p>
								) : (
									<FontAwesomeIcon icon={faLock as any} />
								)}
							</div>
							{pastMaterial[cardIndex - 1] !== undefined ? (
								<div className="consult-seminary-card" onClick={subCardIndex}>
									{pastMaterial[cardIndex - 1].num > actualSeminary && (
										<div className="next-class-container">
											<img src={Calendar} alt="icono de calendario" />
											<p className="next-class" style={{ userSelect: "none" }}>
												Próxima sesión
											</p>
										</div>
									)}
									<h3 style={{ userSelect: "none" }}>Seminario {romanize(pastMaterial[cardIndex - 1].num)}</h3>
									{pastMaterial[cardIndex - 1].num <= actualSeminary ? (
										<p style={{ userSelect: "none" }}>{pastMaterial[cardIndex - 1].consultsSize} consultas</p>
									) : (
										<FontAwesomeIcon icon={faLock as any} />
									)}
									{pastMaterial[cardIndex - 1].num > sesionesPagadas && (
										<div className="block-seminary">
											<img src={EyeBlock} alt="ojo bloqueado" />
											<p style={{ userSelect: "none" }}>
												Aún no te has inscrito al módulo {getModuleNumber(pastMaterial[cardIndex - 1].num)}
											</p>
										</div>
									)}
								</div>
							) : (
								<div className="consult-seminary-card opacity-0" style={{ cursor: "default" }}></div>
							)}
							<img
								src={ForwardArrow}
								className={"" + (pastMaterial[cardIndex - 1] === undefined ? "filter" : "")}
								alt="flecha hacia la izquierda"
								onClick={subCardIndex}
							/>
						</div>
						<div className="consult-last-post">
							{(user.userType === 2 || user.userType === 4) && pastMaterial[cardIndex].remain > 0 && (
								<div className="remain">
									<div className="remain-description">
										<p>Todavía tienes consultas por revisar.</p>
									</div>
								</div>
							)}
							<div className="content">
								<div className="header">
									<p>Última consulta</p>
								</div>
								{(
									!myConsults
										? pastMaterial[cardIndex].lastConsult.length > 0 &&
										  pastMaterial[cardIndex].num <= sesionesPagadas &&
										  pastMaterial[cardIndex].num <= actualSeminary
										: pastMaterial[cardIndex].personalConsults.length > 0 &&
										  pastMaterial[cardIndex].num <= sesionesPagadas &&
										  pastMaterial[cardIndex].num <= actualSeminary
								) ? (
									<div className="post-container">
										<div
											className="profile-pic-container"
											style={{
												backgroundColor: !myConsults
													? pastMaterial[cardIndex].lastConsult[0].profileColor
													: pastMaterial[cardIndex].personalConsults[0].profileColor,
											}}
										>
											{(!myConsults
												? pastMaterial[cardIndex].lastConsult[0].userType
												: pastMaterial[cardIndex].personalConsults[0].userType) === 2 ? (
												<img src={Logo2} alt="logo academia de lenguas internacionales" />
											) : (!myConsults
													? pastMaterial[cardIndex].lastConsult[0].userType
													: pastMaterial[cardIndex].personalConsults[0].userType) === 4 ? (
												<img src={Logo2} alt="logo colaborador " />
											) : (
												<h3>
													{getInitials(
														!myConsults
															? pastMaterial[cardIndex].lastConsult[0].name
															: pastMaterial[cardIndex].personalConsults[0].name
													)}
												</h3>
											)}
										</div>

										<div className="post-text-container">
											<div className="post-info-container">
												{pastMaterial[cardIndex].lastConsult[0].userType === 2 ? (
													<img src={AdminIcon} alt="sombrero de graduacion" />
												) : pastMaterial[cardIndex].lastConsult[0].userType === 4 ? (
													<img src={ColabComment} alt="colaborador" />
												) : null}
												{(
													!myConsults
														? pastMaterial[cardIndex].lastConsult[0].userType === 2
														: pastMaterial[cardIndex].personalConsults[0].userType === 2
												) ? (
													<h3 className="admin">Academia de Lenguas Internacionales</h3>
												) : (
													<h3>
														{!myConsults
															? pastMaterial[cardIndex].lastConsult[0].name
															: pastMaterial[cardIndex].personalConsults[0].name}
													</h3>
												)}
												{(user.userType === 2 || user.userType === 4) && (
													<p>
														{new Date(pastMaterial[cardIndex].lastConsult[0].date.seconds * 1000).toLocaleDateString("en-gb")} -{" "}
														{new Date(pastMaterial[cardIndex].lastConsult[0].date.seconds * 1000).toLocaleTimeString("en-US", {
															hour: "2-digit",
															minute: "2-digit",
														})}
													</p>
												)}
											</div>
											<div className="text-container">{parse(pastMaterial[cardIndex].lastConsult[0].postText)}</div>
											<div className="hashtag-flag-container">
												<div className="hashtag-container">
													{pastMaterial[cardIndex].lastConsult[0].hashtags.map((hashtag, index) => {
														return <p key={"hashtags-" + index}>#{hashtag}</p>;
													})}
												</div>
												<div className="flags-container">
													{pastMaterial[cardIndex].lastConsult[0].french && <img src={FranceFlagSVG} alt="bandera de francia" />}
													{pastMaterial[cardIndex].lastConsult[0].german && (
														<img src={GermanyFlagSVG} alt="bandera de alemania" />
													)}
													{pastMaterial[cardIndex].lastConsult[0].italian && <img src={ItalyFlagSVG} alt="bandera de italia" />}
													{pastMaterial[cardIndex].lastConsult[0].russian && <img src={RussiaFlagSVG} alt="bandera de rusia" />}
													{pastMaterial[cardIndex].lastConsult[0].japanese && <img src={JapanFlagSVG} alt="bandera de japon" />}
													{pastMaterial[cardIndex].lastConsult[0].chinese && <img src={ChinaFlagSVG} alt="bandera de china" />}
													{pastMaterial[cardIndex].lastConsult[0].english && (
														<img src={UnitedStatesFlagSVG} alt="bandera de estados unidos" />
													)}
													{pastMaterial[cardIndex].lastConsult[0].portuguese && (
														<img src={BrazilFlagSVG} alt="bandera de portugal" />
													)}
												</div>
											</div>
											<div className="answers-container">
												<p>{pastMaterial[cardIndex].lastConsult[0].numberOfReplies} respuestas</p>
											</div>
											<ConsultAnswers user={user} cardIndex={cardIndex} consultId={pastMaterial[cardIndex].lastConsult[0].id} />
										</div>
									</div>
								) : (
									<>
										{pastMaterial[cardIndex].num <= sesionesPagadas && pastMaterial[cardIndex].num <= actualSeminary ? (
											<div className="no-consult-container" style={{ minHeight: 302 }}>
												<h3>No hay consultas por el momento</h3>
												{(user.userType === 2 || user.userType === 4) && pastMaterial[cardIndex].remain > 0 && (
													<span style={{ color: "#ca5050" }}>Consultas pendientes de revisión</span>
												)}
											</div>
										) : (
											<div className="no-consult-container" style={{ minHeight: 302 }}></div>
										)}
									</>
								)}
							</div>
							{pastMaterial[cardIndex].num > actualSeminary && pastMaterial[cardIndex].num <= sesionesPagadas && (
								<div className="block-seminary">
									<span style={{ fontSize: "2rem", color: "white" }}>
										<FontAwesomeIcon icon={faLock as any} />
									</span>
									<span style={{ color: "#ffffff" }}>Aún no se ha llevado a cabo estee seminario.</span>
								</div>
							)}
							{pastMaterial[cardIndex].num > sesionesPagadas && (
								<div className="block-seminary">
									<img src={EyeBlock} alt="ojo bloqueado" />
									<p>Aún no tienes acceso a este módulo.</p>
									<button className="return-btn-style" onClick={() => history.push("/micuenta/historial-de-pagos")}>
										Inscríbete
									</button>
								</div>
							)}
							<div className="footer">
								{pastMaterial[cardIndex].num <= actualSeminary && pastMaterial[cardIndex].num <= sesionesPagadas ? (
									<Link to={`${path}/` + pastMaterial[cardIndex].num}>
										<p>Leer el resto de consultas</p>
									</Link>
								) : (
									<p>Leer el resto de consultas</p>
								)}
								<img src={NextArrow} alt="siguiente flecha" />
							</div>
						</div>
						{pastMaterial[cardIndex].num <= actualSeminary && pastMaterial[cardIndex].num <= sesionesPagadas && (
							<>
								<div style={{ margin: "auto", maxWidth: 900, marginTop: 40 }}>
									<h3 className="doubts-text-consults-page">¿Tienes alguna duda?</h3>
								</div>
								<div style={{ maxWidth: 900, margin: "auto" }}>
									{loadingConsult ? (
										<Spinner height={300} />
									) : (
										<TextEditor
											popularHashtags={popularHashtags2}
											langDirection={"down"}
											consultPost={consultPost}
											setConsultPost={setConsultPost}
											consultLangs={consultLangs}
											setSend={setSend}
											setConsultLangs={setConsultLangs}
											setHashtags={setHashtags}
											hashtags={hashtags}
											id={"0"}
										/>
									)}
								</div>
							</>
						)}
					</>
				) : (
					<div className="consult-seminary-cards-container">
						{pastMaterial.map((consults, index) => {
							return (
								<>
									<Suspense fallback={<Spinner height={200} />}>
										<SearchCard
											getModuleNumber={getModuleNumber}
											user={user}
											actualSeminary={actualSeminary}
											numPaid={sesionesPagadas}
											moduleSelector={moduleSelector}
											isSearching={isSearching}
											consult={consults}
											myConsults={myConsults}
											hashtagFilter={hashtagFilter}
											filterText={filterText}
											portuguese={portuguese}
											french={french}
											italian={italian}
											german={german}
											english={english}
											japanese={japanese}
											chinese={chinese}
											russian={russian}
											index={index}
										/>
									</Suspense>
								</>
							);
						})}
					</div>
				)}
			</>
		</>
	);
}

function Spinner({ height }) {
	return (
		<div style={{ height: height, display: "flex", justifyContent: "center", alignItems: "center" }}>
			<div className="spinner-border text-danger" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
}

export default ConsultContainer;
