import { useAuth } from "@hooks/useAuth";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

const IsSeminaryPaid = ({ children }) => {
	const history = useHistory();
	const { num } = useParams<any>();
	const [sesionesPagadas, setSesionesPagadas] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);
	const [actualSeminary, setActualSeminary] = useState<number>(0);

	let user = useAuth().user;

	function compare2(a, b) {
		if (a.num < b.num) {
			return -1;
		}
		if (a.num > b.num) {
			return 1;
		}
		return 0;
	}

	useEffect(() => {
		const getPayments = async (gen) => {
			const qry = query(
				collection(db, "paymentHistory"),
				where("userId", "==", user.id),
				where("verified", "==", true)
			);
			const qrySnapshot = await getDocs(qry);

			let i = 0;
			let total = 0;
			let sesionesPagadas = 0;

			qrySnapshot.forEach(async (doc) => {
				i++;
				total = total + parseInt(doc.data().amount);
				if (i === qrySnapshot.size) {
					//sesiones pagadas, sesion actual,
					if (
						(total === 12000 && qrySnapshot.size === 1) ||
						user.promotion === true ||
						user.userType === 2 ||
						user.userType === 4
					) {
						total = 18000;
					}
					sesionesPagadas = Math.floor(total / 300);
				}
			});

			if (
				(qrySnapshot.size === 0 && user.promotion === true) ||
				user.userType === 2 ||
				user.userType === 4
			) {
				sesionesPagadas = 60;
			} else if (qrySnapshot.size === 0 && user.promotion === false) {
				sesionesPagadas = 0;
			}

			setSesionesPagadas(sesionesPagadas);
			getActualSeminary(gen);
		};

		const getActualSeminary = async (gen) => {
			let nextMaterial: any = [];
			let pastMaterialArray: any = [];
			const q = query(collection(db, "generations", gen.id, "material"));

			const querySnapshot = await getDocs(q);

			let i = 0;
			const date = new Date();

			querySnapshot.forEach((doc) => {
				let materialDate = new Date();
				i++;
				// doc.data() is never undefined for query doc snapshots
				const data2: any = {
					...doc.data(),
					id: doc.id,
				};

				let materialDateSplit = data2.date.split("/");
				materialDate.setDate(materialDateSplit[0]);
				materialDate.setMonth(materialDateSplit[1]);
				materialDate.setFullYear(materialDateSplit[2]);
				materialDate.setHours(0, 0, 0);

				if (date < materialDate) {
					nextMaterial.push(data2);
				} else {
					pastMaterialArray.push(data2);
				}
				if (i === querySnapshot.size) {
					pastMaterialArray.sort(compare2);
					if (user.userType === 2 || user.userType === 4) {
						setActualSeminary(60);
					} else {
						if (pastMaterialArray[pastMaterialArray.length - 1] !== undefined) {
							setActualSeminary(pastMaterialArray[pastMaterialArray.length - 1].num);
						} else {
							setActualSeminary(0);
						}
					}
					setLoading(false);
				}
			});
		};

		const getGen = async () => {
			if (user.generationId !== undefined && user.generationId !== "") {
				const docRef = doc(db, "generations", user.generationId);
				const docSnap = await getDoc(docRef);

				getPayments(docSnap.data());
			} else {
				if (window.location.href.includes("consultas")) {
					history.push("/micuenta/consultas");
				} else if (window.location.href.includes("vocabulario")) {
					history.push("/micuenta/vocabulario");
				} else {
					history.go(-1);
				}
			}
		};

		getGen();
	}, []);

	if (loading) return <></>;

	if (
		parseInt(num) > sesionesPagadas ||
		parseInt(num) >
			(window.location.href.includes("consultas") ? actualSeminary : actualSeminary + 1) ||
		parseInt(num) > 60 ||
		parseInt(num) < 0 ||
		sesionesPagadas === 0
	) {
		return (
			<>
				{window.location.href.includes("consultas") && history.push("/micuenta/consultas")}
				{window.location.href.includes("vocabulario") && history.push("/micuenta/vocabulario")}
			</>
		);
	}

	return <>{children}</>;
};

export default IsSeminaryPaid;
