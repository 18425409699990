import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import "./styles.scss";
import emailjs from "emailjs-com";
import { LogoBig } from "assets/Imports";
import { useAuth } from "@hooks/useAuth";

function ThankyouContainer({ user }) {
	const history = useHistory();
	const location = useLocation<any>();

	if (location.state === undefined) {
		history.push({
			pathname: "/",
		});
	}

	const { updateUser } = useAuth();
	const order = location.state.order;
	const student = location.state.student;
	const firstPay = location.state.firstPay;

	//const student = { name: "javier", email: "fjcenteno99@gmail.com" }
	//const order = { id: "2332234sadfaf", amount: 300000, line_items: { data: [{ name: "testing" }] }, updated_at: 1644871074 }
	//let firstPay = false;

	var factura = new Date((order.updated_at || order.created) * 1000);
	var month;

	switch (factura.getMonth()) {
		case 0:
			month = "enero";
			break;
		case 1:
			month = "febrero";
			break;
		case 2:
			month = "marzo";
			break;
		case 3:
			month = "abril";
			break;
		case 4:
			month = "mayo";
			break;
		case 5:
			month = "junio";
			break;
		case 6:
			month = "julio";
			break;
		case 7:
			month = "agosto";
			break;
		case 8:
			month = "septiembre";
			break;
		case 9:
			month = "octubre";
			break;
		case 10:
			month = "noviembre";
			break;
		case 11:
			month = "diciembre";
			break;
	}

	const sendEmail = () => {
		const data = {
			name: student.name,
			email: student.email,
			cantidad: order.amount / 100,
			fecha: factura.getDate() + " de " + month + " de " + factura.getFullYear(),
			ordenId: order.id,
		};

		emailjs.send("service_1kfzhtl", "template_90x5hvj", data, "user_PPI0O8nofs9cbuJ3JRWlT").then(
			function (res) {
				console.log("enviado");
			},
			function (error) {
				console.error(error);
			}
		);
	};

	useEffect(() => {
		if (user) {
			updateUser();
		}
		sendEmail();
	}, []);

	return (
		<>
			<div className="thankyou_container">
				<div style={{ alignSelf: "flex-start" }}>
					{!firstPay ? <h1>Comprobante de pago</h1> : <h1>Comprobante de inscripción</h1>}
				</div>
				<div className="recibo-container">
					<h2 className="thanks">
						Gracias, {student.name}. <br /> Tu pago se ha realizado con éxito.
					</h2>
					<br />
					<p className="send-text">
						Envía este recibo de pago al teléfono{" "}
						<a href="https://wa.me/525623925453">+52 56 2392 5453</a>, al{" "}
						<a href="https://wa.me/52555154848431">+52 55 5184 8431</a> o a la dirección de correo
						electrónico:{" "}
						<a
							className="contact-email-text"
							href="mailto:contacto@academiadelenguasinternacionales.mx"
						>
							<span style={{ color: "#007bff", textDecoration: "underline" }}>
								contacto@academiadelenguasinternacionales.mx
							</span>
						</a>{" "}
						para registrar la transacción correctamente.
					</p>
					{user === null && (
						<p className="send-text" style={{ textAlign: "center" }}>
							Inicia sesión para registrar tu pago.
						</p>
					)}
					<h2 className="welcome-thankyou order-data-variable" style={{ textAlign: "center" }}>
						{firstPay ? "¡Bienvenid@ a la Academia de Lenguas Internacionales!" : "Recibo de pago"}
					</h2>
					<h4 className="m-0" style={{ textAlign: "center" }}>
						{order.metadata.name} {order.amount === 1500000 ? " - Presencial" : ""}
					</h4>
					<p className="order-data-variable" style={{ textAlign: "center" }}>
						${(order.amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
					</p>
					<h4 className="m-0" style={{ textAlign: "center" }}>
						Número de orden:
					</h4>
					<p className="order-data-variable" style={{ textAlign: "center" }}>
						{order.id}
					</p>
					<h4 className="m-0" style={{ textAlign: "center" }}>
						Fecha de realización:
					</h4>
					<p className="order-data-variable" style={{ textAlign: "center" }}>
						{factura.getDate()} de {month} de {factura.getFullYear()}
					</p>
					<h4 className="m-0" style={{ textAlign: "center" }}>
						Nombre del estudiante:
					</h4>
					<p className="order-data-variable" style={{ textAlign: "center" }}>
						{student.name}
					</p>
					<Image fluid src={LogoBig} alt="Logo Academia" />
				</div>
				<div className="return-invoice-btns-container">
					<button
						className="tertiary return-btn-style"
						onClick={() => history.push({ pathname: "/" })}
						style={{ width: "300px" }}
					>
						Regresar
					</button>
					{user === null && (
						<button className="tertiary return-btn-style" onClick={() => history.push("/ingresar")}>
							Iniciar sesión
						</button>
					)}
					<button
						className="tertiary"
						onClick={() => history.push({ pathname: "/facturacion" })}
						style={{ width: "300px" }}
					>
						Solicitar factura
					</button>
				</div>
			</div>
		</>
	);
}

export default ThankyouContainer;
