import { areWordsDominated } from "@api/Vocabulary";
import { Spinner20vh, SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import {
	getUniqueTotalWords,
	getUniqueTotalWordsArray,
} from "@containers/VocabularySeminaryContainer/components/Test/functions/TestFunctions";
import { blockDate } from "@containers/VocabularySeminaryContainer/functions/VocabularyFunctions";
import { returnFlagByLanguage, romanize } from "functions/Functions";
import { Quizzes } from "interfaces/Interfaces";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./LastQuizzesStyles.scss";

interface lastQuizzesProps {
	quiz: Quizzes;
	user: any;
}

function LastQuizzes(props: lastQuizzesProps) {
	const date = new Date(props.quiz.createdAt.seconds * 1000);
	const totalWords = getUniqueTotalWords(props.quiz.quizWords);
	const progress = props.quiz.correctWords.length;
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(true);
	const [wordsDominated, setWordsDominated] = useState<number>(0);
	const [isBlocked, setIsBlocked] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);

	// BLOCK DATES
	const date1: Date = blockDate("date1");
	const date2: Date = blockDate("date2");
	const date3: Date = blockDate("date3");

	const countDominated = async () => {
		setLoading(true);
		setError(false);
		try {
			await areWordsDominated(
				props.user.vocabularyUserId,
				getUniqueTotalWordsArray(props.quiz.quizWords)
			).then((res) => {
				setWordsDominated(res.data.words.filter((e) => e.dominated === 3).length);
				setLoading(false);
			});
		} catch (error) {
			console.error(error);
			setError(true);
		}
	};

	useEffect(() => {
		const date = new Date(props.quiz.createdAt.seconds * 1000);

		if (date < date3 || (date < date1 && date > date2)) {
			setIsBlocked(true);
		}
		countDominated();
	}, [props.quiz]);

	return (
		<div
			className="last-quiz-card"
			onClick={
				loading
					? () => {}
					: isBlocked
					? () =>
							history.push({
								pathname: "/micuenta/vocabulario/" + props.quiz.seminaryNum,
								state: {
									view: 4,
								},
							})
					: () =>
							history.push({
								pathname: "/micuenta/vocabulario/" + props.quiz.seminaryNum,
								state: {
									lastQuiz: props.quiz.id,
									finished: props.quiz.finished,
								},
							})
			}
		>
			{loading || error ? (
				!error ? (
					<SpinnerCustom height={133} />
				) : (
					<div style={{ height: 133 }}>
						<div className="block-card-quiz">
							<h3 className="block-quiz-title">Hubo un error al cargar esta ronda de estudio.</h3>
							<div
								className="d-flex justify-content-center flex-column align-items-center"
								style={{ gap: 5 }}
							>
								<button onClick={countDominated} className="primary-btn">
									Reintentar
								</button>
							</div>
						</div>
					</div>
				)
			) : (
				<>
					<div className="header">
						<p className="item">
							{date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()}
						</p>
						<div className="item image-container">{returnFlagByLanguage(props.quiz.language)}</div>
						<p className="item item2">{props.quiz.correctWords.length + "/" + totalWords}</p>
					</div>
					<div className="title">
						<h3>{props.quiz.name}</h3>
					</div>
					<div className="footer">
						<div className="item flex-column">
							<div className="words-progress-bar-container">
								<div className="w-100 text-center">
									<div className="words-progress-bar dominated">
										<div
											className="words-progress"
											style={{ width: (wordsDominated / totalWords) * 100 + "%" }}
										></div>
									</div>
								</div>
							</div>
							<label>Términos dominados</label>
						</div>
						<div className="item">
							<p className="seminary-number">{romanize(props.quiz.seminaryNum)}</p>
						</div>
						<div className="item flex-column">
							<div className=" words-progress-bar-container">
								<div className="words-progress-bar">
									<div
										className="words-progress"
										style={{ width: (progress / totalWords) * 100 + "%" }}
									></div>
								</div>
							</div>
							<label>Términos correctos</label>
						</div>
					</div>
					{isBlocked && (
						<div className="block-card-quiz">
							<h3 className="block-quiz-title">
								Esta ronda de estudio no está disponible de momento.
							</h3>
							<div
								className="d-flex justify-content-center flex-column align-items-center"
								style={{ gap: 5 }}
							>
								<button
									onClick={() => history.push("/micuenta/vocabulario/" + props.quiz.seminaryNum)}
									className="primary-btn"
								>
									Crear nueva ronda
								</button>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default LastQuizzes;
