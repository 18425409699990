import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	setDoc,
	getDoc,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import "./PaymentRequestCardStyle.css";
import { db } from "initFirebase";
import emailjs from "emailjs-com";
import { getMatricula } from "functions/Functions";
import { GenerateReceiptModal } from "@components/GenerateReceiptModal/GenerateReceiptModal";
import {
	Cardicon,
	CashIcon,
	OxxoIcon,
	PaypalLogo,
	ReceiptIcon,
	SPEIIcon,
	TrashIcon,
	WalletIcon,
	ArchivedIcon,
	Unarchived,
} from "assets/Imports";
import { UserProfileModal } from "../UserProfileModal/UserProfileModal";
import moment from "moment";
import useCollaboratorLogs, { collaborator, user } from "@hooks/collaborators/useCollaboratorLogs";
import { useLocation } from "react-router-dom";
import { GenerationTypesToSpanish, isValidGenType } from "interfaces/Generations";

function PaymentRequestCard({
	data,
	index,
	setRefresh,
	refresh,
	collaborator,
	highlighted,
	isArchived,
	setIsArchived,
	handleClickBackToPayments,
}) {
	const date = new Date(data.date);
	const dateInfo = data.createdAt.split("/");
	const createdAtDate = new Date();
	createdAtDate.setDate(dateInfo[0]);
	createdAtDate.setFullYear(dateInfo[2]);
	createdAtDate.setMonth(dateInfo[1]);
	const [check, setCheck] = useState(true);
	const [loading, setLoading] = useState(true);
	const [user, setUser]: any = useState(null);
	const [modules, setModules]: any = useState([]);
	const [studentModal, setStudentModal] = useState(false);
	const [gen, setGen]: any = useState(null);
	const [student, setStudent]: any = useState(null);
	const [modalidad, setModalidad] = useState<string>(data.modalidad ? data.modalidad : "online-am");
	const [paymentType, setPaymentType] = useState<string>(data.medio);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [newDate, setNewDate] = useState<any>();
	const dropdownPaymentValues = [
		"Tarjeta",
		"Efectivo",
		"OXXO Pay",
		"SPEI",
		"PayPal",
		"Transferencia",
		"Depósito bancario",
		"Otro",
	];
	const [userProfileModal, setUserProfileModal] = useState<boolean>(false);
	const { paymentRequestLog } = useCollaboratorLogs();
	const newDateForm = async () => {
		let NewDateS = moment(date, "DD-MM-YYYY").valueOf();
		let date1: Date = new Date(NewDateS);
		let DateString1: string =
			date1.getDate() + "/" + (date1.getMonth() + 1) + "/" + date1.getFullYear();
		setNewDate(DateString1);
	};
	const getUser = async () => {
		setLoading(true);
		const userRef = doc(db, "users", data.userId);
		const docSnap = await getDoc(userRef);
		setUser({ id: docSnap.id, ...docSnap.data() });

		if (docSnap.data() !== undefined && docSnap.data()?.generationId !== "") {
			const genRef = doc(db, "generations", docSnap.data()?.generationId);
			const genSnap = await getDoc(genRef);

			if (genSnap.exists()) {
				setGen({ id: genSnap.id, ...genSnap.data() });
				getStudent({ id: genSnap.id, ...genSnap.data() }, { id: docSnap.id, ...docSnap.data() });
			}
		} else {
			getStudent({ initialDate: "10/10/2022" }, { id: docSnap.id, ...docSnap.data() });
		}
	};
	const [receiptModal, setReceiptModal] = useState<boolean>(false);

	const getStudent = async (gen, user) => {
		let dateSplit = gen.initialDate.split("/");
		const date = new Date();
		date.setDate(dateSplit[0]);
		date.setMonth(dateSplit[1] - 1);
		date.setFullYear(dateSplit[2]);
		let userr = {};
		let pagos = new Array();
		let totalPay = 0;
		let status = "";
		let weeks = 0;
		const q = query(
			collection(db, "paymentHistory"),
			where("userId", "==", user.id),
			where("verified", "==", true)
		);

		const querySnapshot = await getDocs(q);

		querySnapshot.forEach((doc) => {
			totalPay = totalPay + parseInt(doc.data().amount);
			pagos.push({ id: doc.id, ...doc.data() });
		});

		if ((querySnapshot.size === 1 && totalPay === 12000) || user.promotion === true) {
			status = "Pago completo";
			userr = {
				...user,
				status: status,
				totalPay: totalPay,
				numPayments: querySnapshot.size,
				paidModules: 5,
				pagos: pagos.sort(comparePaymentsDate),
			};
		} else {
			let modules = 0;
			if (totalPay >= 3000 && totalPay < 7500) {
				modules = 1;
			} else if (totalPay >= 7500 && totalPay < 12000) {
				modules = 2;
			} else if (totalPay >= 12000 && totalPay < 15000) {
				modules = 3;
			} else if (totalPay >= 15000 && totalPay < 18000) {
				modules = 4;
			} else if (totalPay == 18000) {
				modules = 5;
			} else {
				modules = 0;
			}
			weeks = Math.floor(totalPay / 1500) * 5;
			let nextDate = new Date();
			nextDate.setDate(dateSplit[0]);
			nextDate.setMonth(dateSplit[1] - 1);
			nextDate.setFullYear(dateSplit[2]);
			let now = new Date();
			nextDate.setDate(date.getDate() + weeks * 7);
			nextDate.setHours(0, 0, 0);
			if (now > nextDate) {
				status = "Pago retrasado";
			} else {
				status = "Pago completo";
			}

			userr = {
				...user,
				status: status,
				totalPay: totalPay,
				numPayments: querySnapshot.size,
				nextDate: nextDate,
				paidModules: modules,
				pagos: pagos.sort(comparePaymentsDate),
			};
		}
		setStudent(userr);
		setLoading(false);
	};
	const changeNumber = () => {
		let phone = "";
		if (data.phone) {
			phone = data.phone.replace(/\s+/g, "");
			switch (phone.length) {
				case 12:
					phone = phone.replace(/(\S{2})(\S{2})(\S{4})(\S{4})/, "$1 $2 $3 $4");
					break;
				case 13:
					phone = phone.replace(/(\S{3})(\S{2})(\S{4})(\S{4})/, "$1 $2 $3 $4");
					break;
				default:
					phone = phone.replace(/(\S{2})(\S{4})(\S{1,})/, "$1 $2 $3");
			}
		}
		setPhoneNumber(phone);
	};

	const seeReceipt = () => {
		window.open(data.image, "_blank");
	};

	const deniedRequest = async () => {
		if (window.confirm("¿Estás seguro de que deseas negar esta solicitud?")) {
			const message = window.prompt("Mensaje", "");
			setLoading(true);
			setLoading(true);
			const requestRef = doc(db, "paymentHistory", data.id);
			await updateDoc(requestRef, {
				checked: true,
				medio: paymentType,
				verified: false,
				message: message,
				...(collaborator
					? { rejectedByName: collaborator.name, rejectedById: collaborator.id }
					: {}),
			}).then(async () => {
				if (collaborator) {
					let tempCollaborator: collaborator = {
						id: collaborator.id,
						name: collaborator.name,
						role: collaborator.collaborator_rol ?? "",
					};
					let tempUser: user = { name: data.name, id: data.userId };
					await paymentRequestLog(
						"reject",
						tempCollaborator,
						tempUser,
						parseFloat(data.amount),
						data.image,
						data.id,
						undefined,
						message ?? ""
					);
				}
				await addDoc(collection(db, "notifications"), {
					name: "Academia de Lenguas Internacionales",
					text: "<p>Solicitud de actualización de pago rechazada</p>",
					date: new Date(),
					userId: user.id,
					userType: 2,
					seen: false,
					profileColor: user.color,
					type: "denied-payment",
					paymentHistoryId: data.id,
				});

				const notificationQuery = query(
					collection(db, "notifications"),
					where("paymentHistoryId", "==", data.id)
				);
				const snap = await getDocs(notificationQuery);
				if (snap.size > 0) {
					const notificationRef = doc(db, "notifications", snap.docs[0].id);
					await updateDoc(notificationRef, {
						denied: true,
						seen: true,
					});
				}
			});
			setRefresh(!refresh);
			setLoading(false);

			handleClickBackToPayments();
		}
	};

	const checkRepeatedPayment = async () => {
		let repeated: boolean;
		const q = query(
			collection(db, "paymentHistory"),
			where("checked", "==", true),
			where("verified", "==", true),
			where("createdAt", "==", data.createdAt),
			where("name", "==", data.name),
			where("amount", "in", [data.amount.toString(), parseInt(data.amount)])
		);
		const querySnapshot = await getDocs(q);
		if (querySnapshot.size > 0) {
			repeated = true;
		} else {
			repeated = false;
		}
		return repeated;
	};

	const acceptRequest = async () => {
		let response = await checkRepeatedPayment();
		let confirmMessage: string =
			response === true
				? "Ya existe una solicitud de pago aceptada de hoy con el mismo nombre y monto, ¿estás seguro de que deseas aceptar esta solicitud?"
				: "¿Estás seguro de que deseas aceptar esta solicitud?";
		if (window.confirm(confirmMessage)) {
			const genQuery = query(
				collection(db, "generations"),
				where("type", "==", data.modalidad),
				where("active", "==", true)
			);
			const genRef = await getDocs(genQuery);
			if (genRef.empty) {
				alert(
					"No se encontro una generacion activa con esta modalidad, intenta cambiar de modalidad"
				);
				return;
			}

			const gen: any = { id: genRef.docs[0].id, ...genRef.docs[0].data() };

			if (gen.limit === 0 && user.generationId === "") {
				alert("Ya no hay espacios para la generación presencial.");
			} else {
				let total = parseInt(data.amount);
				let i = 0;

				const paymentsQuery = query(
					collection(db, "paymentHistory"),
					where("userId", "==", data.userId),
					where("verified", "==", true)
				);
				const paymentRef = await getDocs(paymentsQuery);
				const payment = await paymentRef.size;

				paymentRef.forEach((doc) => {
					i++;
					total = total + parseInt(doc.data().amount);
				});

				setLoading(true);

				const now = new Date();
				const acceptDate = now.getDate() + "/" + (now.getMonth() + 1) + "/" + now.getFullYear();

				const requestRef = doc(db, "paymentHistory", data.id);
				await updateDoc(requestRef, {
					checked: true,
					verified: true,
					medio: paymentType,
					acceptDate: acceptDate,
					...(collaborator
						? { acceptedByName: collaborator.name, acceptedById: collaborator.id }
						: {}),
				}).then(async () => {
					if (collaborator) {
						let tempCollaborator: collaborator = {
							id: collaborator.id,
							name: collaborator.name,
							role: collaborator.collaborator_rol ?? "",
						};
						let tempUser: user = { name: data.name, id: data.userId };
						await paymentRequestLog(
							"accept",
							tempCollaborator,
							tempUser,
							parseFloat(data.amount),
							data.image,
							data.id,
							true
						);
					}

					if (total >= 3000 && user.generationId === "") {
						const splitGenDate = gen.initialDate.split("/");
						const date = new Date();
						const q = query(collection(db, "users"), where("generationId", "==", gen.id));
						const querySnapshot = await getDocs(q);

						let num = querySnapshot.size + 1;

						let matricula = await getMatricula(num, gen.sede, splitGenDate);

						const userRef = doc(db, "users", data.userId);
						await updateDoc(userRef, {
							generationId: gen.id,
							matricula: matricula,
							schedule: gen.schedule,
							addedAt: new Date(),
						});

						const g = doc(db, "generations", gen.id);

						await updateDoc(g, {
							limit: gen.limit - 1,
						});
					} else if (total >= 3000 && user.matricula === "" && user.generationId !== "") {
						const genRef = doc(db, "generations", user.generationId);
						const docSnap: any = await getDoc(genRef);
						const splitGenDate = docSnap.data().initialDate.split("/");
						const q = query(
							collection(db, "users"),
							where("generationId", "==", user.generationId)
						);
						const querySnapshot = await getDocs(q);

						let num = querySnapshot.size;

						let matricula = await getMatricula(num, gen.sede, splitGenDate);

						const userRef = doc(db, "users", data.userId);
						await updateDoc(userRef, {
							matricula: matricula,
						});
					}

					const datos = {
						name: data.name,
						email: user.email,
						orderId: data.orderId,
						cantidad: data.amount,
						fecha:
							createdAtDate.getDate() +
							"/" +
							(createdAtDate.getMonth() + 1) +
							"/" +
							createdAtDate.getFullYear(),
					};
					await emailjs
						.send("service_1kfzhtl", "template_vf4px0h", datos, "user_PPI0O8nofs9cbuJ3JRWlT")
						.then(
							function (res) {
								console.log("enviado con exito");
							},
							function (error) {
								console.error(error);
							}
						);

					await addDoc(collection(db, "notifications"), {
						name: "Academia de Lenguas Internacionales",
						text: "<p>Solicitud de actualización de pago aceptada</p>",
						date: new Date(),
						userId: user.id,
						userType: 2,
						seen: false,
						profileColor: user.color,
						type: "aproved-payment",
						paymentHistoryId: data.id,
					});

					const notificationQuery = query(
						collection(db, "notifications"),
						where("paymentHistoryId", "==", data.id),
						where("type", "==", "new-request-payment")
					);
					const snap = await getDocs(notificationQuery);
					if (snap.size > 0) {
						const notificationRef = doc(db, "notifications", snap.docs[0].id);
						await updateDoc(notificationRef, {
							check: true,
							seen: true,
						});
					}
				});
				setCheck(false);
				setRefresh(!refresh);
				setLoading(false);
			}
			handleClickBackToPayments();
		}
	};

	const handleModalidadChange = async (e) => {
		setModalidad(e.target.value);

		const docRef = await doc(db, "paymentHistory", data.id);

		await updateDoc(docRef, {
			modalidad: e.target.value,
		}).then(() => {
			setRefresh(!refresh);
		});
	};

	const deleteRequest = async () => {
		if (window.confirm("¿Estás seguro que deseas eliminar esta solicitud?")) {
			setLoading(true);
			await deleteDoc(doc(db, "paymentHistory", data.id)).then(() => {
				setRefresh(!refresh);
				setLoading(false);
			});

			handleClickBackToPayments();
		}
	};

	const seeUserProfile = async (gen) => {
		let today = new Date();
		let dateSplit =
			gen !== null
				? gen.initialDate.split("/")
				: [today.getDate(), today.getMonth(), today.getFullYear()];
		const date = new Date();
		date.setDate(dateSplit[0]);
		date.setMonth(dateSplit[1] - 1);
		date.setFullYear(dateSplit[2]);

		setStudentModal(true);
	};

	function comparePaymentsDate(a, b) {
		let x = a.createdAt.split("/");
		let y = b.createdAt.split("/");
		const date1 = new Date();
		const date2 = new Date();
		date1.setDate(x[0]);
		date1.setMonth(x[1]);
		date1.setFullYear(x[2]);
		date2.setDate(y[0]);
		date2.setMonth(y[1]);
		date2.setFullYear(y[2]);

		if (date1 > date2) {
			return -1;
		}
		if (date1 < date2) {
			return 1;
		}
		return 0;
	}
	useEffect(() => {
		changeNumber();
		getUser();
		newDateForm();
	}, [refresh]);

	async function Archived(documentoId: string) {
		const documentRef = doc(db, "paymentHistory", documentoId);
		try {
			await setDoc(documentRef, { isArchived: data.isArchived ? false : true }, { merge: true });
			setIsArchived(!isArchived);
		} catch (error) {
			console.error("Error al agregar el documento:", error);
		}
	}

	return (
		<>
			{student !== null && userProfileModal && !loading && (
				// <ProfileModal
				// 	student={student}
				// 	modules={modules}
				// 	studentModal={studentModal}
				// 	setStudentModal={setStudentModal}
				// 	gen={gen}
				// 	setRefresh={setRefresh}
				// 	refresh={refresh}
				// 	vocabularyStreak={undefined}
				// 	studiedDays={undefined}
				// />

				<UserProfileModal
					user={student}
					setRefresh={setRefresh}
					refresh={refresh}
					show={userProfileModal}
					onHide={() => setUserProfileModal(false)}
					key={student.id}
					collaborator={collaborator}
				/>
			)}
			<GenerateReceiptModal
				show={receiptModal}
				setRefresh={setRefresh}
				payment={data}
				onHide={() => setReceiptModal(false)}
			/>
			<div
				className={`payment-history-card-container ${highlighted ? "highlighted" : ""}`}
				style={{ flexDirection: "column" }}
				id={"request-card-" + data.id}
			>
				<div
					className={"payment-history-card-first-section "}
					style={{
						display: "flex",
						width: "100%",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<div className="payment-history-right-content-card-container">
						<div className="icon-payment-history-container">
							{paymentType === "OXXO Pay" && <img src={OxxoIcon} />}
							{paymentType === "Efectivo" && <img src={CashIcon} />}
							{paymentType === "Otro" && <img src={ReceiptIcon} />}
							{paymentType === "Tarjeta" && <img src={Cardicon} />}
							{paymentType === "SPEI" && <img src={SPEIIcon} />}
							{paymentType === "PayPal" && <img src={PaypalLogo} alt="paypal-loco-icon" />}
							{(data.medio === "Transferencia/Deposito bancario" ||
								data.medio === "Transferencia/Depósito bancario" ||
								data.medio === "Transferencia" ||
								data.medio === "Depósito bancario") && <img src={WalletIcon} alt="wallet-icon" />}
						</div>
						<div className="text-payment-history-container">
							{check === true || data.checked === true ? (
								<>
									<p>
										<a className="title-red">
											$
											{parseInt(data.amount.toString().replace(/\$/g, "").replace(/,/g, ""))
												.toFixed(2)
												.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
											MXN
										</a>
									</p>
									<p>{data.name}</p>
								</>
							) : (
								<p style={{ fontWeight: 500, color: "#000000" }}>
									$
									{parseInt(data.amount.toString().replace(/\$/g, ""))
										.toFixed(2)
										.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
									MXN
								</p>
							)}
							<p className="realizacion-date">
								<span>Fecha de solicitud:</span>{" "}
								{createdAtDate.getDate() +
									"/" +
									(createdAtDate.getMonth() + 1) +
									"/" +
									createdAtDate.getFullYear()}
							</p>
							{date && typeof date !== "string" ? (
								<p className="realizacion-date">
									<span>Fecha de pago:</span>{" "}
									{date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()}
								</p>
							) : (
								<p className="realizacion-date">
									<span>Fecha de pago:</span> {newDate}
								</p>
							)}
							{data.acceptedByName && data.checked === true && data.verified === true && (
								<p className="realizacion-date">
									<span>Aprobado por:</span> {data.acceptedByName}
								</p>
							)}
							{data.rejectedByName && data.checked === true && data.verified === false && (
								<p className="realizacion-date">
									<span>Negado por:</span> {data.rejectedByName}
								</p>
							)}
							{check === true && data.checked === false && (
								<>
									<p className="realizacion-date" style={{ width: "max-content" }}>
										<span>Generación:</span>{" "}
										{gen?.name === "" ? "No inscrito." : gen?.name?.slice(56)}
									</p>
									<p>{data.email}</p>
									<p>{phoneNumber}</p>
								</>
							)}
						</div>
					</div>
					{data.checked === false && !data.verified ? (
						check === false ? (
							<div className="payment-history-left-content-card-container">
								<button
									className="tertiary"
									style={{ height: 45, padding: "10px 15px", minWidth: 90 }}
									onClick={() => setCheck(!check)}
								>
									Revisar
								</button>
							</div>
						) : (
							<div
								className="payment-history-left-content-card-container"
								style={{ alignSelf: "flex-start" }}
							>
								<button className="close-btn-history-card" onClick={() => setCheck(!check)}>
									x
								</button>
								<button
									className="see-profile-btn"
									onClick={() => setUserProfileModal(true)} /*onClick={() => seeUserProfile(gen)}*/
								>
									Ver perfil
								</button>
							</div>
						)
					) : (
						<div className="d-flex align-items-center" style={{ flexDirection: "column" }}>
							{data.checked === true && data.verified === false ? (
								<p className="text-down" style={{ color: "#B13536" }}>
									Negada
								</p>
							) : (
								<p
									className="text-down"
									style={data.verified ? { color: "#02A377" } : { color: "#FE9A12" }}
								>
									{data.verified ? "Aceptado" : "En revisión"}
								</p>
							)}
							<button
								className="see-profile-btn m-0"
								onClick={() => setUserProfileModal(true)} /*onClick={() => seeUserProfile(gen)}*/
							>
								Ver perfil
							</button>
						</div>
					)}
				</div>
				{check === true && data.checked === false && data.verified === false && student !== null ? (
					<>
						<div style={{ width: "100%" }}>
							<div style={{ marginLeft: 65 }}>
								<p className="m-0" style={{ margin: 0, marginTop: 10 }}>
									Concepto:
								</p>
								<p>{data.concept}</p>
							</div>
							<div style={{ marginLeft: 65 }}>
								<p className="m-0" style={{ margin: 0, marginTop: 10 }}>
									Modalidad:
								</p>
								<p>{GenerationTypesToSpanish[data.modalidad]}</p>
							</div>
							{(user.generationId === "" || data.modalidad === "online") && (
								<div style={{ marginLeft: 65, marginBottom: 10 }}>
									<p className="m-0">Cambiar modalidad</p>
									<select
										className="select-modalidad"
										name="modalidad"
										id="modalidad-selector"
										value={modalidad}
										onChange={handleModalidadChange}
									>
										<option value="online" disabled>
											En línea (modalidad obsoleta)
										</option>
										<option value="online-pm">En línea vespertino</option>
										<option value="online-am">En línea matutino</option>
										<option value="in-person">Presencial</option>
									</select>
								</div>
							)}

							<div style={{ marginLeft: 65, marginBottom: 10 }}>
								<p className="m-0">Método de pago</p>
								<select
									className="select-modalidad"
									name="payment-type"
									id="payment-type-selector"
									value={paymentType}
									onChange={(e) => setPaymentType(e.target.value)}
								>
									{dropdownPaymentValues.map((type, i) => {
										return (
											<option value={type} key={"payment-type-value-option-" + i}>
												{type}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className="buttons-container-request-card">
							{data.image !== "" && <button onClick={seeReceipt}>Ver comprobante</button>}
							{loading === false ? (
								<div style={{ marginLeft: "auto", display: "flex", gap: "20px", flexWrap: "wrap" }}>
									<button onClick={deleteRequest} style={{ borderColor: "#000" }}>
										<img src={TrashIcon} alt="basura" />
									</button>
									<button
										onClick={() => Archived(data.id)}
										className="btn-archived-request"
										title={data.isArchived ? "Desarchivar" : "Archivar"}
									>
										<img src={data.isArchived ? Unarchived : ArchivedIcon} alt="basura" />
									</button>

									<button onClick={deniedRequest}>Negar</button>
									<button onClick={acceptRequest} className="btn-accept-request">
										Aprobar
									</button>
								</div>
							) : (
								<div
									className="spinner-border text-danger"
									style={{ marginLeft: "auto" }}
									role="status"
								>
									<span className="sr-only">Loading...</span>
								</div>
							)}
						</div>
					</>
				) : (
					<></>
				)}
				{data.checked === true && (
					<>
						<div className="buttons-container-request-card">
							{data.image !== "" && <button onClick={seeReceipt}>Ver comprobante</button>}
							{data.checked && data.verified && (
								<button onClick={() => setReceiptModal(true)}>Generar recibo</button>
							)}
						</div>
						<div className="denied-reason-message-container">
							{data.message !== null && data.message !== undefined && data.message.length > 0 && (
								<p>
									<b>Motivo de rechazo: </b>
									{data.message}
								</p>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default PaymentRequestCard;
