import { deletearFamiliasR, excelWords, getFamilies, getWordsSubFamily } from "@api/Vocabulary";
import { Receipt } from "@components/Receipt/Receipt";
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	orderBy,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { db, functions } from "initFirebase";
import { useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import "./AddWordsVocabulary.scss";
import { getUserById, getWordsByLanguage } from "@api/develop";
import { useAuth } from "@hooks/useAuth";
import { httpsCallable } from "firebase/functions";
import { TGeneration } from "interfaces/Generations";

interface AddWordsVocabularyProps {
	user: any;
}

export function AddWordsVocabulary(props: AddWordsVocabularyProps) {
	const [records, setRecords] = useState<Array<any>>(new Array());
	const [repeated, setRepeated] = useState<Array<any>>(new Array());
	const user = useAuth().user;

	function uploadProductCSV(event: any) {
		const reader = new FileReader();
		const fileContent = event.target;
		reader.readAsText(fileContent.files[0]);

		reader.onload = () => {
			let csvData: any = reader.result;
			let csvRecordsArray = csvData.split(/\r\n|\n/);

			let rec = getDataRecordsArrayFromCSVFile(csvRecordsArray);
			setRecords(rec);
		};

		reader.onerror = function () {};
	}

	function getDataRecordsArrayFromCSVFile(csvRecordsArray: any) {
		let csvArr: any = [];

		for (let i = 0; i < csvRecordsArray.length; i++) {
			let currentRecord = csvRecordsArray[i].split(",");
			let csvRecord: Array<string> = new Array();
			for (let i = 0; i < currentRecord.length; i++) {
				csvRecord.push(currentRecord[i].trim());
			}
			if (csvRecord[0] != "") {
				csvArr.push(csvRecord);
			}
		}
		return csvArr;
	}

	function newArrayFormat() {
		let temp = new Array(...JSON.parse(JSON.stringify(records)));

		temp.map((word, index) => {
			if (index > 0) {
				word[1] = word[1].split("-");
				word[2] = word[2].split("-");
				word[3] = word[3].split("-");
				word[4] = word[4].split("-");
				word[5] = parseInt(word[5]);
				word[6] = word[5] === "sí" ? true : false;
				word[7] = parseInt(word[7]);
				word[8] = parseInt(word[8]);
				word[9] = parseInt(word[9]);
				word[10] = word[10] === "null" ? null : word[10];
			}
		});

		temp.splice(0, 1);

		excelWords(temp).then((res) => {
			// console.log(res);
		});
	}

	const searchForCapitalEmail = async () => {
		const validateUsers = httpsCallable(functions, "validateUsers");

		try {
			const res = await validateUsers();
			const usersWithoutAuth = (res.data as any).usersWithoutAuth;
			const usersWithAuth = (res.data as any).usersWithAuth;

			// console.log("==== START DELETE USERS WITHOUT AUTH ====");
			// for (const user of usersWithoutAuth) {
			// 	console.log("userEmail:", user.email);
			// 	try {
			// 		const userRef = doc(db, "users", user.id);
			// 		await deleteDoc(userRef);
			// 		console.log(`Deleted user ${user.email}`);
			// 	} catch (error) {
			// 		console.error(`Error deleting user ${user.email}:`, error);
			// 	}
			// }
			// console.log("==== END DELETE USERS WITHOUT AUTH ====");

			// console.log("==== START UPDATE USER EMAIL USERS WITH AUTH ====");
			// for (const user of usersWithAuth) {
			// 	try {
			// 		console.log("userEmail:", user.email);
			// 		const userRef = doc(db, "users", user.id);
			// 		await updateDoc(userRef, {
			// 			email: user.email.toLowerCase(),
			// 		});
			// 		console.log(`Updated email for user ${user.email}`);
			// 	} catch (error) {
			// 		console.error(`Error updating email for user ${user.id}: ${user.email}:`, error);
			// 	}
			// }
			// console.log("==== END UPDATE USER EMAIL USERS WITH AUTH ====");
		} catch (error) {
			console.error(error);
		}
	};

	const changeAllVideosUrl = async () => {
		const queryDocs = query(collection(db, "generations"));
		const snapDocs = await getDocs(queryDocs);

		if (snapDocs.size > 0) {
			for (const genDoc of snapDocs.docs) {
				const genData = { id: genDoc.id, ...genDoc.data() } as TGeneration;
				console.log(`==== Generation ${genData.name} ====`);

				const queryMaterials = query(
					collection(db, "generations", genData.id, "material"),
					orderBy("num", "asc")
				);
				const snapMaterial = await getDocs(queryMaterials);

				if (snapMaterial.size > 0) {
					for (const materialDoc of snapMaterial.docs) {
						const materialData: any = { ...materialDoc.data(), id: materialDoc.id };
						console.log(`Material: ${materialData.num}`);

						console.log("=== OLD URLS ===");
						console.log("videoUrl", materialData.videoUrl);
						console.log("videoUrl12", materialData.videoUrl12);
						console.log("videoUrl13", materialData.videoUrl13);

						const oldUrl = "videoacademia.academiadelenguasinternacionales.info";
						const newUrl = "academia.inowu.dev";
						let videoUrl;
						let videoUrl12;
						let videoUrl13;

						if (materialData.videoUrl && materialData.videoUrl.indexOf(oldUrl) !== -1) {
							videoUrl = materialData.videoUrl.replace(oldUrl, newUrl);
						}
						if (materialData.videoUrl12 && materialData.videoUrl12.indexOf(oldUrl) !== -1) {
							videoUrl12 = materialData.videoUrl12.replace(oldUrl, newUrl);
						}
						if (materialData.videoUrl13 && materialData.videoUrl13.indexOf(oldUrl) !== -1) {
							videoUrl13 = materialData.videoUrl13.replace(oldUrl, newUrl);
						}

						console.log("=== NEW URLS ===");
						console.log("videoUrl", videoUrl ? videoUrl : "no change needed");
						console.log("videoUrl12", videoUrl12 ? videoUrl12 : "no change needed");
						console.log("videoUrl13", videoUrl13 ? videoUrl13 : "no change needed");

						const urls = {
							...(videoUrl && { videoUrl: videoUrl }),
							...(videoUrl12 && { videoUrl12: videoUrl12 }),
							...(videoUrl13 && { videoUrl13: videoUrl13 }),
						};

						if (Object.keys(urls).length > 0) {
							console.log("update Doc");
							console.log("update Object", urls);
							const docRef = doc(db, "generations", genData.id, "material", materialData.id);
							await updateDoc(docRef, urls);
						} else {
							console.log("no update needed");
						}
					}
				}
			}
		}
	};

	function getFamilis() {
		let uniqueIds = new Array();
		let repeatedIds = new Array();

		getFamilies().then((res) => {
			for (let i = 0; i < res.length; i++) {
				if (
					!uniqueIds.some((e) => e.word_id === res[i].word_id && e.family_id === res[i].family_id)
				) {
					uniqueIds.push(res[i]);
				} else {
					repeatedIds.push(res[i]);
				}
			}
			setRepeated(repeatedIds);
		});
	}

	const convertWords = async () => {
		await getWordsByLanguage(8)
			.then((res) => {
				// console.log(res);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	function deletear() {
		for (let i = 0; i < repeated.length; i++) {
			deletearFamiliasR(repeated[i].id).then((res) => {
				// console.log(res);
			});
		}
	}

	const getWordsSubFamilys = async () => {
		await getWordsSubFamily().then((res) => {
			// console.log(res);
		});
	};

	const copyQuiz = async () => {
		const docRef = doc(db, "quiz", "qf48R2jEw7QDs2iTjKdk");
		const snap = await getDoc(docRef);

		if (snap.exists()) {
			const data = {
				...snap.data(),
				userId: "1QXuvMqcYzdb0YZawOkVsUuiORU2",
			};

			const copyRef = await addDoc(collection(db, "quiz"), data);
		}
	};

	const deleteDuplicatedValues = async () => {
		// get all users
		await getUserById(user.vocabularyUserId).then((res) => {
			console.log(res);
		});
	};

	const deleteNotification = async () => {
		// const q = query(
		// 	collection(db, "notifications"),
		// 	where("text", "==", "<p>Se ha creado una nueva solicitud de facturación</p>"),
		// 	where("date", ">", new Date("05/01/2023")),
		// 	orderBy("date", "desc")
		// );
		// const snap = await getDocs(q);
		// // snap.docs.map((notification) => {
		// // 	console.log(new Date(notification.data().date.seconds * 1000));
		// // });
		// await Promise.all(
		// 	snap.docs.map(async (notification, i) => {
		// 		await deleteDoc(doc(db, "notifications", notification.id)).then(() => {
		// 			console.log(i);
		// 		});
		// 	})
		// );
	};

	// const getReceipt = async () => {
	// 	const receiptElement = document.getElementById("receipt");
	// 	if (receiptElement !== null) {
	// 		const canvas = await html2canvas(receiptElement);
	// 		const image = canvas.toDataURL("image/png", 1.0);

	// 		let doc = new jsPDF("l", "mm", [297, 210]);
	// 		doc.addImage(image, "PNG", 0, 0, 297, 210);
	//           doc.save();
	// 	}
	// };
	const pxTomm = (px: any) => {
		return Math.floor(px / 2);
	};

	return (
		<div className="add-words-vocabulary-main-container">
			{/* <div>
				<h2>Add words for vocabulary with excel</h2>
			</div>
			<div className="d-flex flex-column mb-4">
				<label htmlFor="excel">Excel file</label>
				<input onChange={(e) => uploadProductCSV(e)} type="file" name="excel" id="excel" />
			</div>
			<div>
				<button onClick={newArrayFormat}>Action</button>
			</div> */}
			{/* <div>
				<div>
					<h2>Users with capital letter on email</h2>
				</div>
				<div>
					<button onClick={searchForCapitalEmail}>Action</button>
				</div>
			</div> */}
			<div>
				<div>
					<h2>change all material videourl</h2>
				</div>
				<div>
					<button onClick={changeAllVideosUrl}>Action</button>
				</div>
			</div>
			{/* <div>
				<button onClick={deleteNotification}>Action</button>
			</div> */}
			{/* <div>
				<button onClick={deleteDuplicatedValues}>delete duplciates</button>
			</div> */}

			{/* <div className="convertion-new-tables-container mt-4">
				<h2>Button to convert the words into the new tables</h2>
				<button className="action-btn linear-bg" onClick={convertWords}>
					Convert
				</button>
			</div> */}
			{/* <div>
				<button onClick={deletear} disabled>
					borrar
				</button>
			</div> */}
			{/* <div>
				<h3>Ejecutar funcion</h3>
				<button onClick={copyQuiz} disabled>
					Ejecutar
				</button>
			</div> */}
			{/* <div className="mt-4">
                <h3>words seminary</h3>
                <button onClick={getWordsSubFamilys}>Agarrar words_sub_family</button>
            </div> */}
			{/* <div>
				<h3>videos</h3>
				<video>
					<source src="https://videoacademia.inowu.dev/media/Viernes 18:00- 20:00 2021/483efb3d-9981-408c-8e77-4cc2492dfdc8/master.m3u8" />
				</video>
			</div> */}
			{/* <button onClick={getReceipt}>Generar recibo</button> */}
			{/* <Receipt /> */}
		</div>
	);
}
