import { areWordsDominated } from "@api/Vocabulary";
import {
	getDocs,
	query,
	where,
	collection,
	orderBy,
	doc,
	getDoc,
	deleteDoc,
	updateDoc,
	limit,
	startAfter,
	endBefore,
	startAt,
} from "firebase/firestore";
import { returnFlagByLanguage, romanize } from "functions/Functions";
import { db } from "initFirebase";
import { Quizzes } from "interfaces/Interfaces";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { defaultQuiz, DefaultQuizzes } from "./components/DefaultQuizzes/DefaultQuizzes";
import DetailSeminaryCard from "./components/DetailSeminaryCard/DetailSeminaryCard";
import { getLangArray } from "./components/DetailSeminaryCard/functions/DetailSeminaryCardFunctions";
import HistorySeminaryVocabulary from "./components/HistorySeminaryVocabulary/HistorySeminaryVocabulary";
import PersonalizedTest from "../../components/Vocabulary/PersonalizedTest/PersonalizedTest";
import Pronunciation from "./components/Pronunciation/Pronunciation";
import { getUniqueTotalWordsArray } from "./components/Test/functions/TestFunctions";
import Test from "./components/Test/Test";
import { getSegmentsArray, repeatQuiz } from "./functions/VocabularyFunctions";
import "./VocabularySeminaryContainer.scss";
import firebase from "firebase/compat/app";
import PercentageBar from "@components/PercentageBar/PercentageBar";
import { SuperPersonalized } from "@components/Vocabulary/SuperPersonalized/SuperPersonalized";
import { languagesEnglish } from "functions/DefaultValues";
import { Progress, Tooltip } from "antd";
import {
	ClockIcon,
	GoldRocketIcon,
	ListIconVocabulary,
	Logo,
	PronunciatinoIcon,
	RocketIcon,
	SearchIcon,
	ToolsIcon,
} from "assets/Imports";
import { StreakFlame } from "./components/Test/components/StreakModal/components/StreakFlame";
import { useVocabularySeminaryContext } from "contexts/VocabularySeminaryContext";
import { useAuth } from "@hooks/useAuth";
import { useStreakContext } from "contexts/VocabularyStreakContext";
import Pagination from "@components/Pagination/Pagination";

function VocabularySeminaryContainer({ user }) {
	const history = useHistory();
	const [aboutVocabularyShow, setAboutVocabularyShow] = useState<boolean>(false);
	const { num } = useParams<any>();
	const {
		loading,
		setLoading,
		words,
		categories,
		subCategories,
		languagesProgress,
		view,
		allWordsArray,
		getSeminary,
		manageError,
		setView,
		unitTime,
	} = useVocabularySeminaryContext();
	const [testId, setTestId] = useState<string>("");
	const [quizzes, setQuizzes] = useState<Array<Quizzes>>(new Array());
	const [defaultQuizzes, setDefaultQuizzes] = useState<Array<defaultQuiz>>(new Array());
	const [totalDefaults, setTotalDefaults] = useState<number>(0);
	const [totalDefaultsCompleted, settotalDefaultsCompleted] = useState<number>(0);
	const [totalWordsDefaults, setTotalWordsDefaults] = useState<number>(0);
	const [totalDominatedWordsDefaults, setTotalDominatedWordsDefault] = useState<number>(0);
	const [modalShow, setModalShow] = useState<boolean>(false);
	const location: any = useLocation();
	const [loadingDefault, setLoadingDefault] = useState<boolean>(true);
	const [colorDictionarySub, setColorDictionarySub] = useState<any>({});
	const [key, setKey] = useState<string>("");
	const [countRefresh, setCountRefresh] = useState<number>(0);
	const { setQuizOn } = useAuth();
	const [numberLangs, setNumberLangs] = useState<number>(0);
	const [lang, setLang] = useState<string>("");
	const [viewDetail, setViewDetail] = useState<number>(0);

	const [isShown, setIsShown] = useState(false);
	const [searchWord, setSearchWord] = useState("");
	const [filterWord, setFilterWord] = useState<Array<any>>([]);

	const [searchLang, setSearchLang] = useState("all");
	const [loadingLang, setLoadingLang] = useState<boolean>(false);

	const [pageSize] = useState(5);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageCursors, setPageCursors] = useState<any[]>([]);
	const [isLastPage, setIsLastPage] = useState(true);

	const handleClick = () => {
		setIsShown((current) => !current);
		if (isShown === false) {
			setSearchWord("");
		}
	};

	const { isLoading, vocabularyStreak, studiedDays, currentStreak } = useStreakContext();

	const handleChange = (e) => {
		let searchText = e.target.value;
		setSearchWord(searchText);

		const filtertemp = allWordsArray?.filter((word) =>
			word.native_word.toLowerCase().includes(searchText.toLowerCase())
		);

		setFilterWord(filtertemp ? filtertemp : []);
	};

	const handleLanguage = (e) => {
		let searchLanguage = e.target.value;
		setSearchLang(searchLanguage);
		setLoadingLang(true);
	};
	const getKey = async () => {
		let tryKey = await firebase.functions().httpsCallable("getAudio");
		tryKey().then((res) => {
			setKey(res.data);
		});
	};

	const subCategoriesRef = doc(db, "dictionaries", "subcategories");

	const getSubCategoriesDictionary = async () => {
		const snap = await getDoc(subCategoriesRef);

		if (snap.exists()) {
			let data = {};
			snap.data().subcategories.map((sub) => {
				data = { ...data, [sub.subcategory]: sub.color };
			});

			setColorDictionarySub(data);
		}
	};

	const numberOfLanguagesInSeminary = async () => {
		let count = 0;

		await Promise.all(
			languagesEnglish.map((lang) => {
				if (words![lang].length > 0) {
					count++;
				}
			})
		);

		setNumberLangs(count);
	};

	const manageLangModalResponsive = (language) => {
		setLang(language);
		setViewDetail(1);
		setAboutVocabularyShow(!aboutVocabularyShow);
	};

	const getDefaultsNumbers = async () => {
		if (parseInt(num) > 0) {
			const q = query(
				collection(db, "defaultQuizzes"),
				where("seminary", "==", parseInt(num)),
				orderBy("order", "asc")
			);
			const snap = await getDocs(q);

			const quizzesTemp = new Array();
			let completed = 0;
			let totalWords = 0;
			let totalDominatedWords = 0;

			if (snap.size > 0) {
				await Promise.all(
					snap.docs.map((quiz) => {
						const data = {
							id: quiz.id,
							answerType: quiz.data().answerType,
							language: quiz.data().language,
							name: quiz.data().name,
							quizWords: quiz.data().quizWords,
							seminary: quiz.data().seminary,
							version: quiz.data().version,
						};

						quizzesTemp.push(data);
					})
				);
				let tempArray2 = new Array();

				try {
					for (let index = 0; index < quizzesTemp.length; index++) {
						const queryRef = query(
							collection(db, "quiz"),
							where("seminaryNum", "==", parseInt(quizzesTemp[index].seminary)),
							where("defaultId", "==", quizzesTemp[index].id),
							where("finishedDefault", "==", true),
							where("userId", "==", user.id)
						);

						const querySnapshot = await getDocs(queryRef);

						let tempArray = await getUniqueTotalWordsArray(quizzesTemp[index].quizWords);

						await areWordsDominated(user.vocabularyUserId, tempArray).then((res) => {
							const data = {
								...quizzesTemp[index],
								completed:
									querySnapshot.size > 0 &&
									querySnapshot.docs.some(
										(e) => e.data().defaultVersion === quizzesTemp[index].version
									)
										? true
										: false,
								dominated: res.data.words.filter((e) => e.dominated === 3).length,
								totalWords: res.data.words.length,
								...(querySnapshot.size > 0 &&
									!querySnapshot.docs.some(
										(e) => e.data().defaultVersion === quizzesTemp[index].version
									) && {
										differentVersionCompleted: true,
									}),
								quizWords: getSegmentsArray(res.data.words),
							};

							totalWords += data.totalWords;
							totalDominatedWords += data.dominated;
							completed += data.completed ? 1 : 0;
							tempArray2.push(data);
						});
					}
				} catch (error) {
					console.error(error);
					manageError(error);
				}

				setTotalWordsDefaults(totalWords);
				setTotalDominatedWordsDefault(totalDominatedWords);
				setDefaultQuizzes(tempArray2);
				setLoading(false);
				setTotalDefaults(snap.size);
				settotalDefaultsCompleted(completed);
				setLoadingDefault(false);
			} else if (parseInt(num) === 0) {
				setLoading(false);
			}
		} else {
			setLoading(false);
		}
	};

	const deleteQuiz = async (quizId) => {
		if (
			window.confirm(
				`¿${user.gender === "mujer" ? "Segura" : "Seguro"} que quieres eliminar esta lista?`
			)
		) {
			await deleteDoc(doc(db, "quiz", quizId));
			setLoading(true);
			setQuizzes(new Array());
			setSearchLang("all");
			getSeminary();
			getDefaultsNumbers();
			getTests();
			setQuizOn(false);
			getSubCategoriesDictionary();
		}
	};

	const getTests = async (direction = "next") => {
		setLoadingLang(true);
		let q;
		let querySnapshot;
		let baseQuery;

		if (searchLang !== "all") {
			baseQuery = query(
				collection(db, "quiz"),
				where("userId", "==", user.id),
				where("seminaryNum", "==", parseInt(num)),
				where("language", "==", searchLang)
			);
		} else {
			baseQuery = query(
				collection(db, "quiz"),
				where("userId", "==", user.id),
				where("seminaryNum", "==", parseInt(num))
			);
		}

		try {
			if (direction === "next") {
				// Moving to the next page
				if (pageCursors[currentPage]) {
					// Start after the last document of the current page
					q = query(
						baseQuery,
						orderBy("createdAt", "desc"),
						startAfter(pageCursors[currentPage]),
						limit(pageSize)
					);
				} else {
					// First page, no cursor needed
					q = query(baseQuery, orderBy("createdAt", "desc"), limit(pageSize));
				}

				querySnapshot = await getDocs(q);

				const data = querySnapshot.docs.map(
					(doc) => ({ id: doc.id, ...(doc.data() as Partial<Quizzes>) } as Quizzes)
				);

				if (data.length > 0) {
					// Update the cursor for the current page
					const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
					setPageCursors((prev) => {
						const newCursors = [...prev];
						newCursors[currentPage + 1] = lastDoc;
						return newCursors;
					});
				}

				setQuizzes(data);
				setCurrentPage(currentPage + 1);

				if (data.length < pageSize) {
					setIsLastPage(true);
				} else {
					setIsLastPage(false);
				}
			} else if (direction === "prev") {
				// Moving to the previous page
				if (currentPage > 1) {
					// Reverse the order and use startAt with the first document of the current page
					q = query(
						baseQuery,
						orderBy("createdAt", "asc"),
						startAt(pageCursors[currentPage - 1]),
						limit(pageSize)
					);

					querySnapshot = await getDocs(q);

					let data = querySnapshot.docs.map(
						(doc) => ({ id: doc.id, ...(doc.data() as Partial<Quizzes>) } as Quizzes)
					);

					// Reverse the data back to descending order
					data = data.reverse();

					setQuizzes(data);
					setCurrentPage((prev) => prev - 1);
					setIsLastPage(false);
				} else {
					// Back to the first page
					q = query(baseQuery, orderBy("createdAt", "desc"), limit(pageSize));

					querySnapshot = await getDocs(q);

					const data = querySnapshot.docs.map(
						(doc) => ({ id: doc.id, ...(doc.data() as Partial<Quizzes>) } as Quizzes)
					);

					setQuizzes(data);
					setCurrentPage(1);
					setIsLastPage(false);
				}
			}
		} catch (error) {
			console.error("error getting tests", error);
		}

		setLoadingLang(false);
	};

	const continueTest = (id: string) => {
		setTestId(id);
		setView(3);
	};

	const repeat = (quiz: Quizzes) => {
		const langArray = getLangArray(quiz.language, JSON.parse(JSON.stringify(words!)));
		let uniqueWordsArray = getUniqueTotalWordsArray(quiz.quizWords);
		let newQuizWords = new Array();

		if (langArray !== undefined) {
			langArray.map((word) => {
				if (uniqueWordsArray.findIndex((e) => e.id === word.id) !== -1) {
					word.dominated = null;
					newQuizWords.push(word);
				}
			});

			getSeminary();
			const segments = getSegmentsArray(newQuizWords);

			repeatQuiz(quiz, segments).then((quizId) => {
				setTestId(quizId);
				setView(3);
			});
		}
	};

	const repeatIncorrects = (quiz: Quizzes) => {
		const langArray = getLangArray(quiz.language, JSON.parse(JSON.stringify(words!)));
		let uniqueWordsArray = quiz.incorrectWords;
		let newQuizWords = new Array();

		if (langArray !== undefined) {
			langArray.map((word) => {
				if (uniqueWordsArray.findIndex((e) => e.id === word.id) !== -1) {
					newQuizWords.push(word);
				}
			});

			const segments = getSegmentsArray(newQuizWords);

			repeatQuiz(quiz, segments, true).then((quizId) => {
				setTestId(quizId);
				setView(3);
			});
		}
	};

	const lastStudy = async () => {
		const userDoc = doc(db, "users", user.id);
		await updateDoc(userDoc, {
			lastQuizStudy: new Date(),
		});
	};

	useEffect(() => {
		try {
			if (view === 1) {
				setLoading(true);
				setQuizzes(new Array());
				setSearchLang("all");
				setCurrentPage(0);
				setPageCursors([]);
				setIsLastPage(false);
				setLoadingLang(true);
				getSeminary();
				getDefaultsNumbers();
				setQuizOn(false);
				getSubCategoriesDictionary();
				if (location.state !== undefined) {
					if (!location.state.finished && location.state.view === undefined) {
						continueTest(location.state.lastQuiz);
						setQuizOn(true);
						location.state = undefined;
					}
				}
			} else if (view === 3) {
				lastStudy();
				setQuizOn(true);
			} else {
				setQuizOn(false);
			}
		} catch (error) {
			console.error(error);
		}

		// getCategoriesBySeminary();
	}, [view, countRefresh]);

	useEffect(() => {
		try {
			if (location.state !== undefined) {
				if (location.state.view !== undefined && location.state.view === 4) {
					setView(4);
					setLoading(true);
					setQuizzes(new Array());
					setSearchLang("all");
					getSeminary();
					getTests();
					setQuizOn(false);
					getSubCategoriesDictionary();
					location.state = undefined;
				}
			}
		} catch (error) {
			console.error(error);
		}
	}, [loadingDefault]);

	useEffect(() => {
		if (currentPage !== 0 || pageCursors.length !== 0) {
			setPageCursors([]);
			setCurrentPage(0);
			setIsLastPage(false);
		}
	}, [searchLang]);

	// First useEffect Hook
	useEffect(() => {
		if (view === 1 && currentPage === 0 && pageCursors.length === 0) {
			getTests();
		}
	}, [view, currentPage, pageCursors]);

	useEffect(() => {
		if (categories.length > 0) {
			setLoading(false);
		}
	}, [categories, subCategories]);

	useEffect(() => {
		if (words !== undefined) {
			numberOfLanguagesInSeminary();
		}
	}, [words]);

	useEffect(() => {
		if (!aboutVocabularyShow) {
			setLang("");
			setViewDetail(0);
		}
	}, [aboutVocabularyShow]);

	const langComparator = [...quizzes].sort((a, b) => (a.language > b.language ? 1 : -1));

	if (loading)
		return (
			<div
				style={{
					height: "60vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);

	return (
		<>
			{view === 1 && (
				<div className="vocabulary-seminary-main-container">
					<div className="d-flex justify-content-start w-100">
						<button
							className="goback-btn m-0"
							onClick={() => history.push("/micuenta/vocabulario")}
						>
							Atrás
						</button>
					</div>
					{/* <StreakModal show={streakModal} onHide={() => setStreakModal(false)} vocabularyStreak={studiedDays} /> */}
					<Pronunciation show={modalShow} onHide={() => setModalShow(false)} />
					<div className="header-container">
						<div className="title-container">
							<h2
								className="d-flex align-items-center m-0"
								style={{
									gap: 10,
									fontSize: "2.5rem",
									lineHeight: "4rem",
									color: "#ca5050",
								}}
							>
								<img src={ListIconVocabulary} alt="icono de lista" />
								Seminario{" "}
								<span style={{ fontFamily: "initial", lineHeight: "4rem" }}>
									{romanize(parseInt(num))}
								</span>
								<span style={{ fontSize: "0.875rem", color: "#ca5050" }}>BETA</span>
							</h2>
							<div className="time-container">
								<StreakFlame
									user={user}
									currentStreak={currentStreak}
									vocabularyStreak={vocabularyStreak}
									studiedDays={studiedDays}
									isLoading={isLoading}
								/>
								{/* <div className="streak-container" onClick={() => setStreakModal(true)}>
									<img className="streak-icon" src={StreakFlameIcon} alt="icono de fuego" />
									<h2>{vocabularyStreak.length}</h2>
								</div> */}
								<h3>
									<img src={ClockIcon} alt="icono de relog" />
									{unitTime} minutos
								</h3>
								<p>Tiempo transcurrido en la unidad</p>
							</div>
						</div>
					</div>
					<div
						className="circle-progress-flags-container responsive-circle-progress-main mb-4"
						style={numberLangs > 6 || numberLangs === 4 ? { gap: 5 } : { gap: 10 }}
					>
						{languagesEnglish.map((lang, index) => {
							if (languagesProgress) {
								const percentage = languagesProgress[lang]?.dominated;
								const newPercentage = languagesProgress[lang]?.seen;
								return (
									words[lang]?.length > 0 && (
										<div
											className="circle-container"
											key={"circle-languages-progress-" + index}
											onClick={() => manageLangModalResponsive(lang)}
										>
											<h2 className="m-0">{Math.floor(percentage)}%</h2>
											<div className="progress-container">
												<Tooltip>
													<Progress
														percent={newPercentage}
														success={{
															percent: percentage,
															strokeColor: percentage < 100 ? "#ca5050" : "#efc900",
														}}
														type="circle"
														width={70}
														strokeColor="#959595"
														strokeWidth={10}
														trailColor="#e7e7e7"
														showInfo={false}
													/>
												</Tooltip>
												{returnFlagByLanguage(lang)}
											</div>
										</div>
									)
								);
							}
						})}
					</div>
					<div className="pronunciation-btn-container">
						{isShown ? (
							<input
								value={searchWord}
								type="text"
								placeholder="Buscar término"
								onChange={handleChange}
							/>
						) : (
							<button onClick={() => setModalShow(true)}>
								<img src={PronunciatinoIcon} alt="icono de persona hablando" /> Pronunciación
							</button>
						)}
						<div className="search-container" style={{ cursor: "pointer" }} onClick={handleClick}>
							<img src={SearchIcon} alt="" />
						</div>
					</div>
					<div className="vocabulary-content-main-container">
						<div
							className={
								"back-modal-detail-seminary-card" +
								(aboutVocabularyShow ? " back-modal-detail-seminary-card-open" : "")
							}
							onClick={() => setAboutVocabularyShow(!aboutVocabularyShow)}
						></div>
						<div
							className={
								"detail-seminary-card-container" + (aboutVocabularyShow ? " open-card" : "")
							}
						>
							<DetailSeminaryCard
								view={viewDetail}
								setView={setViewDetail}
								setLang={setLang}
								lang={lang}
								gKey={key}
								languagesProgress={languagesProgress}
								categories={categories}
								num={num}
								setCountRefresh={setCountRefresh}
								countRefresh={countRefresh}
								filterWord={filterWord}
								searchWord={searchWord}
								isShown={isShown}
								handleChange={handleChange}
								handleClick={handleClick}
							/>
						</div>
						<div className="vocabulary-tests-container">
							<div
								className={
									"tests-buttons-container " +
									(parseInt(num) === 0 ? "tests-buttons-seminary-zero-container" : "")
								}
							>
								{parseInt(num) > 0 && (
									<div
										className="test-card"
										onClick={!loadingDefault ? () => setView(4) : () => setView(1)}
									>
										{totalDefaults === totalDefaultsCompleted &&
										totalWordsDefaults === totalDominatedWordsDefaults &&
										totalDefaults > 0 ? (
											<img
												className="gold-rocket"
												src={GoldRocketIcon}
												alt="imagen de cohete dorado"
											/>
										) : (
											<img src={RocketIcon} alt="imagen de cohete" />
										)}
										<div className="personalized-div-test-card-1">
											<h3 className="text-center">
												Ronda de estudio <span>predeterminada</span>
											</h3>
										</div>
										<p className="default-quizzes-card-text">
											{!loadingDefault
												? `${totalDefaultsCompleted}/${totalDefaults} rondas terminadas`
												: "...Cargando"}
										</p>
										{!loadingDefault && (
											<PercentageBar percentage={(totalDefaultsCompleted / totalDefaults) * 100} />
										)}
									</div>
								)}
								<div className="test-card" onClick={() => setView(2)}>
									<img src={ToolsIcon} alt="imagen de herramientas" />
									<div className="text-center personalized-div-test-card">
										<h3>Crea una</h3> <span>ronda de estudio</span> <h3>personalizada</h3>
									</div>
								</div>
								{parseInt(num) === 0 && user.userType === 2 && (
									<div className="test-card" onClick={() => setView(5)}>
										<img src={Logo} alt="imagen de herramientas" />
										<div className="text-center personalized-div-test-card">
											<h3>Crea una</h3> <span>lista de estudio</span>
										</div>
									</div>
								)}
								<div className="about-vocabulary-btn-container">
									<button onClick={() => setAboutVocabularyShow(!aboutVocabularyShow)}>
										Sobre el vocabulario
									</button>
								</div>
							</div>
							<div className="history-vocabulary-seminary-card">
								<h2 className="title">Historial de estudio</h2>
								<div className="quizzes-vocabulary-lang">
									{parseInt(num) === 0 && (
										<>
											<select
												className="quizzes-vocabulary-lang-selector"
												value={searchLang}
												onChange={handleLanguage}
											>
												<option value="all">Todos</option>
												<option value="chinese">Chino</option>
												<option value="english">Ingles</option>
												<option value="italian">Italiano</option>
												<option value="french">Frances</option>
												<option value="russian">Ruso</option>
											</select>
										</>
									)}
								</div>
								<Pagination
									currentPage={currentPage}
									isLastPage={isLastPage}
									nextPage={() => getTests("next")}
									prevPage={() => getTests("prev")}
									isLoading={loadingLang}
								/>
								{loadingLang === false ? (
									<>
										{quizzes.length > 0 ? (
											<>
												{quizzes.map((quiz, index) => {
													return (
														<HistorySeminaryVocabulary
															key={"quiz-history-" + quiz.id}
															repeatIncorrects={repeatIncorrects}
															repeat={repeat}
															continueTest={continueTest}
															quiz={quiz}
															location={location}
															loading={loading}
															deleteQuiz={deleteQuiz}
															user={user}
															setView={setView}
															loadingDefault={loadingDefault}
															defaultQuizzes={defaultQuizzes}
															setTestId={setTestId}
															gKey={key}
															countRefresh={countRefresh}
															setCountRefresh={setCountRefresh}
														/>
													);
												})}
											</>
										) : (
											<>
												<h2 className="no-quizzes">
													Aún no existen rondas de estudio en este seminario
												</h2>
												<h2 className="mt-4 text-center">¡Crea la primera!</h2>
											</>
										)}
									</>
								) : (
									<>
										<div
											style={{
												height: "60vh",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												flexDirection: "column",
											}}
										>
											<div className="spinner-border text-danger" role="status">
												<span className="sr-only">Loading...</span>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
			{view === 2 && (
				<PersonalizedTest
					admin={false}
					setTestId={setTestId}
					user={user}
					num={num}
					words={words}
					allWordsArray={allWordsArray}
					setView={setView}
					categories={categories}
					subCategories={subCategories}
					languagesProgress={languagesProgress}
				/>
			)}
			{view === 3 && (
				<Test
					allWordsUser={words}
					dictionarySubColor={colorDictionarySub}
					testId={testId}
					repeatIncorrects={repeatIncorrects}
					setView={setView}
					user={user}
					num={num}
					repeat={repeat}
				/>
			)}
			{view === 4 && (
				<DefaultQuizzes
					defaultQuizzes={defaultQuizzes}
					setTestId={setTestId}
					user={user}
					num={parseInt(num)}
					setView={setView}
					totalDefaultsCompleted={totalDefaultsCompleted}
					totalDefaults={totalDefaults}
				/>
			)}
			{view === 5 && <SuperPersonalized user={user} setView={setView} gKey={key} />}
		</>
	);
}

export default VocabularySeminaryContainer;
