import {
	DocumentData,
	DocumentReference,
	DocumentSnapshot,
	addDoc,
	collection,
	doc,
	getDoc,
	updateDoc,
} from "firebase/firestore";
import { db } from "../initFirebase";
import { useEffect, useState } from "react";
// This custom hook will be used to store all the functions related to sending notifications to users.
export default function useStreak() {
	const invoiceRejectionNotif = async (userId: string) => {
		await addDoc(collection(db, "notifications"), {
			name: "Academia de Lenguas Internacionales",
			text: `<p>Tu solicitud de factura fue rechazada, revisa tu correo para conocer el motivo.</p>`,
			date: new Date(),
			userId: userId,
			userType: 2,
			seen: false,
			type: "invoice-reject",
			title: "Actualización sobre solicitud de factura.",
		})
			.then((res) => {
				console.log(res);
				return "Success!";
			})
			.catch((error) => {
				console.error(error);
				return null;
			});
	};

	const invoiceAcceptedNotif = async (userId: string) => {
		await addDoc(collection(db, "notifications"), {
			name: "Academia de Lenguas Internacionales",
			text: `<p>Tu solicitud de factura fue aceptada, puedes revisar tu correo para visualizarla.</p>`,
			date: new Date(),
			userId: userId,
			userType: 2,
			seen: false,
			type: "invoice-accepted",
			title: "Actualización sobre solicitud de factura.",
		})
			.then((res) => {
				console.log(res);
				return "Success!";
			})
			.catch((error) => {
				console.error(error);
				return null;
			});
	};

	const invoiceRequestNotif = async (name: string, invoiceId?: string, profileColor?: string) => {
		await addDoc(collection(db, "notifications"), {
			name: name,
			text: `<p>Solicitud de facturación.</p>`,
			date: new Date(),
			userId: "admin",
			userType: 1,
			seen: false,
			type: "invoice-request",
			title: "Actualización sobre solicitud de factura.",
			invoiceFbId: invoiceId,
			sectionId: "NXDBm1nwRB1NY4QgRmXv",
			profileColor: profileColor,
		})
			.then((res) => {
				return "Success!";
			})
			.catch((error) => {
				console.error(error);
				return null;
			});
	};

	const invoiceAutoGeneratedNotif = async (name: string, invoiceId?: string, profileColor?: string) => {
		await addDoc(collection(db, "notifications"), {
			name: name,
			text: `<p>Se ha emitido una nueva factura.</p>`,
			date: new Date(),
			userId: "admin",
			userType: 1,
			seen: false,
			invoiceFbId: invoiceId,
			type: "invoice-autoGenerated",
			title: "Actualización sobre solicitud de factura.",
			sectionId: "NXDBm1nwRB1NY4QgRmXv",
			profileColor: profileColor,
		})
			.then((res) => {
				return "Success!";
			})
			.catch((error) => {
				console.error(error);
				return null;
			});
	};

	const SendNotificationFromContact = async (newsLetterId:string,nameUser:string,messageSucces:string,messageError:string) => {
		await addDoc(collection(db, "notifications"), {
			type: "new-contact-form",
			newsLetterId: newsLetterId,
			name: "Academia de Lenguas Internacionales",
			text: `Nueva solicitud de contacto de ${nameUser}`,
			date: new Date(),
			userId: "admin",
			userType: 2,
			seen: false,
			profileColor: "#000000",
			sectionId: "4srk2wxgaBdxqGo23vsW",
		})
			.then((res) => {
                alert(messageSucces);
			})
			.catch((error) => {
				alert(messageError);
                console.log(error)
			});
	};

	return { invoiceRejectionNotif, invoiceRequestNotif, invoiceAutoGeneratedNotif, invoiceAcceptedNotif,SendNotificationFromContact };
}
