import { ErrorMessage, Field, FieldArray, Form, Formik, useFormik } from "formik";
import { array, object, string } from "yup";
import "./LocationsForm.scss";
import { TrashIcon } from "assets/Imports";
import { useEffect, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";

interface ILocationFormProps {
	location: ILocationDoc;
	locationRef: ILocationRef;
	setEdit: () => void;
	setEditLocation: () => void;
}

interface ILocationDoc {
	name: string;
	desc: string;
	schedules: Array<{ day: string; hours: Array<string> }>;
	index: number;
	locationIndex: string;
}

interface ILocationRef {
	id: string;
	locations: Array<ILocationDoc>;
	locations2: Array<ILocationDoc>;
	parentId: string;
	subDoc: string;
}

const locationSchema = object({
	name: string().required("Es necesario que ingreses el nombre de la sede."),
	schedules: array().of(
		object().shape({
			day: string().required("Campo obligatorio."),
			hours: array().of(string().required("Hora obligatoria")),
		})
	),
});

export const LocationsForm = (props: ILocationFormProps) => {
	const { location, locationRef, setEdit, setEditLocation } = props;
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

	const initialValues = {
		name: location.name || "",
		schedules: location.schedules || [],
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={locationSchema}
			onSubmit={async (values) => {
				setLoadingSubmit(true);
				console.log(values);
				console.log(locationRef);
				console.log(location);

				const temp = locationRef[location.locationIndex];
				temp[location.index] = { ...values };

				console.log(temp);
				await updateDoc(doc(db, "dynamic_copies", locationRef.parentId, locationRef.subDoc, locationRef.id), {
					[location.locationIndex]: temp,
				})
					.catch((error) => {
						console.error(error);
					})
					.finally(() => {
						setEditLocation();
						setEdit();
						setLoadingSubmit(false);
					});
			}}
			enableReinitialize
		>
			{({ values }) => (
				<Form className="location-form-container">
					<div className="input-container">
						<label htmlFor="name">Sede:</label>
						<Field id="name" name="name" />
						<ErrorMessage className="error-message" component={"div"} name="name" />
					</div>

					<FieldArray name="schedules">
						{({ insert, remove, push }) => (
							<div className="location-schedule-row-container">
								{values.schedules.length > 0 &&
									values.schedules.map((_, index) => (
										<div className="schedule-day-container" key={index}>
											<div className="day-container">
												<button className="trash" type="button" onClick={() => remove(index)}>
													<img src={TrashIcon} />
												</button>
												<div className="input-container">
													<label>Día:</label>
													<Field name={`schedules.${index}.day`} />
													<ErrorMessage className="error-message" component={"div"} name={`schedules.${index}.day`} />
												</div>
											</div>

											<FieldArray name={`schedules.${index}.hours`}>
												{({ remove: removeHour, push: pushHour }) => (
													<div className="hour-row">
														{values.schedules[index].hours.map((_, hourIndex) => (
															<div className="hour-container" key={hourIndex}>
																<div className="input-container">
																	<label>Hora:</label>
																	<Field name={`schedules.${index}.hours.${hourIndex}`} />
																	<ErrorMessage
																		name={`schedules.${index}.hours.${hourIndex}`}
																		className="error-message"
																		component={"div"}
																	/>
																</div>
																{values.schedules[index].hours.length > 1 && (
																	<button className="trash" type="button" onClick={() => removeHour(hourIndex)}>
																		<img src={TrashIcon} />
																	</button>
																)}
															</div>
														))}
														<button className="btn-success p-1" type="button" onClick={() => pushHour("")}>
															Agregar hora
														</button>
													</div>
												)}
											</FieldArray>
										</div>
									))}
								<button className="secondary-btn-bg p-2" type="button" onClick={() => push({ day: "", hours: [""] })}>
									Agregar día
								</button>
							</div>
						)}
					</FieldArray>
					<button type="submit" className="p-2 linear-bg border-0 text-light" disabled={loadingSubmit}>
						Guardar
					</button>
				</Form>
			)}
		</Formik>
	);
};
