import axios from "axios";

export const getDateData = async (dateString: string, userId: number) => {

	return axios
		.get(process.env.REACT_APP_API_URL + `studied-words/${dateString}/${userId}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.error(error);
			return error;
		});
};
