import firebase from "firebase/compat/app";
import { useEffect, useRef, useState } from "react";
import { getUniqueTotalWordsArray } from "../../functions/TestFunctions";
import "./QuizResultsStyles.scss";
import { InfoModal } from "./components/InfoModal";
import { Progress, Tooltip } from "antd";
import { returnFlagByLanguage } from "functions/Functions";
import { ClockIcon } from "assets/Imports";

function QuizResults({
	totalWords,
	correctWords,
	quiz,
	incorrectWords,
	realTotalWords,
	errorsCounter,
	repeats,
	repeatI,
	setView,
	quizLangText,
	time,
	quizWords,
	quizIncorrects,
	dominatedWords,
	quizCorrects,
	quizErrors,
	languageWords,
	language,
	subCatColores,
	countRefresh,
	setCountRefresh,
}) {
	const [modalShow, setModalShow] = useState<any>({
		show: false,
		words: [],
		title: "",
	});
	const [flagPercentage, setFlagPercentage] = useState<number>(0);
	const [flagNewPercentage, setFlagNewPercentage] = useState<number>(0);
	const soundRef = useRef<any>(null);

	async function getPercentage() {
		let tempWordQuiz = getUniqueTotalWordsArray(quiz.quizWords);
		let numDominated = 0;
		await Promise.all(
			languageWords.map((wordT) => {
				let index = dominatedWords.length === 0 ? tempWordQuiz.findIndex((e) => e.id === wordT.id) : -1;
				let tempNum = index === -1 ? wordT.dominated : wordT.dominated + tempWordQuiz[index].dominated;
				if (tempNum >= 3) {
					numDominated += 1;
				}
			})
		);

		return (numDominated / languageWords.length) * 100;
	}

	const setPercentage = async () => {
		const percentageTemp = await getPercentage();

		setFlagPercentage(percentageTemp);
		setFlagNewPercentage((languageWords.filter((e) => e.dominated !== null).length / languageWords.length) * 100);
	};


	useEffect(() => {
		setPercentage();

		if (dominatedWords.length > 0) {
			const timeout = setTimeout(() => {
				let part =
					(((languageWords.filter((e) => e.dominated === 3).length + dominatedWords.length) / languageWords.length) * 100) / 100;

				for (let index = 1; index <= 100; index++) {
					setTimeout(() => {
						setFlagPercentage(part * index);
					}, 10 * index);
				}

				// if (soundRef.current) {
				// 	try {
				// 		soundRef.current.play();
				// 	} catch (error) {
				// 		console.error(error);
				// 	}
				// }
			}, 1500);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, []);

	useEffect(() => {
		if (modalShow.title === "Términos de la ronda") {
			setModalShow({
				...modalShow,
				words: [
					...quizWords[0],
					...quizWords[1],
					...quizWords[2],
					...quizWords[3],
					...quizWords[4],
					...quizWords[5],
					...quizWords[6],
					...quizWords[7],
				],
			});
		}
	}, [quizWords]);

	return (
		<>
			{quizLangText.finalScreen !== "" && (
				<div className="final-screen-text">
					<h3>{quizLangText.finalScreen}</h3>
					<h4>{quizLangText.finalScreenSubtitle}</h4>
				</div>
			)}
			<audio style={{ position: "absolute", zIndex: -100, opacity: 0 }} ref={soundRef} controls>
				<source src="../../../../../../assets/media/positive-sound.mp3" />
			</audio>
			<InfoModal
				quiz={quiz}
				show={modalShow.show}
				onHide={() => setModalShow({ ...modalShow, show: false })}
				words={modalShow.words}
				title={modalShow.title}
				subCatColores={subCatColores}
				setCountRefresh={setCountRefresh}
				countRefresh={countRefresh}
			/>
			<div className="test-info-container final-results">
				<div className="test-score-card">
					<div className="title">
						<h2>Resultados</h2>
					</div>
					<div className="circle-progress-flag-container">
						<div className="circle-container">
							<h2 className="m-0">{Math.floor(flagPercentage)}%</h2>
							<div className="progress-container">
								<Tooltip>
									<Progress
										percent={flagNewPercentage}
										success={{
											percent: flagPercentage,
											strokeColor: flagPercentage < 100 ? "#ca5050" : "#efc900",
										}}
										type="circle"
										width={70}
										strokeColor="#959595"
										strokeWidth={10}
										trailColor="#e7e7e7"
										showInfo={false}
									/>
								</Tooltip>
								{returnFlagByLanguage(language)}
							</div>
						</div>
					</div>
					<div className="good-answers-row">
						<div className="label-container">
							<h3
								onClick={() =>
									setModalShow({
										show: true,
										words: getUniqueTotalWordsArray(quizWords),
										title: "Términos de la ronda",
									})
								}
							>
								Términos de la ronda
							</h3>
							<div className="question-icon">
								?
								<div className="info-box">
									<p>Total de términos en la ronda.</p>
								</div>
							</div>
						</div>
						<div className="words-progress-bar-container">
							<div className="words-progress-bar">
								<div className="words-progress" style={{ width: "100%" }}></div>
							</div>
							<p>{totalWords}</p>
						</div>
					</div>
					<div className="good-answers-row">
						<div className="label-container">
							<h3
								onClick={() =>
									setModalShow({
										show: true,
										words: quizCorrects,
										title: "Términos correctos",
									})
								}
							>
								Términos correctos
							</h3>
							<div className="question-icon">
								?
								<div className="info-box">
									<p>Términos que respondiste correctamente al menos una vez.</p>
								</div>
							</div>
						</div>
						<div className="words-progress-bar-container">
							<div className="words-progress-bar">
								<div className="words-progress" style={{ width: (correctWords / totalWords) * 100 + "%" }}></div>
							</div>
							<p>{correctWords}</p>
						</div>
					</div>
					<div className="good-answers-row">
						<div className="label-container">
							<h3
								onClick={() =>
									setModalShow({
										show: true,
										words: quizIncorrects,
										title: "Términos incorrectos",
									})
								}
							>
								Términos incorrectos
							</h3>
							<div className="question-icon">
								?
								<div className="info-box">
									<p>Término que no lograste contestar bien al menos una vez.</p>
								</div>
							</div>
						</div>
						<div className="words-progress-bar-container">
							<div className="words-progress-bar">
								<div className="words-progress" style={{ width: (incorrectWords / totalWords) * 100 + "%" }}></div>
							</div>
							<p>{incorrectWords}</p>
						</div>
					</div>
					<div className="good-answers-row">
						<div className="label-container">
							<h3
								onClick={() =>
									setModalShow({
										show: true,
										title: "Términos contestados",
										words: [
											...quizWords[0],
											...quizWords[1],
											...quizWords[2],
											...quizWords[3],
											...quizWords[4],
											...quizWords[5],
											...quizWords[6],
											...quizWords[7],
										],
									})
								}
							>
								Términos contestados
							</h3>
							<div className="question-icon">
								?
								<div className="info-box">
									<p>Total de veces que respondiste.</p>
								</div>
							</div>
						</div>
						<div className="words-progress-bar-container">
							<div className="words-progress-bar">
								<div className="words-progress" style={{ width: "100%" }}></div>
							</div>
							<p>{realTotalWords}</p>
						</div>
					</div>
					<div className="good-answers-row">
						<h3
							onClick={() =>
								setModalShow({
									show: true,
									words: quizErrors,
									title: "Términos en los que me equivoqué",
								})
							}
						>
							Total de veces que me equivoqué
						</h3>
						<div className="total-errors-container mt-2">
							<h2
								onClick={() =>
									setModalShow({
										show: true,
										words: quizErrors,
										title: "Términos en los que me equivoqué",
									})
								}
							>
								<span>{errorsCounter} </span>
								Errores
							</h2>
						</div>
					</div>
					<div className="good-answers-row">
						<h3 style={{ cursor: "initial" }}>Porcentaje de aciertos</h3>
						<div className="words-progress-bar-container">
							<div className="words-progress-bar">
								<div
									className="words-progress"
									style={{
										width:
											((realTotalWords - errorsCounter) / realTotalWords) * 100 < 0
												? 0
												: ((realTotalWords - errorsCounter) / realTotalWords) * 100 + "%",
									}}
								></div>
							</div>
							<p>
								{(((realTotalWords - errorsCounter) / realTotalWords) * 100 < 0
									? 0
									: ((realTotalWords - errorsCounter) / realTotalWords) * 100
								).toFixed(0) + "%"}
							</p>
						</div>
					</div>
					<div className="good-answers-row results-row">
						<h2>
							{correctWords}/{totalWords}
						</h2>
						<h3>Puntaje obtenido</h3>
					</div>
					<div className="d-flex justify-content-between">
						<div className="words-dominated-quiz">
							{dominatedWords.length > 0 && (
								<>
									<div>
										<h2
											className="dominated-number"
											onClick={() =>
												setModalShow({
													show: true,
													words: dominatedWords,
													title: "¡Felicidades!",
												})
											}
										>
											<span className="offset-header"> +{dominatedWords.length}</span>
										</h2>
									</div>
									<div className="dominated-label">
										<h2>
											<span className="offset-header">Términos dominados</span>
										</h2>
									</div>
								</>
							)}
						</div>
						<div className="time-text-container">
							<div>
								<h2 className="label">Tiempo transcurrido</h2>
							</div>
							<div>
								<h2 style={{ color: "#000000" }}>
									<img src={ClockIcon} alt="icono de relog" />
									{Math.ceil(time / 60)} minuto
									{Math.ceil(time / 60) > 1 ? "s" : ""}
								</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="test-footer">
				<div className="options-btns">
					<button className="prev-btn" onClick={() => repeats()}>
						{quizLangText.buttons.repetir}
					</button>
					{incorrectWords > 0 && (
						<button className="prev-btn" onClick={() => repeatI()}>
							Repasar incorrectas
						</button>
					)}
					<button className="next-btn" onClick={() => setView(1)}>
						{quizLangText.buttons.terminar}
					</button>
				</div>
			</div>
		</>
	);
}

export default QuizResults;
