import {
	InvoiceApiData,
	InvoiceData,
	addGenNameFB,
	cancelInvoiceApi,
	downloadInvoicePDF,
	firebaseDateFormatter,
	generateInvoice,
	getAllInvoices,
	updateDbStatus,
	FirebaseDate,
	sendInvoiceByEmail,
	getInvoiceStatusByPaymentId,
} from "@services/InvoiceServices";
import { Event } from "firebase-functions/lib/cloud-functions";
import {
	DocumentData,
	DocumentReference,
	DocumentSnapshot,
	collection,
	doc,
	getDoc,
	query,
	updateDoc,
	deleteDoc,
	where,
} from "firebase/firestore";
import { db } from "initFirebase";
import { useEffect, useMemo, useState } from "react";
import emailjs from "emailjs-com";
import useNotification from "@hooks/useNotification";

export default function useInvoices() {
	const [invoices, setInvoices] = useState<Array<InvoiceData>>([]);
	const [invoicesCopy, setInvoicesCopy] = useState<Array<InvoiceData>>([]);
	const [invoicesCopy2, setInvoicesCopy2] = useState<Array<InvoiceData>>([]);
	const { invoiceRejectionNotif, invoiceAcceptedNotif } = useNotification();
	const [loading, setLoading] = useState<boolean>(true);

	const filters = {
		2: "Pending",
		3: "Accepted",
		4: "Rejected",
		5: "AutoGenerated",
		6: "AdminGenerated",
	};

	const sendRejectEmail = (
		rejectReason: string,
		requestEmail: string,
		requestName: string,
		requestAmount: number,
		requestDate: any
	) => {
		let requestDateString = firebaseDateFormatter(requestDate);
		const emailParams = {
			email: requestEmail,
			name: requestName,
			cantidad: requestAmount,
			motivo: rejectReason,
			fecha: requestDateString, // Fecha de solicitud
		};
		emailjs.send("service_1kfzhtl", "template_x5rmsnv", emailParams, "user_PPI0O8nofs9cbuJ3JRWlT").then(
			function (res) {},
			function (error) {
				console.error(error);
			}
		);
	};
	const deleteInvoiceById = async (id: string) => {
		try {
			const invoiceRef = doc(db, "invoices", id);
			console.log(invoiceRef);
			await deleteDoc(invoiceRef);
			console.log("Documento eliminado con éxito");
		} catch (error: any) {
			if (error.code === "not-found") {
				console.error("No se encontró un documento con el ID proporcionado");
			} else {
				console.error("Ocurrió un error al eliminar el documento: ", error);
			}
		}
	};

	const cancelationValidation = (date) => {
		let tempDateMonth = date.toDate().getMonth();
		let currentMonth = new Date().getMonth();
		return tempDateMonth === currentMonth;
	};

	const removeInvoice = (invoiceId: string) => {
		const filteredArray = invoices.filter((obj) => obj.id !== invoiceId);
		setInvoices(filteredArray);
	};

	const cancelInvoice = async (invoiceApiId: string, invoiceId: string) => {
		try {
			const res = await cancelInvoiceApi(invoiceApiId);
			if (res !== null) {
				const now = new Date();
				const timestamp = {
					seconds: Math.floor(now.getTime() / 1000),
					nanoseconds: (now.getTime() % 1000) * 1000000,
					toDate() {
						const milliseconds = this.seconds * 1000 + Math.floor(this.nanoseconds / 1e6);
						return new Date(milliseconds);
					},
				};

				let canceledInvoice = invoices.filter((e) => e.id === invoiceId);

				await updateDbStatus("Canceled", invoiceId);
				canceledInvoice[0].canceledDate = timestamp;
				canceledInvoice[0].status = "Canceled";
				console.log("Invoice updated");
				removeInvoice(invoiceId);

				return true; // Return true if successful
			}
		} catch (error) {
			console.error("Cancellation error:", error);
		}

		return false; // Return false if unsuccessful
	};

	const downloadInvoice = async (invoiceApiId, ticketNumber, matricula, format) => {
		downloadInvoicePDF(invoiceApiId, ticketNumber, matricula, format);
	};

	const addGeneration = async (invoice: InvoiceData) => {
		if (invoice.userGen && !invoice.userGenName && invoice.id) {
			addGenNameFB(invoice.id, invoice.userGen);
		}
	};

	const invoiceSearch = (searchEvent) => {
		const searchText = searchEvent.target.value.toLowerCase();
		if (searchText != null && searchText.length > 2) {
			const arraySearch = invoicesCopy2.filter(
				(item) =>
					item.name.toLowerCase().includes(searchText) ||
					(item.userGenName && item.userGenName.toLowerCase().includes(searchText)) ||
					(item.matricula && item.matricula.toLowerCase().includes(searchText)) ||
					item.email.toLowerCase().includes(searchText)
			);
			setInvoices(arraySearch);
		} else {
			setInvoices(invoicesCopy2);
		}
	};

	const getInvoices = async () => {
		setLoading(true);
		const tempInvoices: any = await getAllInvoices();
		setInvoices(tempInvoices);
		setInvoicesCopy(tempInvoices);
		setInvoicesCopy2(tempInvoices);
		setLoading(false);
		return;
	};

	const acceptInvoice = async (invoice: InvoiceData): Promise<boolean> => {
		try {
			let data: InvoiceApiData = {
				legal_name: invoice.corporateName,
				tax_id: invoice.rfc,
				tax_system: invoice.fiscalRegime,
				zip: invoice.zipCode,
				street: invoice.street,
				city: invoice.city,
				state: invoice.state,
				email: invoice.email,
				product_description: "Capacitación en idiomas extranjeros",
				product_key: "86111701",
				price: invoice.amount,
				payment_form: invoice.paymentForm,
				use: invoice.use,
			};

			console.log(data);
			let successfulInvoice = await generateInvoice(data);

			console.log(successfulInvoice);

			if (successfulInvoice && invoice.id) {
				await sendInvoiceByEmail(successfulInvoice, invoice);

				if (invoice.userId) {
					invoiceAcceptedNotif(invoice.userId);
				}

				if (invoice.id) {
					const now = new Date();

					const timestamp = {
						seconds: Math.floor(now.getTime() / 1000),
						nanoseconds: (now.getTime() % 1000) * 1000000,
						toDate() {
							const milliseconds = this.seconds * 1000 + Math.floor(this.nanoseconds / 1e6);
							return new Date(milliseconds);
						},
					};

					await updateDbStatus("Accepted", invoice.id, undefined, successfulInvoice.id, successfulInvoice.folio_number);

					invoice.ticketNumber = successfulInvoice.folio_number;
					invoice.ApiId = successfulInvoice.id;
					invoice.acceptedDate = timestamp;
					invoice.status = "Accepted";
					console.log("Invoice updated");
				}

				removeInvoice(invoice.id);

				return true;
			}
		} catch (error) {
			console.error("Error accepting invoice: ", error);
		}

		return false;
	};

	const rejectInvoice = async (invoice: InvoiceData, rejectReason: string): Promise<boolean> => {
		try {
			if (invoice.id) {
				const now = new Date();
				const timestamp = {
					seconds: Math.floor(now.getTime() / 1000),
					nanoseconds: (now.getTime() % 1000) * 1000000,
				};

				await updateDbStatus("Rejected", invoice.id, rejectReason);

				invoice.rejectedDate = timestamp;
				invoice.status = "Rejected";
				invoice.rejectReason = rejectReason;
				console.log("Invoice updated");
				sendRejectEmail(rejectReason, invoice.email, invoice.name, invoice.amount, invoice.requestDate);
			}

			if (invoice.id) {
				removeInvoice(invoice.id);
			}

			if (invoice.userId) {
				invoiceRejectionNotif(invoice.userId);
			}

			return true;
		} catch (error) {
			console.error("Error rejecting invoice: ", error);
			return false;
		}
	};

	function filterInvoices(filter: number) {
		const tempInvoices = filter === 1 ? invoicesCopy : invoicesCopy?.filter((invoice) => invoice.status === filters[filter]);
		if (tempInvoices !== undefined) {
			setInvoices(tempInvoices);
			setInvoicesCopy2(tempInvoices);
		} else {
			setInvoices([]);
		}
		setLoading(false);
	}

	return {
		invoices,
		filterInvoices,
		getInvoices,
		acceptInvoice,
		rejectInvoice,
		invoiceSearch,
		addGeneration,
		downloadInvoice,
		cancelInvoice,
		cancelationValidation,
		sendRejectEmail,
		deleteInvoiceById,
		loading,
	};
}
