import { useEffect, useState } from "react";
import "./ReportsCardStyles.scss";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { BsFillTrash3Fill } from "react-icons/bs";
import { EditIcon } from "assets/Imports";
import { updateChecks, deleteReport } from "@api/Vocabulary";
import { SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import { language } from "../../../../../interfaces/Interfaces";
import { returnFlagByLanguage } from "functions/Functions";
import { EditWordModal } from "@components/Vocabulary/EditWordModal/EditWordModal";
import React from "react";

function ReportsCard({ data, setUncheckedReports, key, children }: any) {
	const [filter, setFilter] = useState<number>(0); // Valor de filtros, 2 (ascendente y descendente) por cada campo (Fecha, seminario, Palabra, Idioma)
	const [loading, setLoading] = useState({ state: false, index: null });
	const [freshData, setFreshData] = useState(data);
	const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
	const [editWordModal, setEditWordModal] = useState({ show: false, word_data_id: null });

	const sorting = () => {
		if (!freshData || freshData.length === 0) return;
		const criteria = [
			(a, b) => b.date.localeCompare(a.date),
			(a, b) => a.date.localeCompare(b.date),
			(a, b) => b.num - a.num,
			(a, b) => a.num - b.num,
			(a, b) => b.word.localeCompare(a.word),
			(a, b) => a.word.localeCompare(b.word),
			(a, b) => b.language.localeCompare(a.language),
			(a, b) => a.language.localeCompare(b.language),
		];
		freshData.sort(criteria[filter]);
	};

	useEffect(() => {
		sorting();
	}, [filter, freshData]);

	sorting();

	const countUncheckedReports = () => {
		let count = 0;
		data.forEach((e) => {
			if (e.checked === 0) {
				count++;
			}
		});
		setUncheckedReports(count);
	};

	useEffect(() => {
		if (loading.state === false && deleteLoader === false) {
			countUncheckedReports();
		}
	}, [loading, deleteLoader]);

	const deleteThisReport = async (id, index) => {
		setDeleteLoader(true);
		await deleteReport(id)
			.then((res) => {
				const indexDel = data.findIndex((item) => item.id === id);
				delete data[indexDel];
				setFreshData(data);
				setDeleteLoader(false);
			})
			.catch((error) => {
				console.error(error);
				alert("Ha ocurrido un error.");
			});
	};

	const setChecked = async (checkedP, id, index) => {
		setLoading({ state: true, index: index });
		const tempFreshData = freshData;
		checkedP === true ? (tempFreshData[index].checked = 1) : (tempFreshData[index].checked = 0);
		await updateChecks(checkedP === true ? 1 : 0, id)
			.then((res) => {
				if (checkedP === true) {
					setFreshData([]);
					const indexCheck = tempFreshData.findIndex((item) => item.id === id);
					tempFreshData.splice(indexCheck, 1);
					setFreshData(tempFreshData);
				}
				setLoading({ state: false, index: null });
			})
			.catch((error) => {
				console.error(error);
				alert("Ha ocurrido un error.");
			});
	};

	return (
		<>
			{React.cloneElement(children, {
				show: editWordModal.show,
				onHide: () => setEditWordModal({ show: false, word_data_id: null }),
				word_data_id: editWordModal.word_data_id,
			})}
			<table className="table">
				<thead className="head">
					<tr>
						<th scope="col" className="date">
							<div
								onClick={filter !== 0 ? () => setFilter(0) : () => setFilter(1)}
								style={{ textDecoration: filter === 0 || filter === 1 ? "underline" : "" }}
							>
								Fecha {filter === 0 ? <AiOutlineArrowDown /> : filter === 1 ? <AiOutlineArrowUp /> : ""}
							</div>
						</th>
						<th scope="col" className="seminary">
							<div
								onClick={filter !== 2 ? () => setFilter(2) : () => setFilter(3)}
								style={{ textDecoration: filter === 2 || filter === 3 ? "underline" : "" }}
							>
								Seminario {filter === 2 ? <AiOutlineArrowDown /> : filter === 3 ? <AiOutlineArrowUp /> : ""}
							</div>
						</th>
						<th scope="col" className="word">
							<div
								onClick={filter !== 4 ? () => setFilter(4) : () => setFilter(5)}
								style={{ textDecoration: filter === 4 || filter === 5 ? "underline" : "" }}
							>
								Palabra {filter === 4 ? <AiOutlineArrowDown /> : filter === 5 ? <AiOutlineArrowUp /> : ""}
							</div>
						</th>
						<th scope="col" className="language">
							<div
								onClick={filter !== 6 ? () => setFilter(6) : () => setFilter(7)}
								style={{ textDecoration: filter === 6 || filter === 7 ? "underline" : "" }}
							>
								Idioma {filter === 6 ? <AiOutlineArrowDown /> : filter === 7 ? <AiOutlineArrowUp /> : ""}
							</div>
						</th>
						<th scope="col" className="comment">
							Comentario
						</th>
						<th scope="col" className="state">
							Estado
						</th>
						<th scope="col" className="state">
							Acciones
						</th>
					</tr>
				</thead>
				<tbody className="body">
					{freshData.length > 0 && !deleteLoader ? (
						<>
							{freshData.map((report, index) => {
								let date: any = new Date(report.date);
								date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
								return (
									<tr key={"report-" + report.id} id={"report-" + report.id}>
										<th className="date">{date}</th>
										<td className="seminary">{report.num}</td>
										<td className="word">{report.word}</td>
										<td className="language">{returnFlagByLanguage(report.language)}</td>
										<td className="comment">{report.comment}</td>
										<td className="check">
											<input
												type="checkbox"
												defaultChecked={report.checked === 1 ? true : false}
												onChange={(e) => setChecked(e.target.checked, report.id, index)}
												style={loading.state === true || deleteLoader ? { display: "none" } : {}}
											/>
											{loading.state === true && loading.index === index && <SpinnerCustom height={"2.5rem"} />}
										</td>
										<td className="actions">
											<img
												src={EditIcon}
												alt="edit word"
												onClick={() => setEditWordModal({ show: true, word_data_id: report.word_data_id })}
											/>
											<BsFillTrash3Fill onClick={() => deleteThisReport(report.id, index)} className="trash-icon" />
										</td>
									</tr>
								);
							})}
						</>
					) : (
						<SpinnerCustom height={"5rem"} />
					)}
				</tbody>
			</table>
		</>
	);
}
export default ReportsCard;
