import { ChangeEvent, useEffect, useState } from "react";
import { Modal, Table, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import InputMask from "react-input-mask";
import {
	collection,
	doc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { db } from "initFirebase";
import { useHistory, useParams } from "react-router-dom";
import parse from "html-react-parser";
import { NewUserModal } from "./components/NewUserModal/NewUserModal";
import { AsuetosModal } from "./components/AsuetosModal/AsuetosModal";
import AddProfessorModalComponent from "./components/AddProfessorModalComponent/AddProfessorModalComponent";
import Teachers from "./components/TeachersTableComponent/Teachers";
import { BackArrowIcon, CheckmarkIcon, CloseCircle, EditIcon } from "assets/Imports";
import { UserProfileModal } from "../PaymentRequestContainer/components/UserProfileModal/UserProfileModal";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import {
	CountdownReference,
	GenTypes,
	Location,
	SimpleDate,
	TGeneration,
	isGenInPerson,
	isGenOnline,
	isValidGenType,
	GenerationTypesToSpanish,
} from "interfaces/Generations";
import {
	getCountDownReference,
	getGenerationsLocations,
	updateCountDownReference,
} from "@services/MiscellaneousServices";
import { SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import { getSimpleDate } from "@utils/utilFunctions";
import useActiveGenerations from "@hooks/Modes/useFetchActiveModes.hook";

const uppercaseCyrillicCharacters = [
	"А",
	"Б",
	"В",
	"Г",
	"Д",
	"Е",
	"Ё",
	"Ж",
	"З",
	"И",
	"Й",
	"К",
	"Л",
	"М",
	"Н",
	"О",
	"П",
	"Р",
	"С",
	"Т",
	"У",
	"Ф",
	"Х",
	"Ц",
	"Ч",
	"Ш",
	"Щ",
	"Ъ",
	"Ы",
	"Ь",
	"Э",
	"Ю",
	"Я",
];

const weekDays = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];

// Representa la fecha de hoy en el formato "simple" DD/MM/YYYY
const simpleToday: SimpleDate = getSimpleDate(new Date());

const genDefaultValues: TGeneration = {
	id: "",
	name: "",
	initialDate: simpleToday,
	schedule: "",
	asuetos: [],
	type: GenTypes.InPerson,
	sede: "none",
	classDay: "",
	startHour: "",
	endHour: "",
	cirilic: "",
	active: false,
	createdAt: new Date(),
	visible: false,
	promoDate: simpleToday,
	limit: 0,
	countdownEnabled: false,
	hoursPerWeek: 2,
	totalHours: 120,
	regularPrice: 300,
	promoPrice: 250,
	regularPriceUSD: 18,
	promoPriceUSD: 15,
};
// Componente que contiene todos los detalles de una generacion como un espacio para editar asuetos, datos de la generacion, y lista de estudiantes/profesores
export function GenerationDetailsContainer(user) {
	const { id } = useParams<any>();
	const [studentModal, setStudentModal] = useState(false);
	const [loadingStudents, setLoadingStudents] = useState(true);
	const [modules, setModules]: any = useState([]);
	const [showAddStudentModal, setShowAddStudentModal] = useState(false);
	const [asuetosModalShow, setAsuetosModalShow] = useState(false);
	const [tableView, setTableView] = useState("students");
	const [gen, setGen] = useState<TGeneration>(genDefaultValues);
	const [student, setStudent]: any = useState(null);
	const [students, setStudents]: any = useState([]);
	const [parammeter, setParammeter] = useState<string>("");
	const [emailModalShow, setEmailModalShow] = useState<boolean>(false);
	const [filteredEmails, setFilteredEmails] = useState(new Array());
	const [students2, setStudents2]: any = useState([]);
	const [orderByg, setOrderByg] = useState("name");
	const [teachers, setTeachers] = useState([]);
	const [matricula, setMatricula] = useState("");
	const [schedule, setSchedule] = useState<string>("");
	const [initialDate, setInitialDate] = useState<string>("");
	const [showAddProfessorModal, setShowAddProfessorModal] = useState<boolean>(false);
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(true);
	const [searchText, setSearchText] = useState<string>("");
	const [newStudent, setNewStudent] = useState<number>(0);
	const [oldStudent, setOldStudent] = useState<number>(0);
	const [studentFilter, setStudentFilter] = useState<any>("recursamiento");
	const [oldStudents, setOldStudents] = useState<any>();
	const [userProfileModal, setUserProfileModal] = useState<boolean>(false);
	const [orderDirection, setOrderDirection] = useState("asc");
	const [locationOptions, setlocationOptions] =
		useState<Array<{ type: string; element: JSX.Element }>>();
	const [savingGeneration, setSavingGeneration] = useState<boolean>(false);
	const [countdownReference, setCountdownReference] = useState<CountdownReference>();
	const [typeGen, setTypeGen] = useState<GenTypes>();
	const [selected, setSelected] = useState(GenerationTypesToSpanish[gen.type]);
	const [invoiceable, setInvoiceable] = useState<boolean>(true);
	let countdownReferenceInitialValue: CountdownReference;
	let locations: Location[] = [];

	const studentFilterFunction = () => {
		setStudentFilter(studentFilter === "recursamiento" ? "recursamiento-active" : "recursamiento");
	};

	useEffect(() => {
		setStudents(studentFilter === "recursamiento-active" ? oldStudents : students2);
	}, [studentFilter]);

	const getAndSetCountDownReference = async () => {
		try {
			const response = await getCountDownReference();
			if (response) {
				setCountdownReference(response);
				countdownReferenceInitialValue = response;
			}
		} catch (error) {
			console.error(error);
		}
	};
	const getGeneration = async () => {
		const unsub = onSnapshot(doc(db, "generations", id), (genSnap) => {
			if (genSnap.exists()) {
				const data: any = {
					id: genSnap.id,
					...genSnap.data(),
				};
				setSchedule(data.schedule);
				setInitialDate(data.initialDate);
				setTypeGen(data.type);
				setSelected(GenerationTypesToSpanish[data.type]);
				checkGen(data);
			} else {
				history.push("/admin");
			}
		});
		locations = await getGenerationsLocations();
		let tempLocationOptions = locations.map((e) => {
			return { type: e.type, element: <option value={e.name}>{e.name}</option> };
		});
		setlocationOptions(tempLocationOptions);
	};

	const checkGen = async (data) => {
		setLoadingStudents(true);
		let studentsArray: any = [];
		let teachersArray: any = [];
		const q = query(collection(db, "users"), where("generationId", "==", data.id));

		const querySnapshot = await getDocs(q);
		await Promise.all(
			querySnapshot.docs.map(async (user) => {
				const data2: any = {
					...user.data(),
					id: user.id,
				};

				if (data2.birthdate === undefined) {
					const docRef = query(collection(db, "forms"), where("userId", "==", user.id));
					const formSnap = await getDocs(docRef);

					if (formSnap.size > 0) {
						data2.birthdate = formSnap.docs[0].data().birthdate;
						const userRef = doc(db, "users", data2.id);
						await updateDoc(userRef, {
							birthdate: data2.birthdate,
						});
					}
				}
				if (data2.userType === 3) {
					teachersArray.push(data2);
				} else {
					studentsArray.push(data2);
				}
			})
		);
		await setGen(data);
		teachersArray.sort(compareName);
		setTeachers(teachersArray);
		if (orderByg === "name") {
			studentsArray.sort(compareName);
			await getStudents(data, studentsArray);
		} else {
			studentsArray.sort(compareAddedAt);
			await getStudents(data, studentsArray);
		}
	};

	function comparePaymentsDate(a, b) {
		const date1 = new Date(a.date);
		const date2 = new Date(b.date);

		if (date1 > date2) {
			return -1;
		}
		if (date1 < date2) {
			return 1;
		}
		return 0;
	}

	const getStudents = async (gen, students) => {
		let dateSplit = gen.initialDate.split("/");
		const date = new Date();
		let arrayStudents = students;
		date.setDate(dateSplit[0]);
		date.setMonth(dateSplit[1] - 1);
		date.setFullYear(dateSplit[2]);
		if (arrayStudents.length > 0) {
			arrayStudents.forEach(async (student, index) => {
				let pagos = new Array();
				let totalPay = 0;
				let status = "";
				let weeks = 0;
				const q = query(
					collection(db, "paymentHistory"),
					where("userId", "==", student.id),
					where("verified", "==", true)
				);

				const querySnapshot = await getDocs(q);

				querySnapshot.forEach((doc) => {
					totalPay = totalPay + parseInt(doc.data().amount);
					pagos.push({ id: doc.id, ...doc.data() });
				});

				if (
					(querySnapshot.size === 1 && totalPay === 12000 && isGenOnline(gen.type)) ||
					(querySnapshot.size === 1 && totalPay === 15000 && isGenInPerson(gen.type)) ||
					student.promotion === true
				) {
					totalPay = student.promotion ? totalPay : isGenOnline(gen.type) ? 12000 : 15000;
					status = "Pago completo";
					arrayStudents[index] = {
						...arrayStudents[index],
						status: status,
						totalPay: totalPay,
						numPayments: querySnapshot.size,
						paidModules: 5,
						pagos: pagos,
					};
				} else {
					let modules = 0;
					if (totalPay >= 3000 && totalPay < 7500) {
						modules = 1;
					} else if (totalPay >= 7500 && totalPay < 12000) {
						modules = 2;
					} else if (totalPay >= 12000 && totalPay < 15000) {
						modules = 3;
					} else if (totalPay >= 15000 && totalPay < 18000) {
						modules = 4;
					} else if (totalPay == 18000) {
						modules = 5;
					} else {
						modules = 0;
					}
					weeks = Math.floor(totalPay / 1500) * 5;
					let nextDate = new Date();
					nextDate.setDate(dateSplit[0]);
					nextDate.setMonth(dateSplit[1] - 1);
					nextDate.setFullYear(dateSplit[2]);
					let now = new Date();
					nextDate.setDate(date.getDate() + weeks * 7);
					nextDate.setHours(0, 0, 0);
					if (now > nextDate) {
						status = "Pago retrasado";
					} else {
						status = "Pago completo";
					}

					arrayStudents[index] = {
						...arrayStudents[index],
						status: status,
						totalPay: totalPay,
						numPayments: querySnapshot.size,
						nextDate: nextDate,
						paidModules: modules,
						pagos: pagos.sort(comparePaymentsDate),
					};
				}
				if (index === arrayStudents.length - 1) {
					if (orderByg === "name") {
						arrayStudents.sort(compareName);
						await setStudents(arrayStudents);
						await setStudents2(arrayStudents);
					} else if (orderByg === "addedAt") {
						arrayStudents.sort(compareAddedAt);
						await setStudents(arrayStudents);
						await setStudents2(arrayStudents);
					} else if (orderByg === "activity") {
						arrayStudents.sort(compareActivity);
						await setStudents(arrayStudents);
						await setStudents2(arrayStudents);
					} else {
						arrayStudents.sort(compareMatricula);
						await setStudents(arrayStudents);
						await setStudents2(arrayStudents);
					}
					setLoadingStudents(false);
					setLoading(false);
				}
			});
		} else {
			setLoading(false);
			setLoadingStudents(false);
		}
	};

	function compareName(a, b) {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	}

	function compareAddedAt(a, b) {
		if (a.addedAt.seconds > b.addedAt.seconds) {
			return -1;
		}
		if (a.addedAt.seconds < b.addedAt.seconds) {
			return 1;
		}
		return 0;
	}

	function compareMatricula(a, b) {
		if (a.matricula < b.matricula) {
			return -1;
		}
		if (a.matricula > b.matricula) {
			return 1;
		}
		return 0;
	}

	function compareTotalPay(a, b) {
		if (a.totalPay > b.totalPay) {
			return -1;
		}
		if (a.totalPay < b.totalPay) {
			return 1;
		}
		return 0;
	}

	function compareActivity(a, b) {
		if (a.lastSeen !== undefined && b.lastSeen === undefined) {
			return -1;
		} else if (a.lastSeen === undefined && b.lastSeen !== undefined) {
			return 1;
		} else if (a.lastSeen === undefined && b.lastSeen === undefined) {
			return 0;
		}
		if (a.lastSeen.seconds > b.lastSeen.seconds) {
			return -1;
		}
		if (a.lastSeen.seconds < b.lastSeen.seconds) {
			return 1;
		}
		return 0;
	}

	const materialModalClose = () => {};

	const arraySearch = (array, keyword) => {
		const searchTerm = keyword
			.replace(/[+\s]/g, "")
			.toLowerCase()
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "");
		return array.filter((value) => {
			if (
				value.name
					.toLowerCase()
					.replace(/\s/g, "")
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.match(new RegExp(searchTerm, "g")) ||
				value.phone.replace(/[+\s]/g, "").includes(searchTerm, "g")
			) {
				return true;
			}
		});
	};

	const handleOnChange = async (e) => {
		let value = e.target.value;
		if (value.length > 2) {
			let search = await arraySearch(
				value.length < searchText.length ? students2 : students,
				value
			);
			setStudents(search);
		} else {
			setStudents(students2);
		}
		setSearchText(value);
	};

	const saveGen = async () => {
		setSavingGeneration(true);
		const genRef = doc(db, "generations", gen.id);
		if (gen.active === true) {
			const q = query(
				collection(db, "generations"),
				where("active", "==", true),
				where("type", "==", gen.type)
			);
			const snap = await getDocs(q);
			snap.forEach(async (element) => {
				if (element.data().active === true) {
					let elementRef = doc(db, "generations", element.id);
					await updateDoc(elementRef, {
						active: false,
					}).then(async () => {
						await updateDoc(genRef, {
							...gen,
						}).then(() => {
							checkGen(gen);
						});
					});
				}
			});
		}
		if (schedule !== gen.schedule) {
			await Promise.all(
				students.map(async (student) => {
					const studentRef = doc(db, "users", student.id);
					await updateDoc(studentRef, {
						schedule: gen.schedule,
					});
				})
			);
		}
		if (gen.type !== typeGen) {
			await updateDoc(genRef, {
				type: typeGen,
			});
		}

		if (initialDate !== gen.initialDate) {
			let initialDateSplit: any = gen.initialDate?.split("/");
			let lastDate =
				initialDateSplit[0] + "/" + (initialDateSplit[1] - 1) + "/" + initialDateSplit[2];

			const date = new Date();
			const queryMaterials = query(
				collection(db, "generations", gen.id, "material"),
				orderBy("num", "asc")
			);
			const materialesRef = await getDocs(queryMaterials);
			await Promise.all(
				materialesRef.docs.map(async (mDoc) => {
					let dateSplit = lastDate.split("/");
					date.setDate(parseInt(dateSplit[0]));
					date.setMonth(parseInt(dateSplit[1]));
					date.setFullYear(parseInt(dateSplit[2]));

					let dateString = "";

					if (mDoc.data().num > 1) {
						date.setDate(date.getDate() + 7);
						gen.asuetos.forEach((day) => {
							if (date.getMonth() < 9) {
								if (date.getDate() < 10) {
									dateString =
										"0" + date.getDate() + "/0" + (date.getMonth() + 1) + "/" + date.getFullYear();
								} else {
									dateString =
										date.getDate() + "/0" + (date.getMonth() + 1) + "/" + date.getFullYear();
								}
							} else {
								if (date.getDate() < 10) {
									dateString =
										"0" + date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
								} else {
									dateString =
										date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
								}
							}
							if (dateString === day.date) {
								date.setDate(date.getDate() + 7);
							}
						});
					}

					lastDate = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();

					const materialRef = doc(db, "generations", gen.id, "material", mDoc.id);

					await updateDoc(materialRef, {
						date: lastDate,
					});
				})
			);
		}

		await updateDoc(genRef, {
			...gen,
			type: typeGen,
		}).then(() => {
			setSavingGeneration(false);
			alert("Cambios guardados con éxito!");
			checkGen(gen);
		});
	};

	const goBack = () => {
		history.push("/admin");
	};

	function GenerateEmail() {
		const studentsArray = new Array(...students);
		studentsArray.sort(compareTotalPay);
		if (parammeter.length > 0) {
			var limit = parseInt(parammeter);
			//filtrar resultados
			var allAbove = new Array();

			studentsArray.forEach((element) => {
				if (element.totalPay >= limit) {
					allAbove.push(element);
				}
			});

			var onlyEmails = new Array();
			allAbove.forEach((element) => {
				if (element.secondaryEmail !== undefined) {
					onlyEmails.push(element.secondaryEmail);
				} else {
					onlyEmails.push("<b>" + element.email + "</b>");
				}
			});

			setEmailModalShow(true);
			setFilteredEmails(onlyEmails);
		} else {
			alert("Ingresa un número");
		}
	}

	const seeStudent = (student) => {
		let total;
		let modulos;
		let arrayModulos: any = [];

		if (
			(student.numPayments === 1 && student.totalPay === 12000 && isGenOnline(gen.type)) ||
			(student.numPayments === 1 && student.totalPay === 15000 && isGenInPerson(gen.type)) ||
			student.promotion === true
		) {
			total = student.promotion ? student.totalPay : isGenOnline(gen.type) ? 12000 : 15000;
			modulos = 5;
		} else {
			total = 18000;
			if (student.totalPay >= 3000 && student.totalPay < 7500) {
				modulos = 1;
			} else if (student.totalPay >= 7500 && student.totalPay < 12000) {
				modulos = 2;
			} else if (student.totalPay >= 12000 && student.totalPay < 15000) {
				modulos = 3;
			} else if (student.totalPay >= 15000 && student.totalPay < 18000) {
				modulos = 4;
			} else if (student.totalPay == 18000) {
				modulos = 5;
			} else {
				modulos = 0;
			}
		}

		for (let index = 1; index <= 5; index++) {
			let status = "";
			let weeks = 0;
			switch (index) {
				case 1:
					weeks = 0;
					break;
				case 2:
					weeks = 10;
					break;
				case 3:
					weeks = 25;
					break;
				case 4:
					weeks = 40;
					break;
				case 5:
					weeks = 50;
					break;
				default:
					break;
			}
			let dateSplit = gen.initialDate?.split("/");
			let date = new Date();
			if (dateSplit != null) {
				date.setDate(parseInt(dateSplit[0]));
				date.setMonth(parseInt(dateSplit[1]) - 1);
				date.setFullYear(parseInt(dateSplit[2]));
			}
			date.setDate(date.getDate() + weeks * 7);
			if (index <= modulos) {
				status = "Pagado";
			} else if (index > modulos && new Date() < date) {
				status = "Por pagar";
			} else {
				status = "Retrasado";
			}
			const data = {
				name: "Módulo " + index,
				status: status,
			};
			arrayModulos.push(data);

			if (index === 5) {
				setModules(arrayModulos);
				setStudent(student);
				setMatricula(student.matricula);
				setStudentModal(true);
				setUserProfileModal(true);
			}
		}
	};
	const handleAscDesc = async (e) => {
		if (e === "activity") {
			const newDirection = orderDirection === "asc" ? "desc" : "asc";
			setOrderDirection(newDirection);

			setStudents([...students.sort(compareActivityDescAsc(newDirection))]);
			setOrderByg(e);
		} else if (e === "totalpay") {
			const newDirection = orderDirection === "asc" ? "desc" : "asc";
			setOrderDirection(newDirection);

			setStudents([...students.sort(compareTotalPayDescAsc(newDirection))]);
			setOrderByg(e);
		}
	};

	const compareActivityDescAsc = (direction) => (a, b) => {
		const activityA = a.lastSeen ? a.lastSeen.seconds : 0;
		const activityB = b.lastSeen ? b.lastSeen.seconds : 0;

		if (direction === "asc") {
			return activityA - activityB;
		} else {
			return activityB - activityA;
		}
	};

	const compareTotalPayDescAsc = (direction) => (a, b) => {
		const totalPayA = a.totalPay;
		const totalPayB = b.totalPay;

		if (direction === "asc") {
			return totalPayA - totalPayB;
		} else {
			return totalPayB - totalPayA;
		}
	};

	const handleSelected = async (e) => {
		if (e === "name") {
			setStudents(students.sort(compareName));
			setOrderByg(e);
		} else if (e === "addedAt") {
			setStudents(students.sort(compareAddedAt));
			setOrderByg(e);
		} else if (e === "matricula") {
			setStudents(students.sort(compareMatricula));
			setOrderByg(e);
		} else if (e === "totalpay") {
			setStudents(students.sort(compareTotalPay));
			setOrderByg(e);
		} else if (e === "activity") {
			setStudents(students.sort(compareActivity));
			setOrderByg(e);
		}
	};

	const newStudents = (students) => {
		let tempNewStudent: any = [];
		let oldStudentsTemp: any = [];
		const dateArray = gen.initialDate?.split("/");
		if (dateArray !== undefined) {
			const dateArrayFix = dateArray[2].slice(2) + dateArray[1] + dateArray[0];
			const dateArrayFix2 = dateArray[2].slice(2) + dateArray[0] + dateArray[1];

			students.map((student) => {
				if (student.matricula.includes(dateArrayFix) || student.matricula.includes(dateArrayFix2)) {
					tempNewStudent.push(student.matricula);
				} else {
					oldStudentsTemp.push(student);
				}
			});
		}

		setOldStudents(oldStudentsTemp);
		setNewStudent(tempNewStudent.length);
		setOldStudent(oldStudentsTemp.length);
	};
	useEffect(() => {
		getGeneration();
		getAndSetCountDownReference();
	}, []);

	useEffect(() => {
		if (students.length > 0) {
			newStudents(students);
		}
	}, [students2]);
	if (loading)
		return (
			<div
				style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}
			>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);

	async function handleCountdownReferenceValueChange(
		event: ChangeEvent<HTMLSelectElement>
	): Promise<void> {
		setCountdownReference(event.target.value as CountdownReference);
		await updateCountDownReference(event.target.value);
	}

	const handleSelectModality = (eventKey: string | null) => {
		if (eventKey) {
			if (Object.values(GenTypes).includes(eventKey as GenTypes)) {
				const selectedType = eventKey as GenTypes;
				setTypeGen(selectedType);
				setSelected(GenerationTypesToSpanish[selectedType]);
			} else {
				setSelected(eventKey); // Handle custom or unexpected values
			}
			if (eventKey === "Otro") {
				setInvoiceable(false);
			}
		}
	};

	return (
		<div className="admin-generation-details-container">
			<div
				className="go-up-btn"
				onClick={() => {
					window.scrollTo(0, 0);
				}}
			>
				<img src={BackArrowIcon} alt="flecha icono" />
			</div>
			<Modal
				show={emailModalShow}
				onHide={materialModalClose}
				dialogClassName="student-modal"
				aria-labelledby="example-custom-modal-styling-title"
				id="emailReport"
			>
				<AddProfessorModalComponent
					gen={gen}
					show={showAddProfessorModal}
					onHide={() => setShowAddProfessorModal(false)}
					saveGen={saveGen}
				/>
				<Row style={{ padding: "40px" }}>
					<Col>
						<Row>
							<div style={{ fontWeight: "bolder" }}>{filteredEmails.length} Correos</div>
							<div style={{ fontSize: "0.875rem" }}>
								Se presenta lista de correos electrónicos para grabaciones de los usuarios con pagos
								registrados mayores a {parammeter} <br /> <br /> Las direcciones marcadas en
								negritas corresponden a los estudiantes que aún no han dado de alta un mail para
								grabaciones.
							</div>
							<div id="emailHeader">
								{filteredEmails.map((element, index) => {
									return (
										<>
											<span style={{ fontSize: "1rem" }} key={"filtered-emails-" + index}>
												{parse(element)}
												<br />
											</span>
										</>
									);
								})}
							</div>

							<div>
								<button className="dismiss" onClick={() => setEmailModalShow(false)}>
									Cerrar
								</button>
							</div>
						</Row>
					</Col>
				</Row>
			</Modal>
			{(!isGenInPerson(gen.type) || (isGenInPerson(gen.type) && gen.limit > 0)) &&
				!loadingStudents && (
					<NewUserModal
						saveGen={saveGen}
						gen={gen}
						show={showAddStudentModal}
						onHide={() => setShowAddStudentModal(false)}
						setShowAddStudentModal={setShowAddStudentModal}
						showAddStudentModal={showAddStudentModal}
						user={user.user} // info on the collaborator opening the Modal
					/>
				)}
			<AsuetosModal show={asuetosModalShow} onHide={() => setAsuetosModalShow(false)} gen={gen} />
			<button onClick={() => goBack()} className="goback-btn">
				{"Atrás"}
			</button>
			<h2 style={{ color: "#ca5050", textAlign: "center" }}>
				{isGenInPerson(gen.type) ? "Generación presencial" : "Generación en línea"}
			</h2>
			<h2>{gen.name}</h2>
			{/* IMPROVE: Deberia separarse en su propio componente (EditGenCard por ejemplo)*/}
			<div className="edit-gen-card">
				<div className="edit-gen-card-inputs-container">
					<div className="inputs-containers">
						<label htmlFor="gen-name" style={{ fontWeight: 700 }}>
							Nombre de la generación
						</label>
						<div className="input-new-gen-container">
							<input
								className="input-new-gen"
								placeholder="Nombre de la generación"
								type="text"
								name="gen-name"
								id="gen-name"
								value={gen.name}
								onChange={(e) => setGen({ ...gen, name: e.target.value })}
							/>
						</div>
					</div>
					<div className="inputs-containers">
						<label htmlFor="initial-date" style={{ fontWeight: 700 }}>
							Fecha de inicio
						</label>
						<div className="input-new-gen-container">
							<InputMask
								required
								className="input-new-gen"
								type="text"
								name="initial-date"
								id="initial-date"
								value={gen.initialDate}
								onChange={(e) => setGen({ ...gen, initialDate: e.target.value })}
								placeholder="dd/MM/aaaa"
								mask="99/99/9999"
								maskChar=""
							></InputMask>
						</div>
					</div>
					<div className="inputs-containers">
						<label htmlFor="schedule" style={{ fontWeight: 700 }}>
							Horario de la generación
						</label>
						<div className="input-new-gen-container">
							<input
								className="input-new-gen"
								name="schedule"
								value={gen.schedule}
								id="schedule"
								type="text"
								onChange={(e) => setGen({ ...gen, schedule: e.target.value })}
								placeholder="Horario de la generación"
							/>
						</div>
					</div>
				</div>
				<div className="edit-gen-card-inputs-container">
					<div className="inputs-containers">
						<label htmlFor="gen-name" style={{ fontWeight: 700 }}>
							Sede
						</label>
						<div className="input-new-gen-container">
							<select
								name="sede"
								id="sede"
								value={gen.sede}
								onChange={(e) => setGen({ ...gen, sede: e.target.value })}
							>
								<option value="none">Selecciona una sede</option>
								{isGenOnline(gen.type) &&
									locationOptions?.map((e) => {
										if (e.type === "online") return e.element;
									})}
								{isGenInPerson(gen.type) &&
									locationOptions?.map((e) => {
										if (e.type === "presencial") return e.element;
									})}
							</select>
						</div>
					</div>
					<div className="inputs-containers">
						<label htmlFor="cirilic" style={{ fontWeight: 700 }}>
							Caracter cirílico
						</label>
						<div className="input-new-gen-container">
							<select
								className="input-new-gen"
								name="cirilic"
								id="cirilic"
								value={gen.cirilic ?? ""}
								onChange={(e) => setGen({ ...gen, cirilic: e.target.value })}
							>
								<option value="">Sin caracter</option>
								{uppercaseCyrillicCharacters.map((char) => {
									return (
										<option key={`Opcion - ${char}`} value={char}>
											{char}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div className="inputs-containers">
						<label htmlFor="hours-per-week" style={{ fontWeight: 700 }}>
							Horas semanales
						</label>
						<div className="input-new-gen-container">
							<input
								className="input-new-gen"
								type="number"
								name="hours-per-week"
								id="hours-per-week"
								value={gen.hoursPerWeek ?? 0}
								onChange={(e) => setGen({ ...gen, hoursPerWeek: parseInt(e.target.value) })}
							/>
						</div>
					</div>
					<div className="inputs-containers">
						<label htmlFor="total-hours" style={{ fontWeight: 700 }}>
							Horas totales
						</label>
						<div className="input-new-gen-container">
							<input
								className="input-new-gen"
								type="number"
								name="total-hours"
								id="total-hours"
								value={gen.totalHours ?? 0}
								onChange={(e) => setGen({ ...gen, totalHours: parseInt(e.target.value) })}
							/>
						</div>
					</div>

					{isGenInPerson(gen.type) && (
						<div className="inputs-containers">
							<label htmlFor="initial-date" style={{ fontWeight: 700 }}>
								Fecha final de promoción
							</label>
							<div className="input-new-gen-container">
								<InputMask
									required
									className="input-new-gen"
									type="text"
									name="initial-date"
									id="initial-date"
									value={gen.promoDate}
									onChange={(e) => setGen({ ...gen, promoDate: e.target.value })}
									placeholder="dd/MM/aaaa"
									mask="99/99/9999"
									maskChar=""
								></InputMask>
							</div>
						</div>
					)}
				</div>
				<div className="edit-gen-card-inputs-container">
					<div className="inputs-containers">
						<label htmlFor="classDay" style={{ fontWeight: 700 }}>
							Día de la semana
						</label>
						<div className="input-new-gen-container">
							<select
								className="input-new-gen"
								style={{ border: "none" }}
								value={gen.classDay}
								onChange={(e) => setGen({ ...gen, classDay: e.target.value })}
								name="classDay"
								id="classDay"
							>
								<option value="">Selecciona una opción</option>
								{weekDays.map((day, index) => {
									return (
										<option value={day} key={"week-day-option-" + index}>
											{day}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div className="inputs-containers">
						<label htmlFor="startHour" style={{ fontWeight: 700 }}>
							Hora de inicio
						</label>
						<div className="input-new-gen-container">
							<input
								className="input-new-gen"
								type="time"
								name="startHour"
								id="startHour"
								value={gen.startHour}
								onChange={(e) => setGen({ ...gen, startHour: e.target.value })}
							/>
						</div>
					</div>
					<div className="inputs-containers">
						<label htmlFor="endHour" style={{ fontWeight: 700 }}>
							Hora final
						</label>
						<div className="input-new-gen-container">
							<input
								className="input-new-gen"
								type="time"
								name="endHour"
								id="endHour"
								value={gen.endHour}
								onChange={(e) => setGen({ ...gen, endHour: e.target.value })}
							/>
						</div>
					</div>
				</div>
				<h4>Cantidad que se ve en la tabla de la página de inicio:</h4>
				<div className="edit-gen-card-inputs-container">
					<div className="inputs-containers">
						<label htmlFor="regular-price" style={{ fontWeight: 700 }}>
							Tarifa regular
						</label>
						<div className="input-new-gen-container">
							<input
								className="input-new-gen"
								type="number"
								name="regular-price"
								id="regular-price"
								value={gen.regularPrice ?? 0}
								onChange={(e) => setGen({ ...gen, regularPrice: parseInt(e.target.value) })}
							/>
						</div>
					</div>
					<div className="inputs-containers">
						<label htmlFor="promo-price" style={{ fontWeight: 700 }}>
							Tarifa promocional
						</label>
						<div className="input-new-gen-container">
							<input
								className="input-new-gen"
								type="number"
								name="promo-price"
								id="promo-price"
								value={gen.promoPrice ?? 0}
								onChange={(e) => setGen({ ...gen, promoPrice: parseInt(e.target.value) })}
							/>
						</div>
					</div>
					<div className="inputs-containers">
						<label htmlFor="regularPriceUSD" style={{ fontWeight: 700 }}>
							Tarifa regular (USD)
						</label>
						<div className="input-new-gen-container">
							<input
								className="input-new-gen"
								type="number"
								name="regularPriceUSD"
								id="regularPriceUSD"
								value={gen.regularPriceUSD ?? 0}
								onChange={(e) => setGen({ ...gen, regularPriceUSD: parseInt(e.target.value) })}
							/>
						</div>
					</div>
					<div className="inputs-containers">
						<label htmlFor="promoPriceUSD" style={{ fontWeight: 700 }}>
							Tarifa promocional (USD)
						</label>
						<div className="input-new-gen-container">
							<input
								className="input-new-gen"
								type="number"
								name="promoPriceUSD"
								id="promoPriceUSD"
								value={gen.promoPriceUSD ?? 0}
								onChange={(e) => setGen({ ...gen, promoPriceUSD: parseInt(e.target.value) })}
							/>
						</div>
					</div>
				</div>
				<div className="edit-gen-card-inputs-container">
					<div>
						<input
							id="active"
							name="active"
							type="checkbox"
							checked={gen.active}
							onChange={(e) => setGen({ ...gen, active: e.target.checked })}
						/>
						<label style={{ marginLeft: 10 }} htmlFor="active">
							Inscripción abierta.
						</label>
					</div>
					<div>
						<input
							id="countdownEnabledCheckBox"
							name="countdownEnabledCheckBox"
							type="checkbox"
							checked={gen.countdownEnabled}
							onChange={(e) => setGen({ ...gen, countdownEnabled: e.target.checked })}
						/>
						<label style={{ marginLeft: 10 }} htmlFor="countdownEnabledCheckBox">
							Cuenta regresiva.
						</label>
					</div>
					<div className="inputs-containers">
						<label htmlFor="countdownReference" style={{ fontWeight: 700 }}>
							Referencia de cuenta regresiva.
						</label>
						<div className="input-new-gen-container">
							<select
								name="countdownReference"
								id="countdownReference"
								value={countdownReference}
								onChange={handleCountdownReferenceValueChange}
							>
								<option value={CountdownReference.InitialGenerationDate}>
									Fecha inicial de generaciones
								</option>
								<option value={CountdownReference.EnrollmentLimitDate}>
									Fecha limite de inscripción
								</option>
							</select>
						</div>
					</div>
					{gen.type.indexOf("online") >= 0 && (
						<div className="input-row">
							<label>
								<b>Modalidad</b>
							</label>
							<DropdownButton
								alignRight
								title={selected}
								className="dropdown-container"
								id="dropdown-menu-align-right"
								onSelect={(eventKey) => handleSelectModality(eventKey)} // Manejar la selección
							>
								<Dropdown.Item eventKey={GenTypes.OnlineAM}>En línea matutino</Dropdown.Item>
								<Dropdown.Item eventKey={GenTypes.OnlinePM}>En línea vespertino</Dropdown.Item>
							</DropdownButton>
						</div>
					)}
				</div>
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						overflowX: "auto",
					}}
				>
					<button onClick={() => setAsuetosModalShow(true)}>Editar asuetos</button>
					<button style={{ marginLeft: "auto" }} onClick={saveGen} disabled={savingGeneration}>
						{!savingGeneration ? "Guardar" : <SpinnerCustom height={30} />}
					</button>
				</div>
			</div>

			{/*================table view: students and teachers =================*/}
			<div className="d-flex mb-4 table-view-btns-container" style={{ gap: 15 }}>
				<button
					className={"tertiary" + (tableView === "students" ? " active" : "")}
					onClick={() => setTableView("students")}
				>
					Estudiantes
				</button>
				<button
					className={"tertiary" + (tableView === "teachers" ? " active" : "")}
					onClick={() => setTableView("teachers")}
				>
					Profesores
				</button>
			</div>
			<div className="new-students">
				<p className="nuevo-ingreso">Estudiantes de nuevo ingreso: {newStudent}</p>
				<p className={studentFilter} onClick={studentFilterFunction}>
					Estudiantes en recursamiento: {oldStudent}
				</p>
			</div>
			{isGenInPerson(gen.type) && (
				<div className="d-flex justify-content-end gen-students-remaining-spaces-container">
					<h3 className="m-0">Espacios disponibles: {gen.limit}</h3>
				</div>
			)}
			{tableView === "students" ? (
				<>
					<label htmlFor="orderbyg">Ordenar por</label>
					<select
						style={{ borderRadius: 10, padding: 5, marginLeft: 5, marginBottom: 10 }}
						name="orderbyg"
						id="orderbyg"
						value={orderByg}
						onChange={(e) => handleSelected(e.target.value)}
					>
						<option value="addedAt">Reciente</option>
						<option value="name">Nombre</option>
						<option value="matricula">Matrícula</option>
						<option value="totalpay">Total pagado</option>
						<option value="activity">Última actividad</option>
					</select>
					<div
						className="edit-gen-card"
						style={{
							maxHeight: 600,
							padding: 0,
							paddingLeft: "25px",
							paddingRight: "25px",
							margin: 0,
						}}
					>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "center",
								position: "sticky",
								top: "0px",
								zIndex: "2",
								height: "65px",
								background: "white",
							}}
						>
							<div className="gen-search-by-name-input-container">
								<input
									type="text"
									onChange={handleOnChange}
									placeholder="Nombre"
									value={searchText}
								/>
							</div>
							<input
								style={{
									paddingLeft: "10px",
									borderRadius: "10px",
									height: "44px",
									width: "190px",
								}}
								type="text"
								placeholder={"Total Pagado"}
								value={parammeter}
								onChange={(e) => setParammeter(e.target.value)}
							/>
							<button
								className="emailGeneration"
								style={{ minWidth: "195px" }}
								onClick={() => GenerateEmail()}
							>
								Generar Informe
							</button>
							{(!isGenInPerson(gen.type) || (isGenInPerson(gen.type) && gen.limit > 0)) && (
								<button style={{ minWidth: "195px" }} onClick={() => setShowAddStudentModal(true)}>
									Nuevo estudiante
								</button>
							)}
						</div>
						{isGenInPerson(gen.type) && (
							<div className="mb-2 mt-3">
								<p className="m-0">
									<span style={{ color: "red" }}>*</span> Usuarios que se encuentran
									pre-registrados, pero que aún no crean una cuenta en la plataforma.
								</p>
							</div>
						)}
						{students.length > 0 && loadingStudents === false ? (
							<Table
								hover
								style={{
									position: "relative",
									overflowY: "scroll",
									maxHeight: "600px",
									overflowX: "scroll",
								}}
							>
								<thead
									style={{
										borderTop: "none",
										position: "sticky",
										top: "0px",
										backgroundColor: "white",
										width: "auto",
										height: "130px",
									}}
								>
									<tr style={{ borderTop: "none" }}>
										<th style={{ borderTop: "none" }}>Nombre</th>
										<th style={{ borderTop: "none" }}>Matrícula</th>
										<th style={{ borderTop: "none" }}>Correo</th>
										<th style={{ borderTop: "none" }}>Correo para grabaciones</th>
										<th
											style={{
												borderTop: "none",
												cursor: "pointer",
											}}
											onClick={() => handleAscDesc("activity")}
										>
											<div style={{ display: "flex", alignItems: "center" }}>
												Última Actividad{" "}
												{orderByg === "activity" &&
													(orderDirection === "asc" ? (
														<AiOutlineArrowDown />
													) : (
														<AiOutlineArrowUp />
													))}
											</div>
										</th>

										<th style={{ borderTop: "none", textAlign: "center" }}>1° Formulario</th>
										<th style={{ borderTop: "none", textAlign: "center" }}>2° Formulario</th>
										<th style={{ borderTop: "none", textAlign: "center" }}>Módulos pagados</th>
										<th
											style={{ borderTop: "none", textAlign: "center" }}
											onClick={() => handleAscDesc("totalpay")}
										>
											<div style={{ display: "flex", alignItems: "center" }} role="button">
												Total pagado{" "}
												{orderByg === "totalpay" &&
													(orderDirection === "asc" ? (
														<AiOutlineArrowDown />
													) : (
														<AiOutlineArrowUp />
													))}
											</div>
										</th>
										<th style={{ borderTop: "none" }}>Estatus</th>
										<th style={{ borderTop: "none" }}></th>
									</tr>
								</thead>
								<tbody>
									{students.map((student, index) => {
										//newStudents(students);
										return (
											<tr key={"row-sutent-" + index}>
												<td>
													{student.type === "pre-register" && (
														<span style={{ color: "red" }}>*</span>
													)}{" "}
													{student.name}
												</td>
												<td>{student.matricula}</td>
												<td>{student.email}</td>
												<td>{student.secondaryEmail ? student.secondaryEmail : "Sin registro"}</td>
												<td>
													{student.lastSeen !== undefined ? (
														<span>
															{new Date(student.lastSeen.seconds * 1000).toLocaleDateString(
																"en-gb"
															)}{" "}
															<br />{" "}
															{new Date(student.lastSeen.seconds * 1000).toLocaleTimeString(
																"en-US",
																{
																	hour: "2-digit",
																	minute: "2-digit",
																}
															)}
														</span>
													) : (
														"Sin registro"
													)}
												</td>{" "}
												<td style={{ textAlign: "center" }}>
													{student.formAnswered === true ? (
														<img src={CheckmarkIcon} alt="checkmark-icon" />
													) : (
														<img
															style={{ width: 24, height: 24 }}
															src={CloseCircle}
															alt="close-circle-icon"
														/>
													)}
												</td>
												<td style={{ textAlign: "center" }}>
													{student.formAnswered2 === true ? (
														<img src={CheckmarkIcon} alt="checkmark-icon" />
													) : (
														<img
															style={{ width: 24, height: 24 }}
															src={CloseCircle}
															alt="close-circle-icon"
														/>
													)}
												</td>
												<td style={{ textAlign: "center" }}>{student.paidModules}</td>
												<td style={{ textAlign: "center" }}>
													$
													{parseInt(student.totalPay)
														.toFixed(2)
														.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
												</td>
												<td
													style={
														student.status === "Pago completo"
															? { color: "#02A377", fontWeight: 500 }
															: { color: "#CA5050", fontWeight: 500 }
													}
												>
													{student.status}
												</td>
												<td>
													<img
														src={EditIcon}
														alt="edit-icon"
														style={{ cursor: "pointer" }}
														onClick={() => seeStudent(student)}
													/>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						) : (
							<h2>No hay ninguno</h2>
						)}
					</div>
				</>
			) : (
				<Teachers teachers={teachers} setShowAddProfessorModal={setShowAddProfessorModal} />
			)}
			{student !== null && (
				<UserProfileModal
					key={student.id}
					user={student}
					show={userProfileModal}
					onHide={() => setUserProfileModal(false)}
					updateGDT={checkGen}
					collaborator={user.user}
				/>
			)}
		</div>
	);
}
