import { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
	UploadImage,
	addGalleries,
	deleteGallery,
	retreiveGalleries,
	updateGalleries,
	updateGallerySettings,
} from "@services/GalleriesService";
import toast, { Toaster } from "react-hot-toast";
import "./Galleries.scss";
import { IGalleries, IGallerySettings } from "interfaces/Interfaces";
import { IoMdClose } from "react-icons/io";
import { SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import { number, object } from "yup";
import { AiOutlineDrag } from "react-icons/ai";
const initialValues = {
	galleries: [] as Array<IGalleries>,
	interval: "" as number | "",
};
const schema = object({
	interval: number()
		.required("El intervalo es requerido")
		.min(1, "Tiene que ser mínimo 1 segundo")
		.max(10, "Tiene que ser máximo 10 segundos"),
});
export const Galleries = () => {
	const [loader, setLoader] = useState<boolean>(false);
	const [oldGalleries, setOldGalleries] = useState<Array<IGalleries>>([]);
	const emptyGallery = {
		title: "",
		image: "",
		displayImage: "",
		imageResponsive: "",
		displayImageResponsive: "",
		order: 0,
	};
	const handleDragStart = (event, id) => {
		event.dataTransfer.setData("text/plain", id);
	};
	const handleDragOver = (e: any) => {
		e.preventDefault();
	};

	const handleDrop = (e: any, newIndex: number) => {
		e.preventDefault();
		const oldIndex = e.dataTransfer.getData("text/plain");
		const newItems = [...galleries];
		const [draggedItem] = newItems.splice(oldIndex, 1);
		newItems.splice(newIndex, 0, draggedItem);
		const galls = newItems.map((gall, i) => {
			return {
				...gall,
				order: i + 1,
			};
		});
		setFieldValue("galleries", galls);
	};
	const getImage = (file: any) => {
		return new Promise((resolve, reject) => {
			if (file.length > 0) {
				var reader = new FileReader();
				reader.readAsDataURL(file[0]);
				reader.onload = (_event) => {
					resolve(reader.result);
				};
			}
		});
	};
	const onRetreiveGalleries = async () => {
		try {
			const data = await retreiveGalleries();
			const { galleries, settings } = data;
			const { interval } = settings;
			setOldGalleries([...galleries]);
			setValues({ galleries, interval });
		} catch (error) {
			console.error(error);
		}
	};
	const removeGallery = (index: number) => {
		if (galleries.length < 3) {
			toast.error("Deben ser dos galerías mínimo");
			return;
		}
		let tempGallery = [...galleries];
		tempGallery.splice(index, 1);
		tempGallery.map((gall, i) => {
			gall.order = i + 1;
		});
		setFieldValue("galleries", tempGallery);
	};
	const addGallery = () => {
		if (galleries.length > 9) {
			toast.error("Deben ser diez galerías máximo");
			return;
		}
		let tempGallery = [...galleries];
		tempGallery.push({ ...emptyGallery, order: tempGallery.length + 1 });
		setFieldValue("galleries", tempGallery);
	};
	const changeTitle = (value: string, index: number) => {
		let tempGallery = [...galleries];
		tempGallery[index].title = value;
		setFieldValue("galleries", tempGallery);
	};
	const changeImage = async (event, i) => {
		let tempGallery = [...galleries];
		const selectedFile = event.target.files[0];
		if (selectedFile) {
			tempGallery[i].image = selectedFile;
			tempGallery[i].displayImage = (await getImage(event.target.files)) as any;
		}
		setFieldValue("galleries", tempGallery);
	};
	const changeImageResponsive = async (event, i) => {
		let tempGallery = [...galleries];
		const selectedFile = event.target.files[0];
		if (selectedFile) {
			tempGallery[i].imageResponsive = selectedFile;
			tempGallery[i].displayImageResponsive = (await getImage(event.target.files)) as any;
		}
		setFieldValue("galleries", tempGallery);
	};
	const changeImageUSD = async (event, i) => {
		let tempGallery = [...galleries];
		const selectedFile = event.target.files[0];
		if (selectedFile) {
			tempGallery[i].imageUSD = selectedFile;
			tempGallery[i].displayImageUSD = (await getImage(event.target.files)) as any;
		}
		setFieldValue("galleries", tempGallery);
	};
	const changeImageResponsiveUSD = async (event, i) => {
		let tempGallery = [...galleries];
		const selectedFile = event.target.files[0];
		if (selectedFile) {
			tempGallery[i].imageResponsiveUSD = selectedFile;
			tempGallery[i].displayImageResponsiveUSD = (await getImage(event.target.files)) as any;
		}
		setFieldValue("galleries", tempGallery);
	};
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: schema,
		onSubmit: async (values) => {
			setLoader(true);
			try {
				let checkEmptyElements = false;
				if (values.interval === "") {
					toast.error("El Intervalo debe tener un valor");
					setLoader(false);
					return;
				}
				values.galleries.map((gall) => {
					if (
						Object.entries(gall)
							.filter(([key, value]) => key !== "imageUSD" && key !== "imageResponsiveUSD")
							.some(([key, value]) => value === "")
					) {
						checkEmptyElements = true;
					}
				});
				if (checkEmptyElements) {
					toast.error("No puede haber elementos vacios");
					setLoader(false);
					return;
				}
				const deleteGalleries: Array<IGalleries> = [];
				oldGalleries.map((oldGall) => {
					let check = false;
					values.galleries.map((gall) => {
						if (oldGall.id === gall.id) {
							check = true;
						}
					});
					if (check === false) {
						deleteGalleries.push(oldGall);
					}
				});
				await Promise.all(
					values.galleries.map(async (gall) => {
						if (gall.id) {
							const body = {
								...gall,
							};
							if (gall.displayImage) {
								body.image = await UploadImage(gall.image);
							}
							if (gall.displayImageResponsive) {
								body.imageResponsive = await UploadImage(gall.imageResponsive);
							}
							if (gall.displayImageUSD) {
								body.imageUSD = await UploadImage(gall.imageUSD);
							}
							if (gall.displayImageResponsiveUSD) {
								body.imageResponsiveUSD = await UploadImage(gall.imageResponsiveUSD);
							}
							delete body.displayImage;
							delete body.displayImageResponsive;
							delete body.displayImageUSD;
							delete body.displayImageResponsiveUSD;
							const update = await updateGalleries(body);
						} else {
							const body = {
								...gall,
							};
							body.image = await UploadImage(gall.image);
							body.imageResponsive = await UploadImage(gall.imageResponsive);
							body.imageUSD = await UploadImage(gall.imageUSD);
							body.imageResponsiveUSD = await UploadImage(gall.imageResponsiveUSD);
							delete body.displayImage;
							delete body.displayImageResponsive;
							delete body.displayImageUSD;
							delete body.displayImageResponsiveUSD;
							const check = await addGalleries(body);
						}
					})
				);
				await updateGallerySettings(values.interval);
				await Promise.all(
					deleteGalleries.map(async (gall) => {
						await deleteGallery(gall);
					})
				);
				onRetreiveGalleries();
				toast.success("Gallerias actualizadas");
			} catch (error) {
				console.error(error);
			}
			setLoader(false);
		},
	});
	const {
		setFieldValue,
		handleChange,
		touched,
		errors,
		setValues,
		values: { galleries, interval },
	} = formik;
	useEffect(() => {
		onRetreiveGalleries();
	}, []);
	return (
		<div className="galleries-main-contain">
			<Toaster />
			<div className="header">
				<h1>Galerías</h1>
				{loader ? (
					<div style={{ width: 250 }}>
						<SpinnerCustom height={45} />
					</div>
				) : (
					<button onClick={() => formik.handleSubmit()} type="submit" className="finish">
						Guardar Cambios
					</button>
				)}
			</div>
			<div className="button-ctn">
				<div className="input-container">
					<label>Intervalo (segundos)</label>
					<input
						type="number"
						placeholder="Intervalo"
						value={interval}
						id="interval"
						name="interval"
						onChange={handleChange}
					/>
					{touched.interval && errors.interval && <p className="error">{errors.interval}</p>}
				</div>
				<button onClick={addGallery}>Agregar Galería</button>
			</div>
			{galleries.length === 0 ? (
				<p>Sin galerias...</p>
			) : (
				<div className="gallery-content">
					{galleries.map((gall, i) => {
						return (
							<div
								key={i + "galleries"}
								className="single-gallery"
								draggable="true"
								onDragStart={(e) => handleDragStart(e, i)}
								onDragOver={handleDragOver}
								onDrop={(e) => handleDrop(e, i)}
							>
								<div className="gall-header">
									<IoMdClose
										onClick={() => {
											removeGallery(i);
										}}
									/>
									<h2>Galería {i + 1}</h2>
									<AiOutlineDrag className="drag-icon" />
								</div>
								<input
									type="text"
									placeholder="Nombre de galeria"
									value={gall.title}
									onChange={(e) => {
										changeTitle(e.target.value, i);
									}}
								/>
								<label>
									Seleccionar Imagen Desktop
									<input
										type="file"
										style={{ display: "none" }}
										onChange={(e) => changeImage(e, i)}
									/>
									{gall.image !== "" &&
										(gall.displayImage ? (
											<img src={gall.displayImage} />
										) : (
											<img src={gall.image} />
										))}
								</label>
								<label>
									Seleccionar Imagen Responsive
									<input
										type="file"
										style={{ display: "none" }}
										onChange={(e) => changeImageResponsive(e, i)}
									/>
									{gall.imageResponsive !== "" &&
										(gall.displayImageResponsive ? (
											<img src={gall.displayImageResponsive} />
										) : (
											<img src={gall.imageResponsive} />
										))}
								</label>
								<label>
									Seleccionar Imagen Desktop (USD)
									<input
										type="file"
										style={{ display: "none" }}
										onChange={(e) => changeImageUSD(e, i)}
									/>
									{gall.imageUSD &&
										gall.imageUSD !== "" &&
										(gall.displayImageUSD ? (
											<img src={gall.displayImageUSD} />
										) : (
											<img src={gall.imageUSD} />
										))}
								</label>
								<label>
									Seleccionar Imagen Responsive (USD)
									<input
										type="file"
										style={{ display: "none" }}
										onChange={(e) => changeImageResponsiveUSD(e, i)}
									/>
									{gall.imageResponsiveUSD &&
										gall.imageResponsiveUSD !== "" &&
										(gall.displayImageResponsiveUSD ? (
											<img src={gall.displayImageResponsiveUSD} />
										) : (
											<img src={gall.imageResponsiveUSD} />
										))}
								</label>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
