import {
	img1,
	img10,
	img11,
	img12,
	img13,
	img14,
	img15,
	img16,
	img17,
	img18,
	img19,
	img2,
	img20,
	img21,
	img22,
	img23,
	img24,
	img25,
	img26,
	img27,
	img28,
	img29,
	img3,
	img30,
	img31,
	img32,
	img33,
	img34,
	img35,
	img36,
	img37,
	img38,
	img39,
	img4,
	img40,
	img5,
	img6,
	img7,
	img8,
	img9,
	Logo,
	img41,
	img42,
	img43,
	img44,
	img45,
	img46,
	img47,
	img48,
	img49,
	img50,
	img51,
	img52,
	img53,
	img54,
	img55,
	img56,
	img57,
	img58,
	img59,
	img60,
	FranceFlagSVG,
	ItalyFlagSVG,
	RussiaFlagSVG,
	JapanFlagSVG,
	ChinaFlagSVG,
	UnitedStatesFlagSVG,
	BrazilFlagSVG,
	GermanyFlagSVG,
	MexicoFlavSVG,
} from "assets/Imports";

import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "initFirebase";

export const manageError = (error) => {
	console.error(error);
	alert("Ha habido un error de conexión.");
};

export function romanize(num) {
	if (isNaN(num)) return NaN;
	if (parseInt(num) === 0) return "Zero";
	var digits: any = String(+num).split(""),
		key = [
			"",
			"C",
			"CC",
			"CCC",
			"CD",
			"D",
			"DC",
			"DCC",
			"DCCC",
			"CM",
			"",
			"X",
			"XX",
			"XXX",
			"XL",
			"L",
			"LX",
			"LXX",
			"LXXX",
			"XC",
			"",
			"I",
			"II",
			"III",
			"IV",
			"V",
			"VI",
			"VII",
			"VIII",
			"IX",
		],
		roman = "",
		i = 3;
	while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
	return Array(+digits.join("") + 1).join("M") + roman;
}

enum Sedes {
	Narvarte = "Narvarte, CDMX",
}

export function sortOrderAsc(a, b) {
	if (a.order < b.order) {
		return -1;
	}
	if (a.order > b.order) {
		return 1;
	}
	return 0;
}

export function getAge(birthdate: string) {
	let dateArray = birthdate.split("-");
	const date = new Date();
	date.setFullYear(parseInt(dateArray[0]));
	date.setMonth(parseInt(dateArray[1]) - 1);
	date.setDate(parseInt(dateArray[2]));

	let difference = calculateAge(date);

	return (
		(date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
		"/" +
		(date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) +
		"/" +
		date.getFullYear().toString().slice(2, 4) +
		" (" +
		difference +
		")"
	);
}

function calculateAge(birthday) {
	// birthday is a date
	var ageDifMs = Date.now() - birthday;
	var ageDate = new Date(ageDifMs); // miliseconds from epoch
	return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function getModuleNumber(sesionNumber) {
	if (sesionNumber > 0 && sesionNumber <= 10) {
		return "I";
	} else if (sesionNumber > 10 && sesionNumber <= 25) {
		return "II";
	} else if (sesionNumber > 25 && sesionNumber <= 40) {
		return "III";
	} else if (sesionNumber > 40 && sesionNumber <= 50) {
		return "IV";
	} else if (sesionNumber > 50 && sesionNumber <= 60) {
		return "V";
	}
}

export async function getMatricula(num, sede, splitGenDate) {
	const date = new Date();
	date.setDate(splitGenDate[0]);
	date.setMonth(splitGenDate[1] - 1);
	date.setFullYear(splitGenDate[2]);
	let year = "" + date.getFullYear();
	let matricula = "";

	if (num < 10) {
		if (date.getMonth() < 9) {
			matricula =
				year[2] +
				"" +
				year[3] +
				"0" +
				(date.getMonth() + 1) +
				(date.getDate() < 10 ? "0" : "") +
				date.getDate() +
				"00" +
				num;
		} else {
			matricula =
				year[2] +
				"" +
				year[3] +
				"" +
				(date.getMonth() + 1) +
				(date.getDate() < 10 ? "0" : "") +
				date.getDate() +
				"00" +
				num;
		}
	} else if (num >= 10 && num < 100) {
		if (date.getMonth() < 9) {
			matricula =
				year[2] +
				"" +
				year[3] +
				"0" +
				(date.getMonth() + 1) +
				(date.getDate() < 10 ? "0" : "") +
				date.getDate() +
				"0" +
				num;
		} else {
			matricula =
				year[2] +
				"" +
				year[3] +
				"" +
				(date.getMonth() + 1) +
				(date.getDate() < 10 ? "0" : "") +
				date.getDate() +
				"0" +
				num;
		}
	} else {
		if (date.getMonth() < 9) {
			matricula =
				year[2] +
				"" +
				year[3] +
				"0" +
				(date.getMonth() + 1) +
				(date.getDate() < 10 ? "0" : "") +
				date.getDate() +
				"" +
				num;
		} else {
			matricula =
				year[2] +
				"" +
				year[3] +
				"" +
				(date.getMonth() + 1) +
				(date.getDate() < 10 ? "0" : "") +
				date.getDate() +
				"" +
				num;
		}
	}

	let isDifferent = false;
	let matriculaS = (sede === Sedes.Narvarte ? "N" : "Z") + matricula;

	do {
		const q0 = query(collection(db, "users"), where("matricula", "==", matriculaS));
		const querySnapshot0 = await getDocs(q0);
		if (querySnapshot0.size > 0) {
			matricula = "" + (parseInt(matricula) + 1);
			if (sede === Sedes.Narvarte) {
				matriculaS = "N" + matricula;
			} else {
				matriculaS = "Z" + matricula;
			}
		} else {
			isDifferent = true;
		}
	} while (isDifferent === false);

	console.log(matriculaS);
	return matriculaS;
}

export function getPayDate(materialDate) {
	let stringSplit = materialDate.split("/");
	const dateN = new Date();
	dateN.setDate(stringSplit[0]);
	dateN.setMonth(stringSplit[1]);
	dateN.setFullYear(stringSplit[2]);
	dateN.setDate(dateN.getDate() - 3);
	stringSplit[0] = dateN.getDate();
	stringSplit[1] = dateN.getMonth();
	stringSplit[2] = dateN.getFullYear();
	let month = "";
	switch (parseInt(stringSplit[1])) {
		case 0:
			month = "enero";
			break;
		case 1:
			month = "febrero";
			break;
		case 2:
			month = "marzo";
			break;
		case 3:
			month = "abril";
			break;
		case 4:
			month = "mayo";
			break;
		case 5:
			month = "junio";
			break;
		case 6:
			month = "julio";
			break;
		case 7:
			month = "agosto";
			break;
		case 8:
			month = "septiembre";
			break;
		case 9:
			month = "octubre";
			break;
		case 10:
			month = "noviembre";
			break;
		case 11:
			month = "diciembre";
			break;
	}

	let date = stringSplit[0] + " de " + month + " de " + stringSplit[2];
	return date;
}

export function getDate(materialDate) {
	let stringSplit = materialDate.split("/");
	let month = "";
	switch (parseInt(stringSplit[1])) {
		case 0:
			month = "enero";
			break;
		case 1:
			month = "febrero";
			break;
		case 2:
			month = "marzo";
			break;
		case 3:
			month = "abril";
			break;
		case 4:
			month = "mayo";
			break;
		case 5:
			month = "junio";
			break;
		case 6:
			month = "julio";
			break;
		case 7:
			month = "agosto";
			break;
		case 8:
			month = "septiembre";
			break;
		case 9:
			month = "octubre";
			break;
		case 10:
			month = "noviembre";
			break;
		case 11:
			month = "diciembre";
			break;
	}

	if (stringSplit[0][0] === "0") {
		let temp = stringSplit[0].split("");
		temp.splice(0, 1);

		stringSplit[0] = temp.pop();
	}

	let date = stringSplit[0] + " de " + month + " de " + stringSplit[2];
	return date;
}

export function secondsToHms(d) {
	d = Number(d);
	var h = Math.floor(d / 3600);
	var m = Math.floor((d % 3600) / 60);
	var s = Math.floor((d % 3600) % 60);

	var hDisplay = h > 0 ? h + (h == 1 ? ":" : ":") : "";
	var mDisplay = m > 0 ? m + (m == 1 ? ":" : ":") : "";
	var sDisplay = s > 0 ? (s > 0 && s < 10 ? "0" + s : s) + (s == 1 ? ":" : "") : "";
	return hDisplay + mDisplay + sDisplay;
}

export function getInitials(name: string) {
	const initials = name.split(" ");
	let fullInitials = "";

	initials.forEach((word) => {
		if (word !== "") {
			fullInitials += word[0];
		}
	});

	return fullInitials[0] + "" + (fullInitials.length > 1 ? fullInitials[1] : "");
}

export function getFileName(name) {
	let nameSplit = name.split(".pdf");
	return nameSplit[0];
}

export function randomIntFromInterval(min, max) {
	// min and max included
	return Math.floor(Math.random() * (max - min + 1) + min);
}

export function capitalizeFirstLetter(string: string) {
	if (string) {
		const str = string;
		const str2 = str.charAt(0).toUpperCase() + str.slice(1);

		return str2;
	} else {
		return string;
	}
}

export function returnFlag(language) {
	switch (language) {
		case "french":
			return <img src={FranceFlagSVG} alt="bandera de francia" />;
			break;

		case "german":
			return <img src={GermanyFlagSVG} alt="bandera de alemania" />;
			break;

		case "japanese":
			return <img src={JapanFlagSVG} alt="bandera de japon" />;
			break;

		case "chinese":
			return <img src={ChinaFlagSVG} alt="bandera de chino" />;
			break;

		case "russian":
			return <img src={RussiaFlagSVG} alt="bandera de rusia" />;
			break;

		case "portuguese":
			return <img src={BrazilFlagSVG} alt="bandera de brasil" />;
			break;

		case "english":
			return <img src={UnitedStatesFlagSVG} alt="bandera de estados unidos" />;
			break;

		case "italian":
			return <img src={ItalyFlagSVG} alt="bandera de italia" />;
			break;

		default:
			break;
	}
}

export function returnFlagByLanguage(language: string) {
	switch (language) {
		case "french":
			return <img src={FranceFlagSVG} alt="bandera de francia" />;
			break;

		case "german":
			return <img src={GermanyFlagSVG} alt="bandera de alemania" />;
			break;

		case "japanese":
			return <img src={JapanFlagSVG} alt="bandera de japon" />;
			break;

		case "chinese":
			return <img src={ChinaFlagSVG} alt="bandera de chino" />;
			break;

		case "russian":
			return <img src={RussiaFlagSVG} alt="bandera de rusia" />;
			break;

		case "portuguese":
			return <img src={BrazilFlagSVG} alt="bandera de brasil" />;
			break;

		case "english":
			return <img src={UnitedStatesFlagSVG} alt="bandera de estados unidos" />;
			break;

		case "italian":
			return <img src={ItalyFlagSVG} alt="bandera de italia" />;
			break;
		case "spanish":
			return <img src={MexicoFlavSVG} alt="bandera de mexico" />;
			break;

		default:
			break;
	}
}

export function normalizeWord(word: string) {
	return word
		.normalize("NFD")
		.replace(/[\u0300-\u036f\s!¡?¿"]/g, "")
		.replace(/"/g, "")
		.replace(/ *\([^)]*\) */g, "")
		.toLocaleLowerCase()
		.trim();
}

export function getImage(num: number) {
	switch (num) {
		case 0:
			return Logo;
			break;
		case 1:
			return img1;
			break;
		case 2:
			return img2;
			break;
		case 3:
			return img3;
			break;
		case 4:
			return img4;
			break;
		case 5:
			return img5;
			break;
		case 6:
			return img6;
			break;
		case 7:
			return img7;
			break;
		case 8:
			return img8;
			break;
		case 9:
			return img9;
			break;
		case 10:
			return img10;
			break;
		case 11:
			return img11;
			break;
		case 12:
			return img12;
			break;
		case 13:
			return img13;
			break;
		case 14:
			return img14;
			break;
		case 15:
			return img15;
			break;
		case 16:
			return img16;
			break;
		case 17:
			return img17;
			break;
		case 18:
			return img18;
			break;
		case 19:
			return img19;
			break;
		case 20:
			return img20;
			break;
		case 21:
			return img21;
			break;
		case 22:
			return img22;
			break;
		case 23:
			return img23;
			break;
		case 24:
			return img24;
			break;
		case 25:
			return img25;
			break;
		case 26:
			return img26;
			break;
		case 27:
			return img27;
			break;
		case 28:
			return img28;
			break;
		case 29:
			return img29;
			break;
		case 30:
			return img30;
			break;
		case 31:
			return img31;
			break;
		case 32:
			return img32;
			break;
		case 33:
			return img33;
			break;
		case 34:
			return img34;
			break;
		case 35:
			return img35;
			break;
		case 36:
			return img36;
			break;
		case 37:
			return img37;
			break;
		case 38:
			return img38;
			break;
		case 39:
			return img39;
			break;
		case 40:
			return img40;
			break;
		case 41:
			return img41;
			break;
		case 42:
			return img42;
			break;
		case 43:
			return img43;
			break;
		case 44:
			return img44;
			break;
		case 45:
			return img45;
			break;
		case 46:
			return img46;
			break;
		case 47:
			return img47;
			break;
		case 48:
			return img48;
			break;
		case 49:
			return img49;
			break;
		case 50:
			return img50;
			break;
		case 51:
			return img51;
			break;
		case 52:
			return img52;
			break;
		case 53:
			return img53;
			break;
		case 54:
			return img54;
			break;
		case 55:
			return img55;
			break;
		case 56:
			return img56;
			break;
		case 57:
			return img57;
			break;
		case 58:
			return img58;
			break;
		case 59:
			return img59;
			break;
		case 60:
			return img60;
			break;
		default:
			break;
	}
}
