import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "./styles.scss";
import {
	FacebookAuthProvider,
	getAuth,
	GoogleAuthProvider,
	signInWithEmailAndPassword,
	signInWithPopup,
	sendPasswordResetEmail,
} from "firebase/auth";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "@hooks/useAuth";
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db, functions } from "initFirebase";
import firebase from "firebase/compat/app";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/mobile";
import es from "react-phone-number-input/locale/es.json";
import emailjs from "emailjs-com";
import logoHD from "../../screens/mobileAboutApp/screenIMG/image 3.png";
import { Image } from "react-bootstrap";
import { GoogleIcon, Rectangle15, Star, Students, Torre, Vector } from "assets/Imports";
import { getCurrentAnniversary } from "@utils/utilFunctions";
import { preRegisterUser, registerUser } from "./utils/processRegisterWithGoogle";
import { HttpsCallableResult, httpsCallable } from "firebase/functions";
import { StripeResponse } from "interfaces/Stripe/Stripe";

function LoginContent() {
	const [validated, setValidated] = useState(false);
	const [loadingLogin, setLoadingLogin] = useState(false);
	const [loadingLoginGoogle, setLoadingLoginGoogle] = useState(false);
	const [loadingLoginFb, setLoadingLoginFb] = useState(false);
	const [loginMethod, setLoginMethod] = useState("");
	const [error, setError] = useState("");
	const history = useHistory();
	const [show, setShow] = useState(false);
	const [showPhone, setShowPhone] = useState(false);
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleClosePhone = () => setShowPhone(false);
	const handleShowPhone = () => setShowPhone(true);

	const user = useAuth().user;

	//Esta funcion valida que el usuario tenga una coleccion en la base de datos, si es asi, se redirige al usuario al apartado de su perfil
	//esto previene el error que pasaba al momento de registrarse con google, que redirigia sin crear la coleccion.
	const validateUserToRedirect = async () => {
		const docRef = doc(db, "users", user.id);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			history.push("/micuenta");
		}
	};

	useEffect(() => {
		if (user !== null) {
			validateUserToRedirect();
		}
	}, []);

	const handleSubmit = (e) => {
		setLoadingLogin(true);
		const form = e.currentTarget;
		e.preventDefault();

		if (form.checkValidity() === false) {
			e.stopPropagation();
			setValidated(true);
			setLoadingLogin(false);
		} else {
			const auth = getAuth();
			signInWithEmailAndPassword(auth, form.email.value, form.password.value)
				.then(async (userCredential) => {
					history.push("/micuenta/historial-de-pagos");
				})
				.catch((error) => {
					setLoadingLogin(false);
					const errorCode = error.code;
					const errorMessage = error.message;
					if (
						errorCode === "auth/invalid-email" ||
						errorCode === "auth/wrong-password" ||
						errorCode === "auth/user-not-found"
					) {
						alert(
							"El correo y/o la contraseña ingresados no son válidos o el correo se encuentra registrado con Google o Facebook."
						);
					} else if (errorCode === "auth/email-already-in-use") {
						alert("El correo ya se encuentra registrado");
					} else if (errorCode === "auth/weak-password") {
						alert("El correo debe de contener un mínimo de 6 caracteres.");
					}
					// ..
				});
		}
	};

	const handleLoginWithPhone = () => {
		if (phone === "") {
			alert("Por favor ingresa un numero de celular.");
		} else {
			if (isPossiblePhoneNumber(phone) === true) {
				if (loginMethod === "google") {
					handleClosePhone();
					handleGoogleSignIn();
				} else {
					handleClosePhone();
					handleFacebookSignIn();
				}
			} else {
				alert("Ingresa un número valido de celular.");
			}
		}
	};

	const handleGoogleSignIn = () => {
		setLoadingLoginGoogle(true);
		const provider = new GoogleAuthProvider();

		const auth = getAuth();
		signInWithPopup(auth, provider)
			.then(async (result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential = GoogleAuthProvider.credentialFromResult(result);
				const token = credential!.accessToken;
				// The signed-in user info.
				const user = result.user;
				const docRef = doc(db, "users", user.uid);
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					if (docSnap.data().userType === 2 || docSnap.data().userType === 4) {
						history.push("/admin");
					} else {
						history.push("/micuenta/historial-de-pagos");
					}
				} else {
					// doc.data() will be undefined in this case
					if (phone === "") {
						setLoginMethod("google");
						setLoadingLoginGoogle(false);
						handleShowPhone();
					} else {
						let conektaRegister = await firebase.functions().httpsCallable("createCustomerConekta");

						conektaRegister({
							name:
								user.displayName !== null
									? user.displayName.replace(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, "")
									: "usuario registrado",
							email: user.email,
							phone: phone,
						})
							.then(async (res) => {
								const conektaData = res.data;
								const q = query(
									collection(db, "users"),
									where("email", "==", res.data.email.trim()),
									where("type", "==", "pre-register")
								);

								const snap = await getDocs(q);

								const existingUser = snap.docs[0];

								type PaymentCustomerData = { name: string; email: string; phone: string };
								let stripeData: any;
								const userData = {
									name: res.data.name,
									email: res.data.email.trim(),
									phone: res.data.phone,
								};

								const getStripeCustomer = httpsCallable<PaymentCustomerData, StripeResponse>(
									functions,
									"getStripeCustomer"
								);
								const stripeCustomerResponse: HttpsCallableResult<StripeResponse> =
									await getStripeCustomer(res.data.email);

								if (!stripeCustomerResponse.data) {
									const createStripeCustomer = httpsCallable<PaymentCustomerData, StripeResponse>(
										functions,
										"createStripeCustomer"
									);
									const stripeResponse: HttpsCallableResult<StripeResponse> =
										await createStripeCustomer(userData);
									stripeData = stripeResponse?.data;
								}
								switch (snap.size) {
									case 1:
										preRegisterUser(
											docRef,
											conektaData,
											user,
											db,
											existingUser,
											history,
											stripeData,
											stripeCustomerResponse
										);
										break;
									default:
										registerUser(
											res,
											user,
											existingUser?.data()?.userType,
											db,
											history,
											stripeData,
											stripeCustomerResponse
										);
										break;
								}
							})
							.catch((error) => {
								console.error(error);
								alert("Hubo un error de conexión.");
							});
					}
				}
			})
			.catch((error) => {
				setLoadingLoginGoogle(false);
				console.error(error);
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.email;
				// The AuthCredential type that was used.
				const credential = GoogleAuthProvider.credentialFromError(error);
				// ...
			});
	};

	const handleFacebookSignIn = () => {
		setLoadingLoginFb(true);
		const provider = new FacebookAuthProvider();

		const auth = getAuth();
		signInWithPopup(auth, provider)
			.then(async (result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential = FacebookAuthProvider.credentialFromResult(result);
				const token = credential!.accessToken;
				// The signed-in user info.
				const user = result.user;

				const docRef = doc(db, "users", user.uid);
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					history.push("/micuenta/historial-de-pagos");
				} else {
					if (phone === "") {
						setLoginMethod("facebook");
						setLoadingLoginFb(false);
						handleShowPhone();
					} else {
						let conektaRegister = await firebase.functions().httpsCallable("createCustomerConekta");
						conektaRegister({
							name: user.displayName,
							email: user.email,
							phone: phone,
						})
							.then(async (res) => {
								await setDoc(doc(db, "users", user.uid), {
									name: res.data.name,
									email: res.data.email,
									phone: res.data.phone,
									conektaId: res.data.id,
									generationId: "",
									matricula: "",
									schedule: "",
									promotion: false,
									formAnswered: false,
									color: randDarkColor(),
								});
								const data = {
									name: res.data.name,
									email: res.data.email,
								};
								await emailjs
									.send("service_kznboar", "template_r303fk1", data, "user_PPI0O8nofs9cbuJ3JRWlT")
									.then(
										function (res) {},
										function (error) {
											console.error(error);
										}
									);
								history.push("/micuenta/historial-de-pagos");
							})
							.catch((error) => {
								console.error(error);
								alert("Hubo un error de conexión.");
							});
					}
					// doc.data() will be undefined in this case
				}
			})
			.catch((error) => {
				setLoadingLoginFb(false);
				// Handle Errors here.
				console.log(error.code);
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.email;
				// The AuthCredential type that was used.
				const credential = FacebookAuthProvider.credentialFromError(error);
				// ...
				setError(errorCode);
			});
	};

	const handleChange = (e) => {
		setEmail(e.target.value);
	};

	const handlePhone = (e) => {
		if (e === undefined) {
			setPhone("");
		} else {
			setPhone(e);
		}
	};

	const handleEmailSent = () => {
		let re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (re.test(email)) {
			const auth = getAuth();
			sendPasswordResetEmail(auth, email)
				.then(() => {
					// Password reset email sent!
					alert("Correo enviado con éxito.");
					setEmail("");
					handleClose();
					// ..
				})
				.catch((error) => {
					const errorCode = error.code;
					if (error.code === "auth/user-not-found") {
						alert("No se encontró un usuario registrado con ese correo.");
					}
					const errorMessage = error.message;
					// ..
				});
		} else {
			alert("Ingresa un email valido.");
		}
	};

	function randDarkColor() {
		var lum = -0.25;
		var hex = String("#" + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(
			/[^0-9a-f]/gi,
			""
		);
		if (hex.length < 6) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		var rgb = "#",
			c,
			i;
		for (i = 0; i < 3; i++) {
			c = parseInt(hex.substr(i * 2, 2), 16);
			c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
			rgb += ("00" + c).substr(c.length);
		}
		return rgb;
	}

	return (
		<div className="login-main-container">
			<div className="login-header-container">
				<h2 style={{ position: "absolute" }}>Ingresar</h2>
				<img src={Rectangle15} alt="Logo" className="wave" style={{ position: "static" }} />
				<img
					src={Vector}
					alt="Logo"
					className="wave wave-2-login"
					style={{ position: "static", marginTop: -10 }}
				/>
			</div>
			<div className="login-content-container">
				<div className="login-students-image-container">
					<img src={Torre} alt="Torre eiffel" className="torre-image" />
					<img src={Students} alt="Students" />
				</div>

				<div className="login-form-container">
					<Form noValidate validated={validated} onSubmit={handleSubmit}>
						<div
							className="android-header-container"
							style={{
								paddingTop: "10px",
								marginBottom: 20,
								padding: 20,
								boxShadow: "0px 3.93189px 12.7786px rgba(0, 0, 0, 0.19)",
								borderRadius: 10,
								flexWrap: "wrap",
							}}
						>
							<div className="image-container">
								<Image
									style={{ width: "99px", height: "auto" }}
									src={logoHD}
									alt=" "
									className=" "
								/>
							</div>
							<div className="title-container" style={{ width: "64%" }}>
								<h2 className="section1_Text1">Academia de Lenguas Internacionales</h2>
								<h3 className="section1_Text2" style={{ color: "#3478f6" }}>
									Plataforma de aprendizaje
								</h3>
								<div style={{ height: "23.53px", paddingLeft: "0" }}>
									<button
										type="button"
										className="obtain-mobile-btn"
										onClick={() => history.push("/mobileAboutAPP")}
									>
										Obtener
									</button>
								</div>
							</div>
							<div className="android-banner-container w-100">
								<div className="caracteristica-container">
									<h3 className="big-text">{getCurrentAnniversary()} años</h3>
									<h3 className="small-text">formando políglotas</h3>
								</div>
								<div className="line-divisor"></div>
								<div className="caracteristica-container">
									<h3 className="small-text">EDAD</h3>
									<h3 className="big-text">17+</h3>
									<h3 className="small-text">AÑOS</h3>
								</div>
								<div className="line-divisor"></div>
								<div className="caracteristica-container">
									<h3 className="small-text">Duración</h3>
									<h3 className="big-text">60</h3>
									<h3 className="small-text">Seminarios</h3>
								</div>
								<div className="line-divisor"></div>
								<div className="caracteristica-container">
									<h3 className="big-text">
										4.5{" "}
										<img
											src={Star}
											style={{
												filter:
													"invert(56%) sepia(6%) saturate(173%) hue-rotate(182deg) brightness(99%) contrast(91%)",
											}}
											alt="estrella"
										/>
									</h3>
									<h3 className="small-text">Calificación</h3>
								</div>
							</div>
						</div>
						<Row>
							<Col>
								<h2>Acceso alumnos</h2>
							</Col>
						</Row>
						{error !== "" && (
							<Row>
								<Col>
									{error === "auth/account-exists-with-different-credential" && (
										<p style={{ color: "red" }}>El correo ya se encuentra registrado.</p>
									)}
								</Col>
							</Row>
						)}
						<Row className="input-row" style={{ margin: "auto", marginBottom: 0, maxWidth: 630 }}>
							<Col className="w-100 p-0">
								<Form.Group className="" controlId="formBasicEmail">
									<Form.Label>
										<b style={{ fontSize: 20 }}>Correo electrónico</b>
									</Form.Label>
									<Form.Control
										required
										type="text"
										name="email"
										placeholder="ejemplo@ejemplo.com"
									/>
									<Form.Control.Feedback type="invalid">
										Por favor ingresa correo valido.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row className="input-row" style={{ margin: "auto", marginBottom: 0, maxWidth: 630 }}>
							<Col className="w-100 p-0">
								<Form.Group className="" controlId="formBasicPassword">
									<Form.Label>
										<b style={{ fontSize: 20 }}>Contraseña</b>
									</Form.Label>
									<Form.Control required type="password" name="password" placeholder="Contraseña" />
									<Form.Control.Feedback type="invalid">
										Por favor ingresa una contraseña.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row className="input-row" style={{ marginBottom: 0, justifyContent: "center" }}>
							<p style={{ fontSize: "1rem" }}>
								¿Olvidaste tu contraseña?{" "}
								<a onClick={handleShow} style={{ cursor: "pointer", textDecoration: "underline" }}>
									Recupérala aquí
								</a>
							</p>
						</Row>
						<Row
							className="input-row"
							style={{ marginBottom: 0, justifyContent: "center", minHeight: 71 }}
						>
							{!loadingLoginGoogle ? (
								<button
									type="button"
									onClick={handleGoogleSignIn}
									className="tertiary login-api-btn"
								>
									<img src={GoogleIcon} /> Ingresa con tu cuenta de google
								</button>
							) : (
								<div
									className="spinner-border text-danger"
									role="status"
									style={{ marginBottom: "1rem" }}
								>
									<span className="sr-only">Loading...</span>
								</div>
							)}
						</Row>
						{/* <Row className="input-row" style={{ marginBottom: 0, justifyContent: 'center', minHeight: 71 }}>
							{!loadingLoginFb ? (
								<button type="button" onClick={handleFacebookSignIn} className="tertiary login-api-btn"><img sr={"@img/fb-icon.svg"} /> Ingresa con facebook</button>
							) : (
								<div className="spinner-border text-danger" role="status" style={{ marginBottom: '1rem' }}>
									<span className="sr-only">Loading...</span>
								</div>
							)}
						</Row> */}
						<Row
							className="input-row"
							style={{ marginBottom: 0, justifyContent: "center", minHeight: 71 }}
						>
							{!loadingLogin ? (
								<button type="submit" className="tertiary return-btn-style login-btn">
									Iniciar sesión
								</button>
							) : (
								<div
									className="spinner-border text-danger"
									role="status"
									style={{ marginBottom: "1rem" }}
								>
									<span className="sr-only">Loading...</span>
								</div>
							)}
						</Row>
						<Row className="input-row" style={{ marginBottom: 0, justifyContent: "center" }}>
							<p style={{ fontSize: "1rem" }}>
								¿Aún no tienes un perfil de alumno? <Link to="/registrarse">Regístrate aquí</Link>
							</p>
						</Row>
					</Form>
				</div>
			</div>
			<Modal
				show={show}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Restablecer contraseña</Modal.Title>
				</Modal.Header>
				<Modal.Body
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						height: 250,
					}}
				>
					<p style={{ textAlign: "justify" }}>
						Por favor, ingresa el correo electrónico que asociaste a tu cuenta para hacerte llegar
						un correo y restablecer tu contraseña
					</p>
					<label htmlFor="email">Correo:</label>
					<input
						style={{ padding: 10, borderRadius: 10, border: "1px solid black" }}
						name="email"
						id="email"
						type="email"
						value={email}
						placeholder="ejemplo@ejemplo.com"
						onChange={handleChange}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={handleClose} style={{ marginRight: "auto" }}>
						Cancelar
					</Button>
					<Button variant="primary" onClick={handleEmailSent}>
						Enviar
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showPhone}
				onHide={handleClosePhone}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Número de celular</Modal.Title>
				</Modal.Header>
				<Modal.Body
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						height: 250,
					}}
				>
					<p style={{ textAlign: "justify" }}>Ingresa un celular para vincularlo con tu cuenta.</p>
					<PhoneInput
						placeholder="Ingresa un número de celular."
						labels={es}
						defaultCountry="MX"
						international={true}
						name="phone"
						id="phone"
						value={phone}
						onChange={handlePhone}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={handleClosePhone} style={{ marginRight: "auto" }}>
						Cancelar
					</Button>
					<Button variant="primary" onClick={handleLoginWithPhone}>
						Enviar
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default LoginContent;
