import { getCategories, getSeminaryData, getSubCategoriesBySeminary } from "@api/Vocabulary";
import { useAuth } from "@hooks/useAuth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { languagesEnglish } from "functions/DefaultValues";
import { db } from "initFirebase";
import { language, languagesArrays } from "interfaces/Interfaces";
import React, { SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

interface VocabularySeminaryContextI {
	loading: boolean;
	setLoading: React.Dispatch<SetStateAction<boolean>>;
	words: languagesArrays;
	categories: Array<string>;
	subCategories: Array<string>;
	languagesProgress: any;
	view: number;
	setView: React.Dispatch<SetStateAction<number>>;
	allWordsArray: Array<any>;
	getSeminary: () => void;
	manageError: (param: any) => void;
	unitTime: number;
}

const VocabularySeminaryContext = createContext<VocabularySeminaryContextI>({
	loading: false,
	setLoading: () => {},
	words: {
		french: [],
		german: [],
		japanese: [],
		portuguese: [],
		english: [],
		chinese: [],
		russian: [],
		italian: [],
	},
	categories: [],
	subCategories: [],
	languagesProgress: null,
	view: 1,
	setView: () => {},
	allWordsArray: [],
	getSeminary: () => {},
	manageError: () => {},
	unitTime: 0,
});

export const useVocabularySeminaryContext = () => {
	return useContext(VocabularySeminaryContext);
};

export const VocabularySeminaryContextProvider = ({ children }) => {
	const [loading, setLoading] = useState<boolean>(true);
	const history = useHistory();
	const { user } = useAuth();
	const { num } = useParams<any>();
	const [words, setWords] = useState<languagesArrays>({
		french: [],
		german: [],
		japanese: [],
		portuguese: [],
		english: [],
		chinese: [],
		russian: [],
		italian: [],
	});
	const [categories, setCategories] = useState<Array<string>>(new Array());
	const [subCategories, setSubCategories] = useState<Array<string>>(new Array());
	const [languagesProgress, setLanguagesProgress] = useState<any>();
	const [langProgress, setLangProgress] = useState<any>();
	const [differenceInProgress, setDifferenceInProgress] = useState<boolean>(false);
	const [differenceIn, setDifferenceIn] = useState<string>("");
	const [view, setView] = useState<number>(1);
	const [allWordsArray, setAllWordsArray] = useState<Array<any>>([]);
	const [unitTime, setUnitTime] = useState<number>(0);

	const calculateProgress = (array) => ({
		seen: isNaN((array.filter((e) => e.dominated !== null).length / array.length) * 100)
			? 0
			: (array.filter((e) => e.dominated !== null).length / array.length) * 100,
		dominated: isNaN((array.filter((e) => e.dominated === 3).length / array.length) * 100)
			? 0
			: (array.filter((e) => e.dominated === 3).length / array.length) * 100,
	});

	const getSeminary = () => {
		try {
			if (
				(parseInt(num) !== 0 && parseInt(num) <= 60) ||
				(parseInt(num) === 0 && user.userType === 2)
			) {
				getSeminaryData(user.vocabularyUserId, parseInt(num) === 0 ? 61 : num)
					.then(async (res) => {
						let wordsArray = new Array();
						let index = 0;
						let frenchArray = new Array<language>();
						let germanArray = new Array<language>();
						let italianArray = new Array<language>();
						let japaneseArray = new Array<language>();
						let chineseArray = new Array<language>();
						let russianArray = new Array<language>();
						let portugueseArray = new Array<language>();
						let englishArray = new Array<language>();
						await Promise.all(
							res.seminaries.map((word) => {
								index++;

								const data = {
									id: word.id,
									category: word.categoria ? word.categoria.split("|") : [],
									singular_category: word.singular_category,
									sub_category: word.sub_categorias ? word.sub_categorias.split("|") : [null],
									dominated: word.dominada,
									language: word.idioma,
									native_word: word.palabra,
									optional_word: word.palabra_opcional,
									meaning: word.significados ? word.significados.split("|") : [],
									word_id: word.word_id,
									warning: word.advertencia,
									times_answered:
										word.veces_respondida === null ? 0 : parseFloat(word.veces_respondida),
									times_correct_answer:
										word.veces_respondida_correcta === null
											? 0
											: parseFloat(word.veces_respondida_correcta),
									custom_difficulty: parseFloat(word.dificultadP),
									firstStudied: word.firstStudied,
									lastStudied: word.lastStudied,
									seminarios: word.seminarios,
									memory: word.memoria,
									grammar: word.gramatica,
									advice: word.consejo,
									fake_friend: word.falso_amigo,
									similarity: word.similitud,
									pronunciation: word.pronunciacion,
									did_YouKnow: word.sabias_que,
								};
								wordsArray.push(data);
							})
						);

						await Promise.all(
							wordsArray.map((word) => {
								switch (word.language) {
									case "french":
										frenchArray.push(word);
										break;
									case "german":
										germanArray.push(word);
										break;
									case "italian":
										italianArray.push(word);
										break;
									case "japanese":
										japaneseArray.push(word);
										break;
									case "chinese":
										chineseArray.push(word);
										break;
									case "russian":
										russianArray.push(word);
										break;
									case "portuguese":
										portugueseArray.push(word);
										break;
									case "english":
										englishArray.push(word);
										break;
								}
							})
						);

						setWords({
							french: frenchArray,
							german: germanArray,
							italian: italianArray,
							japanese: japaneseArray,
							chinese: chineseArray,
							russian: russianArray,
							english: englishArray,
							portuguese: portugueseArray,
						});
						if (languagesProgress === undefined) {
							setLanguagesProgress({
								french: calculateProgress(frenchArray),
								german: calculateProgress(germanArray),
								italian: calculateProgress(italianArray),
								japanese: calculateProgress(japaneseArray),
								chinese: calculateProgress(chineseArray),
								russian: calculateProgress(russianArray),
								english: calculateProgress(englishArray),
								portuguese: calculateProgress(portugueseArray),
							});
						} else {
							updateProgress({
								french: frenchArray,
								german: germanArray,
								italian: italianArray,
								japanese: japaneseArray,
								chinese: chineseArray,
								russian: russianArray,
								english: englishArray,
								portuguese: portugueseArray,
							});
						}

						setAllWordsArray([
							...frenchArray,
							...germanArray,
							...italianArray,
							...japaneseArray,
							...chineseArray,
							...russianArray,
							...englishArray,
							...portugueseArray,
						]);

						getSubCategories();
						getCategoriesBySeminary();
						const errorDocRef = doc(db, "errorsRegister", "structuredClone");
						const docSnap = await getDoc(errorDocRef);

						if (docSnap.exists()) {
							let usersTemp = [...docSnap.data().usersGood];
							if (!usersTemp.includes(user.id)) {
								usersTemp.push(user.id);
								await updateDoc(errorDocRef, {
									timesGood: docSnap.data().timesGood + 1,
									usersGood: usersTemp,
								});
							}
						}
						// setLoading(false);
						getUnitTime();
					})
					.catch(async (error) => {
						console.error(error);
						const errorDocRef = doc(db, "errorsRegister", "structuredClone");
						const docSnap = await getDoc(errorDocRef);

						if (docSnap.exists()) {
							let usersTemp = [...docSnap.data().users];
							if (!usersTemp.includes(user.id)) {
								usersTemp.push(user.id);
								await updateDoc(errorDocRef, {
									times: docSnap.data().times + 1,
									users: usersTemp,
								});
							}
						}
						manageError(error);
					});
			} else {
				history.push("/micuenta/vocabulario");
			}
		} catch (error) {
			console.error(error);
			alert("Ha ocurrido un error.");
		}
	};

	const getUnitTime = async () => {
		const existsQuizTimeRef = doc(db, "quizTimes", user.id + "-" + num);
		const snapRef = await getDoc(existsQuizTimeRef);

		if (snapRef.exists()) {
			const values = snapRef.data();
			let total =
				values.french +
				values.japanese +
				values.chinese +
				values.english +
				values.portuguese +
				values.russian +
				values.italian +
				values.german;
			setUnitTime(Math.ceil(total / 60));
		} else {
			await setDoc(doc(db, "quizTimes", user.id + "-" + num), {
				userId: user.id,
				seminaryNum: parseInt(num),
				french: 0,
				japanese: 0,
				chinese: 0,
				english: 0,
				portuguese: 0,
				russian: 0,
				italian: 0,
				german: 0,
			});
			setUnitTime(0);
		}
	};

	const getSubCategories = () => {
		let subCategoriesArray = new Array();
		getSubCategoriesBySeminary(num)
			.then((res) => {
				res.subCategories.forEach((subCategory) => {
					subCategoriesArray.push(subCategory.sub_categoria);
				});
				setSubCategories(subCategoriesArray);
			})
			.catch((error) => {
				console.error(error);
				manageError(error);
			});
	};

	const manageError = (error) => {
		console.error(error);
		alert("Ha habido un error de conexión.");
		setView(1);
	};

	const getCategoriesBySeminary = () => {
		let categoriesArray = new Array();
		getCategories(num)
			.then((res) => {
				res.categories.forEach((category) => {
					categoriesArray.push(category.categoria);
				});

				setCategories(categoriesArray);
			})
			.catch((error) => {
				console.error(error);
				manageError(error);
			});
	};

	const updateProgress = async (w) => {
		let differenceIn = "";
		const dataTemp = {
			french: {
				seen: (w.french.filter((e) => e.dominated !== null).length / w.french.length) * 100,
				dominated: (w.french.filter((e) => e.dominated === 3).length / w.french.length) * 100,
			},
			german: {
				seen: (w.german.filter((e) => e.dominated !== null).length / w.german.length) * 100,
				dominated: (w.german.filter((e) => e.dominated === 3).length / w.german.length) * 100,
			},
			italian: {
				seen: (w.italian.filter((e) => e.dominated !== null).length / w.italian.length) * 100,
				dominated: (w.italian.filter((e) => e.dominated === 3).length / w.italian.length) * 100,
			},
			japanese: {
				seen: (w.japanese.filter((e) => e.dominated !== null).length / w.japanese.length) * 100,
				dominated: (w.japanese.filter((e) => e.dominated === 3).length / w.japanese.length) * 100,
			},
			chinese: {
				seen: (w.chinese.filter((e) => e.dominated !== null).length / w.chinese.length) * 100,
				dominated: (w.chinese.filter((e) => e.dominated === 3).length / w.chinese.length) * 100,
			},
			russian: {
				seen: (w.russian.filter((e) => e.dominated !== null).length / w.russian.length) * 100,
				dominated: (w.russian.filter((e) => e.dominated === 3).length / w.russian.length) * 100,
			},
			english: {
				seen: (w.english.filter((e) => e.dominated !== null).length / w.english.length) * 100,
				dominated: (w.english.filter((e) => e.dominated === 3).length / w.english.length) * 100,
			},
			portuguese: {
				seen: (w.portuguese.filter((e) => e.dominated !== null).length / w.portuguese.length) * 100,
				dominated:
					(w.portuguese.filter((e) => e.dominated === 3).length / w.portuguese.length) * 100,
			},
		};

		await Promise.all(
			languagesEnglish.map((lang) => {
				const TLSeen = isNaN(dataTemp[lang].seen) ? 0 : dataTemp[lang].seen;
				const TLDominated = isNaN(dataTemp[lang].dominated) ? 0 : dataTemp[lang].dominated;
				const LPSeen = isNaN(languagesProgress[lang].seen) ? 0 : languagesProgress[lang].seen;
				const LPDominated = isNaN(languagesProgress[lang].dominated)
					? 0
					: languagesProgress[lang].dominated;
				if (TLSeen !== LPSeen || TLDominated !== LPDominated) {
					differenceIn = lang;
					setLangProgress({ seen: dataTemp[lang].seen, dominated: dataTemp[lang].dominated });
				}
			})
		);
		if (differenceIn !== "") {
			setDifferenceIn(differenceIn);
			setDifferenceInProgress(true);
		}
	};

	useEffect(() => {
		if (differenceInProgress && differenceIn.length > 0 && langProgress !== undefined) {
			const timeout = setTimeout(() => {
				let partSeen = (langProgress.seen - languagesProgress[differenceIn].seen) / 100;
				console.log(partSeen);
				for (let index = 1; index <= 100; index++) {
					setTimeout(() => {
						setLanguagesProgress({
							...languagesProgress,
							[differenceIn]: {
								seen: languagesProgress[differenceIn].seen + partSeen * index,
								dominated: languagesProgress[differenceIn].dominated,
							},
						});
					}, 10 * index);
				}
			}, 1500);

			const timeoutDominated = setTimeout(() => {
				let partDominated =
					(langProgress.dominated - languagesProgress[differenceIn].dominated) / 100;

				for (let index = 1; index <= 100; index++) {
					setTimeout(() => {
						setLanguagesProgress({
							...languagesProgress,
							[differenceIn]: {
								dominated: languagesProgress[differenceIn].dominated + partDominated * index,
								seen: langProgress.seen,
							},
						});
					}, 10 * index);
				}
			}, 3000);

			return () => {
				clearTimeout(timeout);
				clearTimeout(timeoutDominated);
				setDifferenceIn("");
				setDifferenceInProgress(false);
				setLangProgress(undefined);
			};
		}
	}, [differenceInProgress, differenceIn, langProgress, view]);

	const values: VocabularySeminaryContextI = {
		loading,
		setLoading,
		words,
		categories,
		subCategories,
		view,
		setView,
		languagesProgress,
		allWordsArray,
		getSeminary,
		manageError,
		unitTime,
	};

	return (
		<VocabularySeminaryContext.Provider value={values}>
			{children}
		</VocabularySeminaryContext.Provider>
	);
};
