import { auth, functions } from "initFirebase";
import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc, updateDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../initFirebase";
import { HttpsCallableResult, httpsCallable } from "firebase/functions";
import { StripeResponse } from "interfaces/Stripe/Stripe";
import { createUser } from "@api/Vocabulary";

const AuthContext = createContext<any>(null);

export const useAuth = () => {
	return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState<any>(null);
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [selectedUserPreview, setUserPreview] = useState<any>(() => {
		const savedSelectedUser = localStorage.getItem("selectedUser");
		return savedSelectedUser ? JSON.parse(savedSelectedUser) : { name: "" };
	});
	const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
	const [userGeneration, setUserGeneration] = useState<null | any>(null);

	const [asideOpen, setAsideOpen] = useState<boolean>(false);
	const asideBtnRef = useRef<any>();

	const [quizOn, setQuizOn] = useState<boolean>(false);

	const togglePreviewModeUser = () => {
		setUserPreview((previewuser) => !previewuser);
	};

	const getUserGeneration = useCallback(async () => {
		if (user.generationId) {
			const genRef = doc(db, "generations", user.generationId);
			const genSnap = await getDoc(genRef);
			if (genSnap.exists()) {
				setUserGeneration({ id: genSnap.id, ...genSnap.data() });
			}
		}
	}, [user]);

	const handleSelectedUserPreview = useCallback(
		(newUser) => {
			if (newUser !== selectedUserPreview) {
				setUserPreview(newUser);
			}
		},
		[selectedUserPreview]
	);

	useEffect(() => {
		localStorage.setItem("selectedUser", JSON.stringify(selectedUserPreview));
	}, [selectedUserPreview]);

	const auth = getAuth();

	const logout = async () => {
		return signOut(auth)
			.then(() => {
				setUser(null);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const updateUser = async (uid?: string) => {
		const docRef = doc(db, "users", uid || user.id);
		const docSnap = await getDoc(docRef);
		const data = {
			id: docSnap.id,
			...docSnap.data(),
		};
		setUser(data);
	};

	useEffect(() => {
		return onAuthStateChanged(auth, async (user) => {
			if (user) {
				const uid = user.uid;
				// const uid = "SIW12I3OFJZsU2USAUoERCm4wt23";
				const docRef = doc(db, "users", uid);
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					const data: any = {
						id: uid,
						...docSnap.data(),
					};

					if (!data.vocabularyUserId) {
						const vocabularyUser = await createUser(data.id, data.name);
						await updateDoc(docRef, {
							vocabularyUserId: vocabularyUser.insertId,
						});

						data.vocabularyUserId = vocabularyUser.insertId;
					}

					if (!data.stripeId || data.stripeId === "") {
						let stripeData: any;

						type PaymentCustomerData = { name: string; email: string; phone: string };
						const userData = {
							name: data.name,
							email: data.email.trim(),
							phone: data.phone,
						};

						const getStripeCustomer = httpsCallable<PaymentCustomerData, StripeResponse>(
							functions,
							"getStripeCustomer"
						);

						const stripeCustomerResponse: HttpsCallableResult<StripeResponse> =
							await getStripeCustomer(data.email);

						if (!stripeCustomerResponse.data[0]) {
							const createStripeCustomer = httpsCallable<PaymentCustomerData, StripeResponse>(
								functions,
								"createStripeCustomer"
							);
							const stripeResponse: HttpsCallableResult<StripeResponse> =
								await createStripeCustomer(userData);
							stripeData = stripeResponse?.data;
						}

						await updateDoc(docRef, {
							stripeId: stripeData?.id ? stripeData?.id : stripeCustomerResponse?.data[0]?.id,
						});
					}

					// Si el userType es 2 o 4, y no tienen una generación asignada, entonces les asignamos una
					if ((data.userType === 2 || data.userType === 4) && !data.generationId) {
						const generationsSnap = await getDocs(collection(db, "generations"));

						// Convertimos los documentos a un array
						const generations = generationsSnap.docs.map((doc) => doc.data());

						// Seleccionamos un documento aleatoriamente
						const randomGeneration = generations[Math.floor(Math.random() * generations.length)];

						data.generationId = randomGeneration.id;

						// Actualizamos el documento en Firestore
						await updateDoc(docRef, {
							generationId: data.generationId,
						});
					}

					setUser(data);
				}
			}
			setIsAuthenticating(false);
		});
	}, []);

	useEffect(() => {
		if (user && user.generationId) {
			getUserGeneration();
		}
	}, [getUserGeneration]);

	const values = {
		user,
		isAuthenticating,
		logout,
		updateUser,
		togglePreviewModeUser,
		selectedUserPreview,
		handleSelectedUserPreview,
		notificationOpen,
		setNotificationOpen,
		quizOn,
		setQuizOn,
		setAsideOpen,
		asideOpen,
		asideBtnRef,
		userGeneration,
	};

	return (
		<AuthContext.Provider value={values}>{!isAuthenticating && children}</AuthContext.Provider>
	);
};
