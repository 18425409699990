import { SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import { useStreak } from "@hooks/useStreak";
import { StreakFlameIcon } from "assets/Imports";
import { useEffect, useState } from "react";
import { StreakModal } from "../StreakModal";
import { dayInfoModalInterface } from "interfaces/StreakCalendar";
import { DayInfoModal } from "./DayInfoModal";
import { isValid, parse, parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

interface StreakFlameProps {
	user: any;
	vocabularyStreak: Array<string>;
	isLoading: boolean;
	studiedDays: Array<string>;
	currentStreak?: number;
}

export function StreakFlame({
	user,
	vocabularyStreak,
	isLoading,
	studiedDays,
	currentStreak = 0,
}: StreakFlameProps) {
	const [streakModal, setStreakModal] = useState(false);
	const [value, onChange] = useState(new Array());
	const [dayInfoModal, setDayInfoModal] = useState<dayInfoModalInterface>({
		show: false,
		dayData: null,
	});

	const getStreakArray = async () => {
		try {
			const temp = studiedDays
				.map((dateStr) => {
					// Extract the date part before 'T'
					const dateOnlyStr = dateStr.split("T")[0]; // e.g., '2024-12-20'

					// Define the expected date format
					const formatString = "yyyy-MM-dd";

					// Parse the date string as a local date
					const parsedDate = parse(dateOnlyStr, formatString, new Date());

					// Validate the parsed date
					if (!isValid(parsedDate)) {
						console.warn(`Invalid date format encountered: ${dateStr}`);
						return null; // Exclude invalid dates
					}

					return parsedDate;
				})
				.filter((date) => date !== null) // Remove invalid dates
				.reverse(); // Reverse the array if needed

			onChange(temp);
		} catch (error) {
			console.error("Error in getStreakArray:", error);
			// Optionally, handle the error in the UI (e.g., show a notification)
		}
	};
	const onDayClick = (date: Date) => {
		const index = value.findIndex((e) => e.toISOString() === date.toISOString());
		if (index !== -1) {
			const dateString = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

			const data = {
				date: date,
				dateString: dateString,
			};

			setDayInfoModal({ show: true, dayData: data });
		}
	};

	useEffect(() => {
		if (studiedDays.length > 0) {
			getStreakArray();
		}
	}, [studiedDays]);

	return (
		<>
			<StreakModal
				value={value}
				show={streakModal}
				onHide={() => setStreakModal(false)}
				vocabularyStreak={studiedDays}
				onDayClick={onDayClick}
			>
				<DayInfoModal
					userProfile={{ id: user.id, vocabularyUserId: user.vocabularyUserId }}
					show={dayInfoModal.show}
					setDayInfoModal={setDayInfoModal}
					dayData={dayInfoModal.dayData}
				/>
			</StreakModal>
			<div className="streak-container" onClick={() => setStreakModal(true)}>
				<img className="streak-icon" src={StreakFlameIcon} alt="icono de fuego" />
				<h2>
					{isLoading ? <SpinnerCustom height={25} /> : currentStreak || vocabularyStreak.length}
				</h2>
			</div>
		</>
	);
}
