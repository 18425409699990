import axios from "axios";

export const uploadVideo = async (file, setTotalBytesUploaded) => {
	return await axios.post(`https://academia.inowu.dev/upload`, file, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
		onUploadProgress: (progressEvent) => setTotalBytesUploaded(progressEvent.loaded),
	});
};

export const convertVideo = async (body) => {
	return await axios
		.post(`https://academia.inowu.dev/convert_video`, {
			savepath:
				body.savepath
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.replace(/[()]/g, "") +
				"/" +
				body.uuid +
				"/",
			filename: body.filename,
		})
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.error(error);
		});
};

export const getStatus = async (savepath) => {
	return await axios
		.get(`https://academia.inowu.dev/video_status?savepath=${savepath}`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.error(error);
			return error;
		});
};

export const lastStep = async (savepath: string) => {
	return await axios
		.post(`https://academia.inowu.dev/video_format`, { savepath: savepath })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.error(error);
			return error;
		});
};
