import { VocabularyModule } from "interfaces/Vocabulary/VocabularyInterfaces";
import React, { useEffect, useRef, useState } from "react";
import "./VocabularyModuleCard.scss";
import { romanize } from "functions/Functions";
import PercentageBar from "@components/PercentageBar/PercentageBar";
import VocabularySeminaryCard from "../VocabularySeminaryCard/VocabularySeminaryCard";
import { fetchVocabularyProgress } from "@services/VocabularyService";
import { useSeminariesService } from "@services/SeminariesService";
import { useAuth } from "@hooks/useAuth";

type VocabularyModuleCardProps = {
	module: VocabularyModule;
};

const module1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const module2 = [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
const module3 = [26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
const module4 = [41, 42, 43, 44, 45, 46, 47, 48, 49, 50];
const module5 = [51, 52, 53, 54, 55, 56, 57, 58, 59, 60];

const modules = {
	module1,
	module2,
	module3,
	module4,
	module5,
};

const VocabularyModuleCard = ({ module }: VocabularyModuleCardProps) => {
	const cardsContainerRef = useRef<HTMLDivElement>(null);
	const { user } = useAuth();
	const [openDetail, setOpenDetail] = useState(false);
	const [moduleProgress, setModuleProgress] = useState({ totalWords: 0, dominatedWords: 0 });
	const [loadingModule, setLoadingModule] = useState(true);
	const { nextSeminaries, paidSeminaries } = useSeminariesService();

	const getModuleProgress = async () => {
		try {
			const res = await fetchVocabularyProgress(module.initialSeminary, module.endSeminary);
			setModuleProgress(res);
			setLoadingModule(false);
		} catch (error) {
			console.error(error);
			//
		}
	};

	useEffect(() => {
		getModuleProgress();
	}, []);

	return (
		<div className={`vocabulary-module-card ${openDetail ? "open" : "closed"}`}>
			<div
				className="main-card-container"
				onClick={() => setOpenDetail((prev) => !prev)}
				role="button"
			>
				<div className="info-container">
					<h2>Modulo {romanize(module.number)}</h2>
					<h3 className="subtitle">
						({romanize(module.initialSeminary)}-{romanize(module.endSeminary)})
					</h3>
					<p>
						{moduleProgress.dominatedWords}/{moduleProgress.totalWords} Términos dominados
					</p>
				</div>
				<div className="progress-container">
					<div>
						<h3 className="progress-label">Progreso del módulo</h3>
						<PercentageBar
							loading={loadingModule}
							percentage={Math.floor(
								(moduleProgress.dominatedWords / moduleProgress.totalWords) * 100
							)}
						/>
					</div>
				</div>
			</div>
			<div className="seminaries-cards-container" ref={cardsContainerRef}>
				{openDetail &&
					modules[`module${module.number}`].map((seminary) => (
						<VocabularySeminaryCard
							key={seminary}
							block={user.userType === 2 ? false : seminary > paidSeminaries ? true : false}
							seminary={seminary}
							nextClass={
								user.userType === 2
									? false
									: nextSeminaries.length > 0 && seminary > nextSeminaries[0].num
							}
							gKey={undefined}
							lastElementRef={undefined}
						/>
					))}
			</div>
		</div>
	);
};

export default VocabularyModuleCard;
