import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { db } from "initFirebase";
import { SeminaryRecording, userViews } from "interfaces/Interfaces";
import { data } from "jquery";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import "video.js/dist/video-js.css";
import "./RecordingsStyles.scss";
import videojs from "video.js";
import "videojs-contrib-quality-levels";
import "videojs-hls-quality-selector";
import { getFileName, getInitials, romanize, secondsToHms } from "functions/Functions";
import { useSeminariesService } from "../../services/SeminariesService";
import VideoJs from "./component/VideoJs";
import UsersViewsModal from "@components/Admin/UsersViewsModal";
import { fullTemary } from "Temary/Temary";
import TemaryCard from "@components/TemaryCard/TemaryCard";
import { Annotation } from "./component/Annotation/Annotation";
import { alerModal } from "@containers/VocabularySeminaryContainer/components/Test/Test";
import { AlertModal } from "@components/AlertModal/AlertModal";
import { AdminGenSelector } from "@components/Admin/AdminGenSelector/AdminGenSelector";
import { ArrowRed, OpenBook, ViewIcon } from "assets/Imports";
import { useGenerations } from "@hooks/GenerationsFilters/useGenerations";
import { compareGenerations } from "functions/Generations/FunctionsGenerations";

declare global {
	interface Window {
		player: any; // 👈️ turn off type checking
	}
}

interface recordingsProps {
	user: any;
	genId?: string;
}

interface views {
	totalViews: number;
	userViews: Array<userViews>;
}

function Recordings(props: recordingsProps) {
	const { id } = useParams<any>();
	const [loading, setLoading] = useState<boolean>(true);
	const history = useHistory();
	const [seminary, setSeminary] = useState<SeminaryRecording>({
		id: "",
		num: 0,
		videoUrl: "",
		videoUrl2: "",
		date: "",
		files: [],
		notes: "",
		usersViews: [{ userId: "", views: 0 }],
		usersViews2: [{ userId: "", views: 0 }],
		usersViews3: [{ userId: "", views: 0 }],
		temaryIndex: 0,
		totalViews: 0,
		totalViews2: 0,
		totalViews3: 0,
	});
	const [nextSeminary, setNextSeminary] = useState({ num: 0, id: "", videoUrl: "" });
	const [prevSeminary, setPrevSeminary] = useState({ num: 0, id: "", videoUrl: "" });
	const [gen, setGen] = useState({ id: "", name: "" });
	const [videoDuration, setVideoDuration] = useState<string>("");
	const [annotations, setAnnotations] = useState([]);
	const [annotationText, setAnnotationText] = useState<string>("");
	const [openAbout, setOpenAbout] = useState<boolean>(false);
	const [part, setPart] = useState<number>(1);
	const location = useLocation<any>();
	const [videoUrl, setVideoUrl] = useState<string>("");
	const { paidSeminaries, nextSeminaries }: any = useSeminariesService();
	const [modalShow, setModalShow] = useState<boolean>(false);
	const [genId, setGenId] = useState<string>("");

	const [changingUrl, setChangingUrl] = useState<boolean>(false);
	const [viewsPart, setViewsPart] = useState<views>({ totalViews: 0, userViews: [] });
	const [WanttoNext, setWantToNext] = useState(false);
	const [WanttoPrev, setWantToPrev] = useState(false);
	const [alertModal, setAlerModal] = useState<alerModal>({
		show: false,
		title: "Mantenimiento",
		message: "Grabaciones en mantenimiento.",
	});
	const { gens, handleCheck, load, eyeColor, setGens, setEyeColor } =
		useGenerations(compareGenerations);

	const [videoJsOptions, setVideoJsOptions] = useState({
		autoplay: false,
		controls: true,
		responsive: true,
		fluid: true,
		sources: [
			{
				src: "",
				type: "",
				withCredentials: false,
			},
		],
		html5: {
			vhs: {
				overrideNative: true,
			},
		},
		hlsQualitySelector: {},
	});

	const playerRef: any = useRef(null);

	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	const getSeminaryRecord = async (seminaryId) => {
		setLoading(true);
		let genId = "";
		if (props.user.userType === 2 || props.user.userType === 4) {
			if (location.state !== undefined) {
				genId = location.state.genId;
			} else {
				genId = props.user.generationId;
			}
		} else {
			genId = props.user.generationId;
		}

		setGenId(genId);
		const docRef = doc(db, "generations", genId, "material", seminaryId);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists() && docSnap.data().num <= paidSeminaries) {
			if (nextSeminaries.findIndex((e) => e.num === docSnap.data().num) !== -1) {
				if (props.user.userType === 2 || props.user.userType === 4) {
					alert("El seminario no se ha llevado acabo.");
				}
				history.push({ pathname: "/micuenta/grabaciones" });
			}
			const data: SeminaryRecording = {
				id: docSnap.id,
				num: docSnap.data().num,
				videoUrl: docSnap.data().videoUrl,
				videoUrl2: docSnap.data().videoUrl2 !== undefined ? docSnap.data().videoUrl2 : "",
				videoUrl12: docSnap.data().videoUrl12,
				videoUrl13: docSnap.data().videoUrl13,
				date: docSnap.data().date,
				files: docSnap.data().files,
				notes: docSnap.data().notes,
				usersViews:
					docSnap.data().usersViews !== undefined ? docSnap.data().usersViews : new Array(),
				totalViews: docSnap.data().usersViews !== undefined ? docSnap.data().totalViews : 0,
				usersViews2:
					docSnap.data().usersViews2 !== undefined ? docSnap.data().usersViews2 : new Array(),
				totalViews2: docSnap.data().usersViews2 !== undefined ? docSnap.data().totalViews2 : 0,
				usersViews3:
					docSnap.data().usersViews3 !== undefined ? docSnap.data().usersViews3 : new Array(),
				totalViews3: docSnap.data().usersViews3 !== undefined ? docSnap.data().totalViews3 : 0,
				temaryIndex: fullTemary.findIndex((e) => e.num === docSnap.data().num),
			};

			setViewsPart({ totalViews: data.totalViews, userViews: data.usersViews });

			await Promise.all(
				data.usersViews.map((userId, index) => {
					if (typeof userId === "string") {
						data.usersViews[index] = { userId: userId, views: 1 };
					}
				})
			);
			await Promise.all(
				data.usersViews2.map((userId, index) => {
					if (typeof userId === "string") {
						data.usersViews2[index] = { userId: userId, views: 1 };
					}
				})
			);
			await Promise.all(
				data.usersViews3.map((userId, index) => {
					if (typeof userId === "string") {
						data.usersViews3[index] = { userId: userId, views: 1 };
					}
				})
			);
			let indexView = data.usersViews.findIndex((e) => e.userId === props.user.id);
			let indexView2 = data.usersViews2.findIndex((e) => e.userId === props.user.id);
			let indexView3 = data.usersViews3.findIndex((e) => e.userId === props.user.id);

			if (indexView === -1 || indexView2 === -1 || indexView3 === -1) {
				if (indexView === -1) data.usersViews.push({ userId: props.user.id, views: 0 });
				if (indexView2 === -1) data.usersViews2.push({ userId: props.user.id, views: 0 });
				if (indexView3 === -1) data.usersViews3.push({ userId: props.user.id, views: 0 });
				await updateDoc(docRef, {
					...(indexView === -1 && { usersViews: data.usersViews }),
					...(indexView2 === -1 && { usersViews2: data.usersViews2 }),
					...(indexView3 === -1 && { usersViews3: data.usersViews3 }),
				});
			}

			if (props.user.userType === 2 || props.user.userType === 4) {
				await updateDoc(docRef, {
					usersViews: data.usersViews,
					usersViews2: data.usersViews2,
					usersViews3: data.usersViews3,
				});
			}

			setVideoJsOptions({
				...videoJsOptions,
				sources: [{ src: data.videoUrl!, type: "application/x-mpegURL", withCredentials: false }],
			});

			setVideoUrl(data.videoUrl!);
			setSeminary(data);
			setLoading(false);
			//@ts-ignore

			getAnnotations(data.num);
		} else {
			// doc.data() will be undefined in this case
			console.log("No such document!");
			if (props.user.userType !== 2) {
				history.push("/micuenta");
			} else {
				history.push("/micuenta/grabaciones");
			}
		}
	};

	const getAnnotations = (num) => {
		const q = query(
			collection(db, "annotations"),
			where("userId", "==", props.user.id),
			where("seminaryNum", "==", num),
			orderBy("time", "desc")
		);
		let annotationsArray: any = [];
		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			annotationsArray = [];
			let index = 0;
			querySnapshot.forEach((doc) => {
				annotationsArray.push({ id: doc.id, ...doc.data() });

				index++;
				if (index === querySnapshot.size) {
					setAnnotations(annotationsArray);
				}
			});
		});
	};

	const getNextSeminary = async () => {
		let nextTemp = seminary.num + 1;
		if (nextTemp > paidSeminaries) {
			setNextSeminary({ ...nextSeminary, id: "no" });
		} else {
			const seminaryRef = query(
				collection(
					db,
					"generations",
					location.state ? location.state.genId : props.user.generationId,
					"material"
				),
				where("num", "==", nextTemp)
			);

			const seminarySnap = await getDocs(seminaryRef);

			if (seminarySnap.size === 1) {
				const data = {
					id: seminarySnap.docs[0].id,
					num: seminarySnap.docs[0].data().num,
					videoUrl: seminarySnap.docs[0].data().videoUrl,
				};

				console.log(data);
				setNextSeminary(data);
			}
		}
	};

	const getPrevSeminary = async () => {
		let prevTemp = seminary.num - 1;

		if (prevTemp > 0) {
			const seminaryRef = query(
				collection(
					db,
					"generations",
					location.state ? location.state.genId : props.user.generationId,
					"material"
				),
				where("num", "==", prevTemp)
			);

			const seminarySnap = await getDocs(seminaryRef);

			if (seminarySnap.size === 1) {
				const data = {
					id: seminarySnap.docs[0].id,
					num: seminarySnap.docs[0].data().num,
					videoUrl: seminarySnap.docs[0].data().videoUrl,
				};

				setPrevSeminary(data);
			}
		}
	};

	const getGen = async () => {
		const docRef = doc(
			db,
			"generations",
			location.state ? location.state.genId : props.user.generationId
		);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			const data = {
				id: docSnap.id,
				name: docSnap.data().name,
			};

			setGen(data);
			console.log(id);
			getSeminaryRecord(id);
		} else {
			history.push("/micuenta");
		}
	};

	function getDateFormat1(date) {
		let dateArray = date.split("/");
		dateArray[1] = parseInt(dateArray[1]) + 1;

		if (parseInt(dateArray[1]) < 10) {
			if (parseInt(dateArray[0]) < 10) {
				return "0" + dateArray[0] + "/0" + dateArray[1] + "/" + dateArray[2];
			} else {
				return dateArray[0] + "/0" + dateArray[1] + "/" + dateArray[2];
			}
		} else {
			if (parseInt(dateArray[0]) < 10) {
				return "0" + dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2];
			} else {
				return dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2];
			}
		}
	}

	const createAnnotation = async (e) => {
		e.preventDefault();
		console.log(playerRef.current.currentTime);
		var video: HTMLMediaElement = document.getElementById("video") as HTMLMediaElement;
		const docRef = await addDoc(collection(db, "annotations"), {
			name: props.user.name,
			userId: props.user.id,
			seminaryNum: seminary.num,
			annotationText: annotationText,
			time: !isSafari ? playerRef.current.currentTime() : playerRef.current.currentTime,
		}).then(() => {
			setAnnotationText("");
		});
	};

	const videostuff = () => {
		var video: HTMLMediaElement = document.getElementById("video-player") as HTMLMediaElement;

		video.src = videoUrl!;
	};

	const handleLoadedMetadata = () => {
		const video = playerRef.current;
		console.log(playerRef.current);
		if (!video) return;
		var date = new Date(video.duration * 1000).toISOString().substr(11, 8);
		setVideoDuration(date);
	};

	const navigateNext = async () => {
		if (nextSeminary.id) {
			await getSeminaryRecord(nextSeminary.id);
		}
	};

	const navigatePrev = async () => {
		if (prevSeminary.id) {
			await getSeminaryRecord(prevSeminary.id);
		}
	};

	useEffect(() => {
		if (props.user.userType === 2) {
			getGenerationsAdmin();
		} else {
			getGen();
		}
	}, [location.state.genId, id]);

	useEffect(() => {
		if (seminary.num !== 0) {
			getNextSeminary();
			getPrevSeminary();
		}
	}, [seminary, id]);

	useEffect(() => {
		if (!loading && !changingUrl && isSafari) {
			videostuff();
		}
	}, [loading, part, changingUrl, location.state.genId]);

	const handlePlayerReady = (player) => {
		playerRef.current = player;

		// You can handle player events here, for example:
		player.one("play", async () => {
			validateUserView();
			const docRef = doc(db, "generations", genId, "material", id);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				const temp1: Array<userViews> = docSnap.data().usersViews;
				const temp2: Array<userViews> = docSnap.data().usersViews2;
				const temp3: Array<userViews> = docSnap.data().usersViews3;

				if (part === 1) {
					let indexView = temp1.findIndex((e) => e.userId === props.user.id);

					if (indexView !== -1) {
						temp1[indexView].views += 1;
					}
				} else if (part === 2) {
					let indexView = temp2.findIndex((e) => e.userId === props.user.id);

					if (indexView !== -1) {
						temp2[indexView].views += 1;
					}
					console.log(temp2);
				} else {
					let indexView = temp3.findIndex((e) => e.userId === props.user.id);

					if (indexView !== -1) {
						temp3[indexView].views += 1;
					}
				}

				await updateDoc(docRef, {
					...(part === 1 && {
						totalViews: docSnap.data().totalViews !== undefined ? docSnap.data().totalViews + 1 : 1,
					}),
					...(part === 1 && { usersViews: temp1 }),
					...(part === 2 && {
						totalViews2:
							docSnap.data().totalViews2 !== undefined ? docSnap.data().totalViews2 + 1 : 1,
					}),
					...(part === 2 && { usersViews2: temp2 }),
					...(part === 3 && {
						totalViews3:
							docSnap.data().totalViews3 !== undefined ? docSnap.data().totalViews3 + 1 : 1,
					}),
					...(part === 3 && { usersViews3: temp3 }),
				});
			}
		});

		player.on("waiting", () => {
			videojs.log("player is waiting");
		});

		player.on("dispose", () => {
			videojs.log("player will dispose");
		});

		player.one("loadedmetadata", () => {
			var date = new Date(player.cache_.duration * 1000).toISOString().substr(11, 8);
			setVideoDuration(date);
		});
	};

	const getGenerationsAdmin = async () => {
		const q = query(collection(db, "generations"));
		const qs = await getDocs(q);

		const genArray = new Array();

		qs.docs.map((gen) => {
			genArray.push({ ...gen.data(), id: gen.id });
		});

		setGens(genArray);
		getGen();
	};

	const validateUserView = async () => {
		if (viewsPart.userViews.findIndex((e) => props.user.id) === -1) {
			const docRef = doc(db, "generations", genId, "material", id);
			const temp = JSON.parse(JSON.stringify(viewsPart.userViews));
			console.log(part);
			console.log(temp);
			temp.push(props.user.id);
			await updateDoc(docRef, {
				...(part === 1 && { usersViews: temp }),
				...(part === 2 && { usersViews2: temp }),
				...(part === 3 && { usersViews3: temp }),
			});
		}
	};

	useEffect(() => {
		setVideoJsOptions({
			...videoJsOptions,
			sources: [{ src: videoUrl!, type: "application/x-mpegURL", withCredentials: false }],
		});
		setChangingUrl(false);
	}, [videoUrl]);

	useEffect(() => {
		if (!loading) {
			setChangingUrl(true);
			switch (part) {
				case 1:
					setViewsPart({ totalViews: seminary.totalViews, userViews: seminary.usersViews });
					setVideoUrl(seminary.videoUrl!);
					break;
				case 2:
					setViewsPart({ totalViews: seminary.totalViews2, userViews: seminary.usersViews2 });
					setVideoUrl(seminary.videoUrl12!);
					break;
				case 3:
					setViewsPart({ totalViews: seminary.totalViews3, userViews: seminary.usersViews3 });
					setVideoUrl(seminary.videoUrl13!);
					break;
				default:
					break;
			}
		}
	}, [part, location.state.genId]);

	const changeGenerationAdmin = async () => {
		const docRef = query(
			collection(db, "generations", genId, "material"),
			where("num", "==", seminary.num)
		);
		const docSnap = await getDocs(docRef);

		if (docSnap.size > 0) {
			history.push({
				pathname: "/micuenta/grabaciones/" + docSnap.docs[0].id,
				state: { genId: genId },
			});
		}
	};

	useEffect(() => {
		if (props.user.userType === 2 && genId.length > 0) {
			if (location.state.genId !== genId) {
				changeGenerationAdmin();
			}
		}
	}, [genId, location.state.genId]);

	if (loading) {
		return (
			<div
				style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}
			>
				<AlertModal
					show={alertModal.show}
					onHide={() => setAlerModal({ ...alertModal, show: false })}
					title={alertModal.title}
					message={alertModal.message}
				/>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	return (
		<div className="recordings-main-container">
			<div className="go-back-container">
				<button
					onClick={() =>
						history.push({
							pathname: "/micuenta/grabaciones",
							state: { genId: location.state ? location.state.genId : props.user.generationId },
						})
					}
					className="goback-btn mb-0"
				>
					{"Atrás"}
				</button>
			</div>
			{props.user.userType === 2 && (
				<AdminGenSelector
					setGenId={setGenId}
					genId={genId}
					load={load}
					handleCheck={handleCheck}
					gens={gens}
					eyeColor={eyeColor}
					setEyeColor={setEyeColor}
				/>
			)}
			<div className="title-container">
				<h2>
					Seminario <span>{romanize(seminary.num)}</span>
				</h2>
				{props.user.userType === 2 && (
					<>
						<div className="d-flex justify-content-end" style={{ gap: 15 }}>
							<div className="recordings-views-container bg-success" style={{ cursor: "initial" }}>
								<img src={ViewIcon} alt="icono de ojo" />
								<p>{viewsPart.totalViews}</p>
							</div>
							<div className="recordings-views-container" onClick={() => setModalShow(true)}>
								<img src={ViewIcon} alt="icono de ojo" />
								<p>{viewsPart.userViews.length}</p>
							</div>
						</div>
						{!changingUrl && (
							<UsersViewsModal
								show={modalShow}
								onHide={() => setModalShow(false)}
								usersIds={viewsPart.userViews}
							/>
						)}
					</>
				)}
			</div>
			{nextSeminary.id !== "" && (
				<div className="next-seminary-container">
					{prevSeminary.id !== "" && (
						<button
							className="action-btn linear-bg w-100"
							style={{ maxWidth: 321 }}
							onClick={navigatePrev}
						>
							Seminario anterior ({romanize(prevSeminary.num)})
						</button>
					)}
					{nextSeminary.id === "no" ? (
						<div className="w-100 d-flex flex-column align-items-end">
							<label>Aún no cuentas con el siguiente seminario</label>
							<button
								className="action-btn linear-bg w-100"
								style={{ maxWidth: 321 }}
								onClick={() => history.push("/micuenta/historial-de-pagos")}
							>
								Inscribete
							</button>
						</div>
					) : (
						<>
							{nextSeminary.videoUrl !== "" && nextSeminary.num !== 0 && (
								<button
									className="action-btn linear-bg w-100 ml-auto"
									style={{ maxWidth: 321 }}
									onClick={navigateNext}
								>
									Siguiente seminario ({romanize(nextSeminary.num)})
								</button>
							)}
						</>
					)}
				</div>
			)}
			{seminary.videoUrl12 !== undefined && seminary.videoUrl12.length > 0 && (
				<div className="parts-container">
					<div className={"part " + (part === 1 ? "active" : "")} onClick={() => setPart(1)}>
						<h3>Parte 1</h3>
					</div>
					{seminary.videoUrl12 !== undefined && seminary.videoUrl12.length > 0 && (
						<div className={"part " + (part === 2 ? "active" : "")} onClick={() => setPart(2)}>
							<h3>Parte 2</h3>
						</div>
					)}
					{seminary.videoUrl13 !== undefined && seminary.videoUrl13.length > 0 && (
						<div className={"part " + (part === 3 ? "active" : "")} onClick={() => setPart(3)}>
							<h3>Parte 3</h3>
						</div>
					)}
				</div>
			)}
			<div className="content-container">
				<div className="video-card">
					{!changingUrl && (
						<div className="c-player__screen" data-vjs-player="true">
							{!isSafari ? (
								<VideoJs options={videoJsOptions} onReady={handlePlayerReady} />
							) : (
								<video
									id="video-player"
									ref={playerRef}
									className="w-100"
									style={{ height: "auto" }}
									preload="auto"
									onLoadedMetadata={handleLoadedMetadata}
									controls={true}
									autoPlay={false}
								></video>
							)}
						</div>
					)}
				</div>
				<div className={"about-class-card " + (openAbout ? "about-class-card-open" : "")}>
					<div className="title" onClick={() => setOpenAbout(!openAbout)}>
						<h2>Sobre el seminario</h2>
						<img src={ArrowRed} alt="flecha icono hacia abajo" />
					</div>
					<div className="info-container">
						<div className="description gen-name">
							<p className="font-weight-bold">Generación</p>
							<p>{gen.name}</p>
						</div>
						<div className="description">
							<p className="font-weight-bold">Fecha:</p>
							<p>{getDateFormat1(seminary.date)}</p>
						</div>
						<div className="description">
							<p className="font-weight-bold">Duración:</p>
							<p>{videoDuration}</p>
						</div>
						{seminary.videoUrl2!.length > 0 && (
							<div className="description" style={{ flexDirection: "column" }}>
								<p className="font-weight-bold">Ver en enlace de YouTube:</p>
								<a target="_blank" href={seminary.videoUrl2} style={{ fontSize: "0.875rem" }}>
									{seminary.videoUrl2}
								</a>
							</div>
						)}
					</div>
					{seminary.notes !== "" && (
						<div className="notes-container">
							<div className="title">
								<h3>Notas</h3>
							</div>
							<div className="text-container">
								<p>{seminary.notes}</p>
							</div>
						</div>
					)}
					<div className="materials-container">
						<div className="title">
							<h3>Materiales</h3>
						</div>
						{seminary.files.map((file, index) => {
							return (
								<div className="link-container" key={"recordings-files-" + index}>
									<a className="recording-material-btn-link" href={file.linkFile} target="_blank">
										<img src={OpenBook} alt="icono de libro abierto" />
										&nbsp;&nbsp;{getFileName(file.name)}
									</a>
								</div>
							);
						})}
					</div>
				</div>
				{seminary.temaryIndex !== -1 && <TemaryCard seminario={fullTemary[seminary.temaryIndex]} />}
			</div>

			<div className="annotations-card">
				<div className="consults-text">
					<p>
						¿Tienes alguna duda? Usa la sección{" "}
						<Link to={"/micuenta/consultas/" + seminary.num}>consultas</Link> para disiparla
					</p>
				</div>
				<div className="title">
					<h2>Anotaciones</h2>
					<h3>Sólo tú podrás ver tus anotaciones</h3>
				</div>
				<form onSubmit={createAnnotation} className="input-row">
					<div className="profile-pic" style={{ backgroundColor: props.user.color }}>
						<p>{getInitials(props.user.name)}</p>
					</div>
					<div className="input-container">
						<input
							type="text"
							value={annotationText}
							onChange={(e) => setAnnotationText(e.target.value)}
							placeholder="Anota en esta sesión"
						/>
						<button className="add-annotation-btn" type="submit">
							Agregar
						</button>
					</div>
				</form>
				{annotations.length > 0 && (
					<>
						{annotations.map((annotation, index) => {
							return (
								<Annotation
									profileColor={props.user.color}
									annotation={annotation}
									playerRef={playerRef}
									key={"annotation-" + index}
								/>
							);
						})}
					</>
				)}
			</div>
		</div>
	);
}

export default Recordings;
